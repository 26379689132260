import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from '../../context/GlobalContext';

import download  from "../../../assets/images/icons/download-badge.svg";
import contact  from "../../../assets/images/icons/contact.svg";
import star  from "../../../assets/images/icons/star.svg";
import starActive  from "../../../assets/images/icons/star_active.svg";
import starAdmin  from "../../../assets/images/icons/star_admin.svg";
import { getBadgeFileAction } from '../../../actions/receptionActions';

export default function UsersRow({ user }) {

  const dispatch = useGlobalContext()[1];

  function renderRole(role) {
    if (role === "Member") {
      return (
        <>
          <img src={star} className="star" alt="icon-contact"/> 
          <span>Membre</span>
        </>
      );
    };
    if (role === "Owner") {
      return (
        <>
          <img src={starActive} className="star" alt="icon-contact"/> 
          <span>Référent</span>
        </>
      );
    };
    if (role === "Admin") {
      return (
        <>
          <img src={starAdmin} className="star" alt="icon-contact"/> 
          <span>Administrateur</span>
        </>
      );
    };
  }

  return (
    
      <div className="list-row">
          <Link to={"/user-edit/" + user._id} className="user">
            <div className="name">
              <div className="icon">
                <img src={contact} alt="user"/>
              </div>
              {user.profile.lastName} {user.profile.firstName}
            </div>

            <div className="name">
              {renderRole(user.role)}
            </div>

            <div className="name">
              {user?.company?.map(c => c.name).toString().split(',').join(' / ')}
            </div>

            <div className="name">
              {user?.presence.length} présence{user?.presence.length !== 1 && "s"}
            </div>
            
          </Link>
          <button
            className="btn-edit circle"
            onClick={() =>  getBadgeFileAction(dispatch , user , null )}>
            <img src={download} alt="download"/>
          </button>
      </div>

  )
}
