import { deleteData, postData } from ".";
import { ERROR_AUTH, SET_FILE } from "./types";

export async function postFilesAction(dispatch , data ){
  let url = "/file"
  let files;
  await postData(SET_FILE , ERROR_AUTH , url, dispatch , data, true , false ).then((response)=>{
    files = response.files
  })
  dispatch({
    type : SET_FILE,
    payload : files
  })
  return files;
}

export async function deleteFileAction(dispatch , id ){
  let url = "/file/" + id ;
  let fileDeleted;
  await deleteData(ERROR_AUTH, url, dispatch , true , false).then((response)=>{
    fileDeleted = response;
  })
  dispatch({
    type : ERROR_AUTH,
    payload : fileDeleted.message
  });
  return fileDeleted;
}

