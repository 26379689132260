import React , { useEffect , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

import { useGlobalContext } from '../../context/GlobalContext';
import { getAnimationAction } from '../../../actions/animationsActions';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

import Animation from "./Animations_Partials/Animation";
import Loader from "../../Partials/Loader";

export default function AnimationView(props) {
  props.setActivePage("animations");
  const [ context , dispatch ] = useGlobalContext();

  const animation = context.animations.animation;

  const getAnimation = useCallback(() => {
    getAnimationAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  useEffect(() => {
    getAnimation();
  }, [getAnimation]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function renderAnimation() {

    return (
      <>
        <header className="page-header">

          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>

          <h1>
            {animation.company.name}
            <span className="hide-mobile text" style={{ marginLeft: 20 }}>
              {animation.dimensionL ?
                <Translate id="animation.notOnStand"/>
                :
                <Translate id="animation.onStand"/>
              }
            </span>
          </h1>

        </header>

        <main className="page-content">

          <Link to={"/animations"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <div className="row col-2">
            <div className="col-2">
              <Animation
                getAnimation={getAnimation}
                animationId={props.match.params.id}
                dispatch={dispatch}
                context={context}
                animation={animation} />
            </div>
          </div>

        </main>
      </>
    );
  };

  return (
    <div className="page-container">
      {animation && animation.company ? 
        renderAnimation()
      :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  );
  
};