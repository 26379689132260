import {
  IS_AUTH,
  USER_AUTH,
  ERROR_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_DASHBOARD,
  SET_FILE,
  GET_EXHIBITION,
} from "../actions/types"

const initialState = {
  isAuth: false,
  user: {},
  error: {},
  message: null,
  dashboard: {},
  exhibition: null,
  file: null,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case IS_AUTH:
      return {...state , isAuth: action.payload}
    case SET_FILE:
      return {...state , file: action.payload}
    case USER_AUTH:
      return {...state , user: action.payload}
    case GET_EXHIBITION:
      return {...state , exhibition: action.payload}
    case GET_DASHBOARD:
        return {...state , dashboard: action.payload}
    case ERROR_AUTH:
      return {...state , error: action.payload}
    case FORGOT_PASSWORD:
      return {...state , message : { forgot: action.payload.message}}
    case RESET_PASSWORD:
      return {...state , message : { reset: action.payload.message}}
    default:
      return {...state};
  }
}
