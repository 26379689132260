/* eslint-disable react-hooks/exhaustive-deps */
import React , { useEffect , useState , useCallback } from 'react';
// import { Translate } from "react-localize-redux";

import UsersRow from './UserRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import { useGlobalContext } from '../../context/GlobalContext';
import { getUsersListAction , exportPresencesAction } from '../../../actions/usersActions';

import searchIcon from "../../../assets/images/icons/search.svg"
import { EXPORT_PRESENCES, SET_USERS_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function UsersList({ setActivePage }) {
  const [ context, dispatch ] = useGlobalContext();
  const { filters } = context.users;
  const [ search , setSearch ] = useState("");
  const [ filtredList , setFiltredList ] = useState();
  const [ exportIsLoading , setExportIsLoading] = useState(null);


  const token = localStorage.getItem('token');

  const getUserList = useCallback(() => {
    setActivePage("users")
    getUsersListAction(dispatch);
    setSearch(filters.search);
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });

    dispatch({
      type : 'GET_USER',
      payload : {}
    });

    dispatch({
      type : 'EXPORT_PRESENCES',
      payload : null
    });

  }, [dispatch, filters.search, setActivePage]);

  useEffect(() => {
    if (token) {
      getUserList();
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (context.users.usersList) {

      dispatch({
        type: SET_USERS_FILTERS,
        payload: { search }
      });
    
      const list = context.users.usersList.filter((user) => {
        if (user.profile && user.profile.lastName && user.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.profile && user.profile.firstName && user.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.email && user.email.toLowerCase().includes(search.toLowerCase())) {
          return user;
        }
        return null;
      });
      setFiltredList(list);
    }
  }, [context.users.usersList, search])

  function renderUserList() {
    if (filtredList && filtredList.length > 0) {
      return filtredList.filter(user => user._id !== context.auth.user._id).map((user, i) => <UsersRow key={user._id} user={user} />);
    } else if (search && filtredList.length === 0) {
      return <span className="text-error">Aucun résultat ne correspond à votre recherche</span>;
    } else {
      return (
        <div style={{ height: '70vh'}}>
          <Loader />
        </div>
      )
    }
  }

  function renderExportBtn() {
    if (context.users.exportPresencesList && context.users.exportPresencesList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.users.exportPresencesList.fileName}
        dataToExport={context.users.exportPresencesList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_PRESENCES,
            payload : null
          });
          setExportIsLoading();
        }} 
        />
    } else {
      return <IconBtn
        label={"Exporter les présences"}
        icon="export"
        isLoading={exportIsLoading === "presences"}
        handleClick={() => {
          exportPresencesAction(dispatch);
          setExportIsLoading("presences");
        }}
      />
    }
  }
  
  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des utilisateurs</h1>
      </header>
      <main className="page-content">
        <div className="row space-between">
            <div className="col-3 input">
              <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
              <img className={'search-icon'} src={searchIcon} alt="search"/>
            </div>
            {renderExportBtn()}
        </div>
        {renderUserList()}
      </main>
    </div>
  )
}