export const requiredField = "Ce champs est requis";

export const dimensionsOptions = [
  { unit: "m2", value: 'square', label: 'Métre carré' },
  { unit: "m", value: 'linear', label: 'Métre linéaire' }
];

export const deliveryOptions = [
  { label: "Centrale", value: "Centrale" },
  { label: "Direct", value: "Direct" }
];

export const offersStatusOptions = [
  { label: "En attente", value: "pending" },
  { label: "Validée", value: "valid" },
  { label: "Refusée", value: "refused" },
  { label: "À corriger", value: "correction" },
];

export const availabilityOptions = [
  { label: "De suite", value: "right now" },
  { label: "Autre", value: "other" },
];