import React , { useState , useEffect } from 'react';
import { Translate } from "react-localize-redux";
import { store } from 'react-notifications-component';
import { API_URL } from '../../../../actions';

import { translateDate } from "../../../../utils/utils";
import Input from '../../../Partials/form_fields/Input'; 

import { updateAnimationAction , removeFileAction } from '../../../../actions/animationsActions';

import '../../../../styles/animations.scss';
import downloadIcon from "../../../../assets/images/icons/download.svg";
import trashIcon from "../../../../assets/images/icons/trash.svg";

export default function Animation({ animation , context , dispatch , getAnimation , animationId }) {

  const [animationData , setAnimationData ] = useState(animation);
  const [ files , setFiles ] = useState([]);

  useEffect(() => {
    if(animation) {
      setAnimationData(animation);
    }
  }, [animation]);

  function submitAnimation() {

    var formData = new FormData()
    formData.append('user', context.auth.user._id);
    formData.append('company', animationData.company._id );
    formData.append('push', animationData.push );
    formData.append('description', animationData.description);
    formData.append('isValid', animationData.isValid );
    formData.append('notes', animationData.notes );
    formData.append('banner', animationData.banner );
    animationData.showroomNumber && formData.append('showroomNumber', animationData.showroomNumber);
    animationData.timeslot && formData.append('timeslot', animationData.timeslot);
    if (animationData.dimensionL && animationData.dimensionP) {
      formData.append('dimensionL', animationData.dimensionL);
      formData.append('dimensionP', animationData.dimensionP);
    }

    if(files){
      Array.from(files).forEach((d)=>{
        formData.append('file', d);
      })
    }
    return updateAnimationAction( dispatch , animationId , formData );
  };

  function removeFile(fileName) {
    removeFileAction(dispatch , animationId , { fileName: fileName });
  }



  useEffect(() => {
    if (context.animations.message === 'Animation successfully updated!' || context.animations.message === "animation updated" || context.animations.message === "File successfully removed!") {
      successUpdate();
      getAnimation();
      setFiles([]);
    };
    // eslint-disable-next-line 
  }, [context.animations.message]);

  function successUpdate() {

    store.addNotification({
      message: "Animation mise à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });

    dispatch({
      type : 'MESSAGE_ANIMATIONS',
      payload : {}
    });

  };

  return (
    <>
      <div className="hide-desktop">
        <h2>
          {animation.dimension && animation.dimension > 0 ?
            <Translate id="animation.notOnStand"/>
            :
            <Translate id="animation.onStand"/>
          }
        </h2>
      </div>


      <div className="card" style={{ padding: 20 }}>

        <div className="save-container">
          <button
            className="btn-save"
            onClick={() => submitAnimation()}
            >
            Enregister
          </button>
        </div>

        <h3 className="bold" style={{ marginTop: 0 }}>Numéro du stand</h3>
        <Input
          value={animationData && animationData.showroomNumber ? animationData.showroomNumber : null }
          type={"number"}
          placeholder="Numéro du stand"
          className="input"
          onChange={(value) => setAnimationData({...animationData ,  showroomNumber: value })}
          />

        <h3 className="bold" style={{ marginTop: 0 }}>Créneau horaire</h3>
        <Input
          value={animationData && animationData.timeslot ? animationData.timeslot : null }
          type={"text"}
          placeholder="Numéro du stand"
          className="input"
          onChange={(value) => setAnimationData({...animationData ,  timeslot: value })}
          />

        <h3 className="bold" style={{ marginTop: 0 }}>Bannière</h3>
        <Input
          value={animationData && animationData.banner ? animationData.banner : null }
          type={"text"}
          placeholder="Bannière"
          className="input"
          onChange={(value) => setAnimationData({...animationData ,  banner: value })}
          />
        
        <h3 className="bold" style={{ marginTop: 0 }}><Translate id="animation.description" /></h3>

         <div className="comments">
          <textarea
            placeholder="Description"
            value={animationData?.description || ''}
            onChange={(e) => setAnimationData({...animationData ,  description: e.target.value })}/>
          </div>

          <h3 className="bold" style={{ marginTop: 0 }}>Activer les notifications sur l'application du salon ?</h3>

          <div className="list-container">
            <button
              onClick={() => setAnimationData({...animationData , push: true })}
              className={`btn list-item ${animationData.push ? "selected" : ""}`}>
              oui
            </button>
            <button
              onClick={() => setAnimationData({...animationData , push: false })}
              className={`btn list-item ${!animationData.push ? "selected" : ""}`}>
              non
            </button>
          </div>

        {animation.timeslot ?
          <p style={{ marginBottom: 20 , marginTop: 5 }}>{animation.timeslot}</p>
          :
          null
        }

        {animationData.dimensionL && animationData.dimensionP  &&
          <>
            <h3 className="bold" ><Translate id="animation.surface" /></h3>
            <p style={{ marginBottom: 20 , marginTop: 5 }}>
              {animationData.dimensionL * animationData.dimensionP} m<sup>2 </sup>
              {!animationData.dimension && animationData.dimensionL && animationData.dimensionP ?
                <span>({animationData.dimensionL} m x {animationData.dimensionP} m)</span>
                :
                null
              }
            </p>
            <div className="input-dimension">
              <span>L</span>
              <input
                placeholder={""}
                type="number"
                defaultValue={animationData.dimensionL}
                style={{ maxWidth: 50 }}
                onChange={(e) => setAnimationData({...animationData , dimensionL: parseFloat(e.target.value) })}
              />
              <span>
              x P
              </span>
              <input
                placeholder={""}
                type="number"
                defaultValue={animationData.dimensionP}
                style={{ maxWidth: 50 }}
                onChange={(e) => setAnimationData({...animationData , dimensionP: parseFloat(e.target.value) })}
              />
            </div>
          </>
        }

        <h3 className="bold" ><Translate id="animation.files" /></h3>
        {animationData && animationData.files && animationData.files.length > 0 &&
          <>
            {animationData.files.map((link, i) => (
               <div key={link + "api" + i} className="file-container">
                <p className="bold">{link.split('/')[1]}</p>
                <div className="files-actions"> 
                  <a
                    href={API_URL + "/files/" + link }
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <button className="btn-download">
                      <img src={downloadIcon} alt="dowload"/>
                    </button>
                  </a>
                  <button className="btn-download" onClick={() => removeFile(link.split('/')[1])}>
                    <img src={trashIcon} alt="dowload"/>
                  </button>
                </div>
              </div>
            ))}
          </>
        }
          <>
            {files && files.map((link) => (
              <div key={link.name} className="file-container">
              <p className="bold">{link.name}</p>
              <div className="files-actions"> 
                <button className="btn-download" onClick={() => setFiles(files.filter(item => item.name !== link.name))}>
                    <img src={trashIcon} alt="dowload"/>
                  </button>
              </div>
            </div>
          ))}
         </>

        <label htmlFor="upload-pole" className="btn-edit large btn-upload">
          Joindre des fichiers
        </label>

        <input className="hide-file-input" onChange={(e) => setFiles([...files, ...e.target.files])} type="file" name="upload-pole" id="upload-pole" multiple="multiple"/>

        <h3 className="bold" ><Translate id="animation.comments" /></h3>

        <div className="comments">

          <textarea
            placeholder="Notes sur le stand"
            value={animationData && animationData.notes}
            onChange={(e) => setAnimationData({...animationData ,  notes: e.target.value })}/>

        </div>

        <div className="validation-container">

          <button
            onClick={() => setAnimationData({...animationData ,  isValid: "valid" })}
            className={`btn-secondary ${animationData.isValid !== "valid" ? "not-active-status" : ""}`}>
            Valider
          </button>

          <button
            onClick={() => setAnimationData({...animationData ,  isValid: "refused"})}
            className={`btn-secondary ${animationData.isValid !== "refused" ? "not-active-status" : ""}`}>
            Refuser
          </button>

          <button
            onClick={() => setAnimationData({...animationData , isValid: "pending"})}
            className={`btn-secondary ${animationData.isValid !== "pending" ? "not-active-status" : ""}`}>
            En attente
          </button>

        </div>

        <span style={{ fontSize: 12 , opacity: 0.58 }} >Modifiée le {translateDate(animation.updatedAt, 'fr')}</span> 
      
      </div>
    </>
  );
};
