import React, { useState , useEffect , useCallback } from "react";
import history from "../../src/utils/history";
import { Router, Switch, Route } from "react-router-dom";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

import { useGlobalContext } from './context/GlobalContext';
import { getUserAction } from '../actions/authActions';

import Nav from "./Partials/Nav";

import UsersList from "./Pages/Users/UsersList";
import UserEdit from "./Pages/Users/UserEdit";

import HomePage from "./Pages/Home/HomePage";
import Login from "./Pages/Login/Login";
import ResetPassword from "./Pages/Login/ResetPassword";

import ProvidersList from "./Pages/Providers/ProvidersList";
import ProviderView from "./Pages/Providers/ProviderView";
import ProviderEdit from "./Pages/Providers/ProviderEdit";
import ProviderContactsEdit from "./Pages/Providers/ProviderContactsEdit";

import StandsList from "./Pages/Stands/StandsList";
import StandView from "./Pages/Stands/StandView";

import ShowroomEdit from "./Pages/Providers/ShowroomEdit";
import ShopContactsEdit from "./Pages/Shops/ShopContactsEdit";

import ShopsList from './Pages/Shops/ShopsList';
import ShopView from './Pages/Shops/ShopView';
import ShopEdit from './Pages/Shops/ShopEdit';

import AnimationsList from "./Pages/Animations/AnimationsList";
import AnimationView from "./Pages/Animations/AnimationView";
import AnimationCreate from "./Pages/Animations/AnimationCreate";

import AssemblyList from "./Pages/Assembly/AssemblyList";
import AssemblyView from "./Pages/Assembly/AssemblyView";

import OffersList from "./Pages/Offers/OffersList";
import OfferView from "./Pages/Offers/OfferView";
import EditOffer from "./Pages/Offers/EditOffer/EditOffer";

import ReceptionList from "./Pages/Reception/ReceptionList";
import Badge from "./Pages/Reception/Badge";
import Scan from "./Pages/Reception/Scan";

import OrdersList from "./Pages/Orders/OrdersList";

import EditOrder from "./Pages/Orders/EditOrder";

function MainRouter() {
  const [ context, dispatch ] = useGlobalContext();
  const [ activePage , setActivePage ] = useState("login");
  var token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAction(dispatch)
  },[dispatch]);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
    }
  }, [context.auth.isAuth , getUser]);

  useEffect(() => {
    if(context.auth.error === 401 ) {
      logout();
    }
    // eslint-disable-next-line 
  },[context.auth.error])

  useEffect(() => {
    if(token) {
      getUser();
      dispatch({
        type : 'ERROR_AUTH',
        payload : {}
      });
    } else {
      history.push("/login");
      dispatch({
        type : 'IS_AUTH',
        payload : false
      });
    }
  // eslint-disable-next-line 
  }, []);


  function logout() {
    history.push("/login");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : {}
    });
    dispatch({
      type : 'ERROR_AUTH',
      payload : {}
    });
  };

  return (
    <div className="app-container">
      <ReactNotification />
      <Router history={history}>
        {activePage !== "login"?
          <Nav
            activePage={activePage}
            setActivePage={setActivePage}
            logout={logout}
          />
          :
          null
        }
        <Switch >
          <Route exact path="/providers" render={
            (props) => 
              <ProvidersList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/provider-view/:id" render={
            (props) =>  <ProviderView
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/provider-edit/:id" render={
            (props) =>  <ProviderEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/provider-create/" render={
            (props) =>  <ProviderEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/provider-contact-edit/:id" render={
            (props) =>  <ProviderContactsEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route path="/showroom-edit/:id/:activePage" render={
            (props) =>  <ShowroomEdit
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/stands" render={
            (props) => 
              <StandsList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/stand-view/:id" render={
            (props) =>  <StandView
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shops">
            <ShopsList
              setActivePage={setActivePage} />
          </Route>
          <Route exact path="/shop-view/:id" render={
            (props) =>  <ShopView
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shop-edit/:id" render={
            (props) =>  <ShopEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shop-contact-edit/:id" render={
            (props) =>  <ShopContactsEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/animations" render={
            (props) => 
              <AnimationsList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/animation-view/:id" render={
            (props) => 
              <AnimationView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/create-animation/" render={
            (props) => 
              <AnimationCreate
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/assemblies" render={
            (props) => 
              <AssemblyList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/offers" render={
            (props) => 
              <OffersList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/offer-create" render={
            (props) => 
              <EditOffer
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/offer-edit/:id" render={
            (props) => 
              <EditOffer
                setActivePage={setActivePage}
                {...props}
              />}
            />
          
          <Route exact path="/offer-view/:id" render={
            (props) => 
              <OfferView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/orders" render={
            (props) => 
              <OrdersList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/create-order" render={
            (props) => 
              <EditOrder
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/order-edit/:id" render={
            (props) => 
              <EditOrder
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/assembly-view/:id" render={
            (props) => 
              <AssemblyView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/reception/:event" render={
            (props) => 
            <ReceptionList
              setActivePage={setActivePage}
              {...props}
              />}
            />

          <Route exact path="/badge/:id/:event" render={
            (props) =>  <Badge
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/scan/:event" render={
            (props) => 
            <Scan
              setActivePage={setActivePage}
              {...props}
              />}
            />

          <Route exact path="/users">
            <UsersList
              setActivePage={setActivePage} />
          </Route>

          <Route exact path="/user-edit/:id" render={
            (props) =>  <UserEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/">
            <HomePage
              setActivePage={setActivePage}/>
          </Route>

          <Route exact path="/login">
            <Login
              activePage={activePage}
              setActivePage={setActivePage}
              />
          </Route>

          <Route exact path="/reset-password/:token" render={
            (props) => <ResetPassword
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
            }/>

        </Switch>
      </Router>
    </div>
  );
}

export default MainRouter;
