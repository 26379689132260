import React, { useState , useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate , withLocalize } from "react-localize-redux";
import "../../../styles/Login.scss";

import bgLogin from "../../../assets/images/login-img.jpg";
import logo from "../../../assets/images/logo-leclerc.svg";
import mail from "../../../assets/images/icons/mail.svg";
import lock from "../../../assets/images/icons/lock.svg";
import LogoParcExpo from '../../../assets/images/logo_parc_expo.svg';

import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction } from '../../../actions/authActions';
import { validateEmail } from '../../../utils/utils';

function Login(props) {
  props.setActivePage("login");

  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();

  const [ email , setEmail ] = useState();
  const [ password , setPassword ] = useState();
  const [ error , setError] = useState();
  const [ message , setMessage ] = useState();

  const [ hidePassword , setHidePassword ] = useState(true);
  
  const token = localStorage.getItem('token');

  useEffect(() => {
    setError();
    setMessage();
    dispatch({
      type : 'ERROR_AUTH',
      payload : {}
    });
    if(token) {
      dispatch({
        type : 'IS_AUTH',
        payload : true
      });
      history.push('/')
    }
    return () => {
      setError();
      setMessage();
    }
  // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if(context?.auth?.user) {
      if (context.auth.user.role === "Admin") history.push('/');
      if (context.auth.user.role === "Accueil") history.push('/reception/day1');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.auth, history])

  

  useEffect(()=> {
    setMessage();
    if (context.auth?.error?.status === 422) {
      setError(props.translate("login.errors.forgot"))
    }
    if (context.auth?.error?.status === 401) {
      setError(props.translate("login.errors.connection"))
    }
    if (context.auth?.error === 401) {
      setError(props.translate("login.errors.connection"))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.auth.error, props])

  useEffect(()=> {
    if (context.auth?.message?.forgot) {
      setError();
      setMessage(props.translate("login.message.forgot"))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.auth.message , props])

  function submitLogin() {
    setMessage();
    if(!password) return setError(props.translate("login.errors.password"))
    if (validateEmail(email)) {
      setError();
      loginAction(dispatch, {
        "email": email,
        "password": password
      });
    } else {
      setError(props.translate("login.errors.email"));
    }
  };

  function forgotPassword() {
    if (validateEmail(email)) {
      setError();
      forgotPasswordAction(dispatch ,{ "email": email })
    } else {
      setError(props.translate("login.errors.email"));
    }
  }

  function pressKey(e) {
    if(e.key === 'Enter') submitLogin();
  }
 
  return (
    <div className="login-container" onKeyPress={(e) => pressKey(e)}>

      <div className="col img">
        <img src={bgLogin} alt="bg-login"/>
      </div>

      <div className="col login">

        <div className="login-content">
          <img src={logo} alt="logo leclerc" />
          <h1>BTlec Ouest</h1>
          <h2>Salon Multimédia et Electro<br/> 15/16 mai 2024</h2>
          <div className="address">
            <img src={LogoParcExpo} alt="parc expo nantes" />
            <p>
              EXPO NANTES - Grand Palais<br/>
              Route de Saint-Joseph de Porterie<br/>
              44300 NANTES
            </p>
          </div>
          <div className="input-login">
            <input
              type="email"
              placeholder={props.translate("login.placeholder.email")}
              onChange={(e) => setEmail(e.target.value)}
              />
            <img src={mail} alt="mail"/>
          </div>
          <div className="input-login">
            <input
              type={hidePassword ? "password" : "text"}
              placeholder={props.translate("login.placeholder.password")}
              onChange={(e) => setPassword(e.target.value)}
              />
            <img src={lock} alt="lock" onClick={() => setHidePassword(!hidePassword)} />
          </div>
          <button className="btn-connection" onClick={() => submitLogin()}><Translate id="login.login"/></button>
          <div className="login-message">
            <span className="text-error">{error}</span>
            <span className="text-success">{message}</span>
          </div>
          <button className="btn-forgot" onClick={() => forgotPassword() }><Translate id="login.forgot"/></button>
        </div>
      </div>

    </div>
  );
};

export default withLocalize(Login);