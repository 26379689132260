import React from "react";
import { Link } from "react-router-dom";

import contactGrey  from "../../../../assets/images/icons/contact_grey.svg";
import providerIcon  from "../../../../assets/images/icons/provider_icon.svg";
import contact  from "../../../../assets/images/icons/contact_primary.svg";
import marketBlue  from "../../../../assets/images/icons/market_primary.svg";
import elec  from "../../../../assets/images/icons/elec.svg";
import water  from "../../../../assets/images/icons/water.svg";
import stock  from "../../../../assets/images/icons/stock.svg";

export default function ProviderRow(props) {
  const { provider } = props;

  function renderUpdatedLabel() {
    var updatedDate = new Date(provider.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  }

  return (
    <Link to={`/provider-view/${provider._id}/`}>

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <p>
            {provider.name}
          </p>
        </div>

        {props.dashboard? 

          <div className="info dashboard">
            <div className="contacts">
              <p>{provider.users && provider.users.length > 0 ?  provider.users.length : 0 }</p> <img src={contact} alt="icon-contact"/>
            </div>
            {provider.showroom && provider.showroom.dimension && provider.showroom.dimension > 0 ?
              <div className="stand">
                {provider.showroom.dimension} m<sup>2</sup><img src={marketBlue} alt="icon-contact"/>
              </div>
              :
              <div className="stand not-active">
                <img src={marketBlue} alt="icon-contact"/>
              </div>
            }
          </div>

          :

          <div className="info">
            {provider.users && provider.users.length > 0 ?
              <div className="picto">
                <p>{provider.users.filter( u => u.presence.length > 0).length}</p> <img src={contact} className={'user'} alt="icon-contact"/>
                <p style={{ marginLeft: 10 }}>{provider.users.filter( u => u.presence.length === 0).length}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
              </div>
            :
              <div className="picto not-active">
                <p>{0}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
                <p style={{ marginLeft: 10 }}>{0}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
              </div>
            }

            {provider.showroomOption && provider.showroomOption.power ?
              <div className="picto">
                <span>oui</span> <img src={elec} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <span>non</span><img src={elec} alt="icon-contact"/>
              </div>
            }

            {provider.showroomOption && provider.showroomOption.water ?
              <div className="picto">
                <span>oui</span> <img src={water} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <span>non</span><img src={water} alt="icon-contact"/>
              </div>
            }

            {provider.showroomOption && provider.showroomOption.storage && provider.showroomOption.storageSize  ?
              <div className="stock">
                <span>{provider.showroomOption.storageSize}</span>
                <img src={stock} alt="icon-contact"/>
              </div>
              :
              <div className="stock not-active">
                non
                <img src={stock} alt="icon-contact"/>
              </div>
            }

            {provider.showroomDimension && provider.showroomDimension > 0 ?
              <div className="stand">
                <span>Stand ({provider.showroomDimension} m<sup>2</sup>)</span><img src={marketBlue} alt="icon-stand"/>
              </div>
              :
              <div className="stand not-active">
                <img src={marketBlue} alt="icon-stand"/>
              </div>

            }
          </div>
        }
        
      </div>

    </Link>
  )
}
