import React from 'react';
import Select from 'react-select';
import primary from './selectPrimary';
import secondary from './selectSecondary';

export default function StyledSelect({
  options,
  value,
  onChange,
  label,
  isMulti = false,
  className,
  placeholder,
  isSearchable = false,
  error
}) {
  const getClassName = className?.split(" ")?.find((s) => s ===  "secondary") ? secondary : primary;
  return (
    <div className={className}>
      <label className="bold">{label}</label>
      <div className="select">
        <Select
          value={value}
          options={options}
          onChange={(val) => onChange(val)}
          isMulti={isMulti}
          isSearchable={isSearchable}
          styles={getClassName}
          isS
          placeholder={placeholder}
          />
      </div>
      <span className="text-error">{error ? error : ""}</span>
    </div>
  )
}
