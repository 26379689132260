import React from 'react';
import loader from './../../assets/images/icons/loader.svg'

export default function Loader(props) {
  return (
    <div style={{ 
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
      }} >
      <img width="100px" src={loader} alt="loader"/>
    </div>
  )
}
