/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState , useCallback , useEffect } from 'react';
// import { Translate } from "react-localize-redux";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';

import { exportOffersAction, getOffersListAction } from '../../../actions/offersActions';
import { useGlobalContext } from '../../context/GlobalContext';
import history from "../../../utils/history";

import OffersRow from './Offers_Partials/OffersRow';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { EXPORT_OFFERS, SET_OFFERS_FILTERS } from '../../../actions/types';
import ExportExcel from '../../Partials/ExportExcel';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OffersList({ setActivePage }) {
  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.offers;
  const [ filtredList , setFiltredList ] = useState();
  const [ exportIsLoading , setExportIsLoading] = useState(null);

  const [ search , setSearch ] = useState("");
  const [ selectedProducts , setSelectedProducts] = useState();
  
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByStatus , setSortByStatus ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);

  const getOffersList = useCallback(() => {
    setActivePage("offers");
    setSearch(filters.search);
    setSelectedProducts(filters.products);
    getOffersListAction(dispatch);

    dispatch({
      type : 'GET_OFFER',
      payload : null
    });

    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

  }, [dispatch]);

  useEffect(() => {
    getOffersList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.offers.scrollOffers)
  });

  useEffect(() => {
    if (context.offers.offersList.length > 0 ) {
      dispatch({
        type: SET_OFFERS_FILTERS,
        payload: { search, products: selectedProducts }
      })
    
      var newList = context.offers.offersList.filter((offer) => {
        if (!search) {
          return offer;
        }
        if (offer.company.name.toLowerCase().includes(search.toLowerCase())) {
          return offer
        }
        if (offer.gencod.toLowerCase().includes(search.toLowerCase())) {
          return offer
        } 
        return null; 
      });
      if (selectedProducts && selectedProducts.length > 0) {
        var filtredByProduct = [];
        selectedProducts.forEach(option => {
          newList.forEach(provider => {
            var found = provider.segment === option.value;
            if (found) filtredByProduct.push(provider);
          });
        })
        setFiltredList(_.uniqBy(filtredByProduct, "_id"))
      } else {
        setFiltredList(newList);
      }
    }
    // eslint-disable-next-line 
  }, [context.offers.offersList, search, selectedProducts])

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_OFFERS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByStatus) {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByStatus]);

  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);
  
  function renderOffersList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map(( offer , i) => <OffersRow key={i} offer={offer}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  function renderSelectProduct() {
    var options = []
    if (context.offers.offersList) {
      context.offers.offersList.forEach(item => {
        if (!options?.find((o) => o.value === item.segment)) {
          options.push({
            label: item.segment,
            value: item.segment
          });
        }
      });
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={selectedProducts}
        onChange={(val) => setSelectedProducts(val)}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par type de produits"
        />
    )
  };

  function renderExportBtn() {
    if (context.offers.exportList && context.offers?.exportList?.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.offers.exportList.fileName}
        dataToExport={context.offers.exportList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_OFFERS,
            payload : null
          });
          setExportIsLoading(false);
        }} 
        />
    } else {
      return <IconBtn
      label={"Exporter"}
      icon="export"
      isLoading={exportIsLoading}
      handleClick={() => {
        exportOffersAction(dispatch);
        setExportIsLoading(true);
      }}
      />
    }
  }


  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Offres salon</h1>
      </header>

      <main className="page-content">

        <div className="row search-container">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="select-products">
            {renderSelectProduct()}
          </div>
          <div className="col-2"
            style={{ display: 'flex', justifyContent: "flex-end" , margin:0 }}
          >
            {renderExportBtn()}
            <button 
              className="btn-edit hide-tablet"
              style={{ height:36 , paddingLeft: 15 , paddingRight: 15 }}
              onClick={() => history.push('/offer-create')} 
            >
              Nouveau produit
            </button>
          </div>
        </div>

        <div className="filters">

        <div
            className="filter"
            style={{ minWidth: '15%'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du fournisseur</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-65px)'}}
            onClick={() => setSortByRef(!sortByRef)}>
            <span>Référence</span>
            <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-55px)'}}
            onClick={() => setSortByGencode(!sortByGencode)}>
            <span>Gencod</span>
            <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-35px)'}}
            onClick={() => setSortByPack(!sortByPack)}>
            <span>Qté en colis</span>
            <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-55px)'}}
            onClick={() => setSortByTotal(!sortByTotal)}>
            <span>Montant total</span>
            <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByStatus(!sortByStatus)}>
            <span>Validation</span>
            <img src={filterIcon} className={sortByStatus ? "rotate180": ""} alt="filter"/>
          </div>

        </div>

        {renderOffersList()}

      </main>

    </div>
  );
};
