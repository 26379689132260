import React from "react";
import { Link } from "react-router-dom";

import providerIcon  from "../../../../assets/images/icons/provider_icon.svg";
import checkValid  from "../../../../assets/images/icons/check-valid.svg";
import checkRefused  from "../../../../assets/images/icons/check-refused.svg";
import checkPending  from "../../../../assets/images/icons/check-pending.svg";


export default function AnimationRow(props) {
  const { anim } = props;

  function renderStatus() {
    if(props.anim.isValid === "valid") {
      return (
        <>
          <p className="statut">Validée</p>
          <img className="check" src={checkValid} alt="check-valid"/>
        </>
      );
    } else if(props.anim.isValid === "refused") {
      return (
        <>
          <p className="statut">Refusée</p>
          <img className="check" src={checkRefused} alt="check-refused"/>
        </>
      );
    } else {
      return (
        <>
          <p className="statut">En attente</p>
          <img className="check" src={checkPending} alt="check-pending"/>
        </>
      );
    };
  };

  function renderUpdatedLabel() {
    var updatedDate = new Date(anim.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  return (
    <Link to={"/animation-view/" + anim._id}>

      <div className="list-row" >

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <p>
            {anim.company.name}
          </p>
        </div>

        <div className="info">

          <div className="picto anim-space">
            {anim.dimension ?
              <p>Espace animation</p>
              :
              <p>Sur stand</p>
            }
          </div>

          <div className="picto">
            {anim.dimension ?
              <p>{anim.dimension} m<sup>2</sup></p>
              :
              <p>- - -</p>
            }
          </div>

          <div className="picto">
            {renderStatus()}
          </div>

        </div>

      </div>

    </Link>
  );
};
