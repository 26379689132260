import {
  GET_RECEPTION_LIST,
  GET_RECEPTION_USER,
  ERROR_RECEPTION,
  MESSAGE_RECEPTION,
  GET_BADGE
} from "../actions/types"

const initialState = {
  receptionList: [],
  receptionUser: {},
  error: {},
  message: "",
  badge: null
};

export default function receptionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECEPTION_LIST:
      return {...state, receptionList : action.payload}
    case MESSAGE_RECEPTION:
      return {...state, message : action.payload}
    case ERROR_RECEPTION:
      return {...state, error : action.payload}
    case GET_RECEPTION_USER:
      return {...state, receptionUser : action.payload}
    case GET_BADGE:
      return {...state, badge : action.payload}
    default:
      return {...state};
  }
}
