import {
  GET_ASSEMBLY_LIST,
  ERROR_ASSEMBLY,
  SCROLL_ON_ASSEMBLY,
  GET_ASSEMBLY,
  PUT_ASSEMBLY,
  EXPORT_ASSEMBLY,
  SET_ASSEMBLY_FILTERS
} from "../actions/types"

const initialState = {
  assemblyList: [],
  assembly: {},
  error: {},
  scrollAssembly: 0,
  message: {},
  exportList: null,
  filters: {
    search: "",
  },
};

export default function assemblyReducer(state = initialState, action) {
  switch (action.type) {
    case PUT_ASSEMBLY:
      const assemblyList = state.assemblyList;
      const index = state.assemblyList.findIndex((a) => a._id === action?.payload?._id);
      if (index !== -1) {
        assemblyList[index] = {...assemblyList[index] , isHere: action.payload.isHere };
      }
      return {...state, assemblyList : assemblyList, assembly : action.payload}
    case SET_ASSEMBLY_FILTERS:
      return {...state, filters : {...state.filters, ...action.payload }}
    case EXPORT_ASSEMBLY:
      return {...state, exportList : action.payload}
    case GET_ASSEMBLY:
      return {...state, assembly : action.payload}
    case GET_ASSEMBLY_LIST:
      return {...state, assemblyList : action.payload}
    case ERROR_ASSEMBLY:
        return {...state, error: action.payload}
    case SCROLL_ON_ASSEMBLY:
      return {...state, scrollAssembly: action.payload}
    default:
      return {...state};
  }
}
