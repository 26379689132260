import React , { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import selectStyle from '../../Partials/selectStyle';
import { useGlobalContext } from '../../context/GlobalContext';
import { getEditListsAction , updateProviderAction , getProvidersListAction, getProviderAction , postProviderAction } from '../../../actions/providersActions';
import { store } from 'react-notifications-component';

import '../../../styles/Edit.scss';

import Input from "../../Partials/form_fields/Input";
import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import SelectList from '../../Partials/SelectList';

export default function ProviderEdit(props) {

  props.setActivePage("providers");

  const [ context , dispatch ] = useGlobalContext();

  const initWholesaler = { value: null , label: "PAS DE GROSSISTE" } 

  const [ name , setName ] = useState(null);
  const [ code , setCode ] = useState(null);
  const [ iddigit , setIddigit ] = useState(null);
  const [ products , setProducts ] = useState(null);
  const [ domains , setDomains ] = useState(null);
  const [ mainUser , setMainUser ] = useState(null);
  const [ wholesaler , setWholesaler ] = useState(null);
  const [ insuranceCompany , setInsuranceCompany ] = useState(null);
  const [ insuranceNb , setInsuranceNb ] = useState(null);

  var provider = context.providers.provider;
  let wholesalerOptions = [ initWholesaler ,...context.providers?.providersList?.filter(p => p._id !== provider?.company?._id ).map(p => ({ value: p._id , label: p.name }))]
  let usersOptions = provider?.users?.map(u => ({ value: u._id , label: `${u.profile.firstName} ${u.profile.lastName}` }))

  const getProvider = useCallback(() => {
    getProviderAction( dispatch, props.match.params.id);
    getProvidersListAction(dispatch);
  }, [dispatch , props.match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getEditLists();
  },[getEditLists])

  useEffect(() => {
    if(!provider.company && props.match.params.id) {
      getProvider();
      window.scrollTo(0, 0);
    }
  }, [getProvider , provider , props.match.params.id])

  useEffect(() => {
    if (provider.company) {
      setName(provider.company.name.toUpperCase());
      setCode(provider.company.code)
      setIddigit(provider.company.iddigit)
      setProducts(provider.company.products);
      setMainUser(provider.company.mainUser);
      setWholesaler(provider.company.wholesaler)
      setDomains(provider.company.domains);
      setInsuranceCompany(provider.company.insurance?.company);
      setInsuranceNb(provider.company.insurance?.number)
    }
  }, [provider]);

  useEffect(() => {
    if (context.providers.message === "Company successfully updated!") {
      successUpdate();
    }
    if (context.providers.message === "company saved") {
      successCreate();
    }
  })

  function submitProvider() {
    const providerUpdated = {
      name: name,
      code: code,
      iddigit: iddigit,
      products: products,
      domains: domains,
      mainUser: mainUser,
      wholesaler : wholesaler,
      insurance: {
        number: insuranceNb,
        company: insuranceCompany
      }
    }
    if(props.match.params.id) {
      providerUpdated.id = props.match.params.id
      updateProviderAction(dispatch, providerUpdated);
    } else {
      providerUpdated.type = "fournisseur"
      postProviderAction(dispatch ,providerUpdated)
    }
  }

  function successCreate() {
    store.addNotification({
      message: "Entreprise créée avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
  }

  function successUpdate() {
    store.addNotification({
      message: "Entreprise mise à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
  }

  function renderProviderEdit() {
      var url = "/providers";
      if (provider.company && provider.company._id ) {
        url = "/provider-view/" + provider.company._id
      };
      var lists = [];
      if (context.providers.lists) {
        lists = context.providers.lists
      };
      return (
        <>
          <header className="page-header">
            <div className="icon">
              <img src={providerIcon} alt="icon-provider"/>
            </div>
            <h1>{name}</h1>
          </header>
          <main className="page-content">
            <Link to={url} className="btn-edit mb-10">
            <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
            </Link>
            <div className="card edit-container">
              <div className="edit-row row">
                <div className="col-3">
                  <Input
                    label={<Translate id="company.social"/>}
                    value={name}
                    type={"text"}
                    className="input"
                    onChange={(value) => setName(value.toUpperCase())}
                    />
                </div>
                <div className="col-3">
                  <Input
                    label={<Translate id="company.code"/>}
                    value={code}
                    type={"text"}
                    className="input"
                    onChange={(value) => setCode(value)}
                    />
                </div>
                <div className="col-3">
                  <Input
                    label={<Translate id="company.iddigit"/>}
                    value={iddigit}
                    type={"text"}
                    className="input"
                    onChange={(value) => setIddigit(value)}
                    />
                </div>
                <div className="col-3">
                  <label className="bold" style={{ marginBottom: 4 , display: 'block' }}><Translate id="company.mainUser"/></label>
                  <Select
                    options={usersOptions}
                    value={usersOptions?.find(u => u.value === mainUser) ? usersOptions?.find(u => u.value === mainUser) : null }
                    onChange={(val) => setMainUser(val.value)}
                    isMulti={false}
                    styles={selectStyle}
                    placeholder={<Translate id="placeholder.select"/>}
                    />
                </div>
                <div className="col-3">
                  <label className="bold" style={{ marginBottom: 4 , display: 'block' }}><Translate id="company.wholesaler"/></label>
                  <Select
                    options={wholesalerOptions}
                    value={wholesalerOptions?.find(u => u.value === wholesaler) ? wholesalerOptions?.find(u => u.value === wholesaler) : null }
                    onChange={(val) => setWholesaler(val.value)}
                    isMulti={false}
                    styles={selectStyle}
                    placeholder={<Translate id="placeholder.select"/>}
                    />
                </div>            
              </div>
              <div className="edit-row">
                <SelectList
                  label={<Translate id="company.products"/>}
                  list={lists && lists.products? lists.products : []}
                  activeList={products ? products : []}
                  onChange={(array) => setProducts(array)}
                />
                <SelectList
                  label={<Translate id="company.domains"/>}
                  list={lists && lists.domains? lists.domains : []}
                  activeList={domains ? domains : []}
                  onChange={(array) => setDomains(array)}
                />
              </div>
              <div className="edit-row row">
                <div className="col-3">
                  <Input
                    label={<Translate id="company.insuranceCompany"/>}
                    value={insuranceCompany}
                    type={"text"}
                    className="input"
                    onChange={(value) => setInsuranceCompany(value)}
                    />
                </div>
                <div className="col-3">
                  <Input
                    label={<Translate id="company.insuranceNb"/>}
                    value={insuranceNb}
                    type={"text"}
                    className="input"
                    onChange={(value) => setInsuranceNb(value)}
                    />
                </div>
              </div>
            </div>
            <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
              <button
                className="btn-save"
                onClick={() => submitProvider()}
                >
                  <Translate id="btn.save"/>
              </button>
            </div>
          </main>
        </>
      )
  }

  return (
    <div className="page-container">
      {renderProviderEdit()}
    </div>
  )
}
