import React from 'react';
import { Link } from "react-router-dom";
import flag  from "../../../../assets/images/icons/flag.svg";
import providerIcon  from "../../../../assets/images/icons/provider_icon.svg";
import { useGlobalContext } from '../../../context/GlobalContext';
import { updateAssemblyAction } from '../../../../actions/assemblyActions';

export default function AssemblyRow(props) {
  const { standAssembly } = props;
  const dispatch = useGlobalContext()[1];
 
  function renderUpdatedLabel() {
    var updatedDate = new Date(standAssembly.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  function submitStandAssenbly(e) {
    e.preventDefault();
    return updateAssemblyAction(dispatch , standAssembly._id , { isHere : !standAssembly?.isHere });
  }

  return (
    <Link to={"/assembly-view/" + standAssembly._id}>

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <p>
            {standAssembly.company.name}
          </p>
        </div>

        <div className="info assembly">

          <p className="hours">
            {standAssembly.time}
          </p>

          <p className="hours">
            {standAssembly.duration}
          </p>

          <p className="hours">
            {standAssembly.endTime}
          </p>

          <p className="volume">
            {standAssembly.volume} palettes
          </p>

          <div className="btn-is-here"
            onClick={(e) => submitStandAssenbly(e)}>
            <div className={`picto ${standAssembly?.isHere ? "" : "not-active"}`}>
              <img src={flag} alt="icon-flag"/>
              <p style={{ width: 30 , marginLeft: 10 }}>{standAssembly?.isHere ? "oui" : "non"}</p>
            </div>
          </div>

        </div>

      </div>

    </Link>
  );
};
