import { getData , putData , postData , getFile } from './index';
import * as FileSaver from 'file-saver';

import {
  GET_PROVIDERS_LIST,
  GET_PROVIDER,
  POST_PROVIDER,
  ERROR_PROVIDERS,
  GET_EDIT_LISTS,
  MESSAGE_PROVIDER,
  RESET_PROVIDER,
  EXPORT_PROVIDERS_LIST,
  EXPORT_PROVIDERS_MAIL
} from "./types"

export async function getProvidersListAction(dispatch){
  let url = "/company/fournisseur"
  let providersList;
  await getData( ERROR_PROVIDERS, url, dispatch , true).then((response)=>{
    providersList = response.companies;
  })
  dispatch({
    type : GET_PROVIDERS_LIST,
    payload : providersList
  })
}

export async function getEditListsAction(dispatch){
  let url = "/company/list"
  let lists;
  await getData( ERROR_PROVIDERS, url, dispatch , true).then((response)=>{
    lists = response
  })
  dispatch({
    type : GET_EDIT_LISTS,
    payload : lists
  })
}

export async function getProviderAction(dispatch , id){
  let url = "/company/" + id
  let provider;
  await getData( ERROR_PROVIDERS, url, dispatch , true).then((response)=>{
    provider = response
  })
  dispatch({
    type : ERROR_PROVIDERS,
    payload : {}
  })
  dispatch({
    type : GET_PROVIDER,
    payload : provider
  })
}

export async function resetProviderAction(dispatch , id){
  let url = "/company/reset/" + id
  let provider;
  await getData( ERROR_PROVIDERS, url, dispatch , true).then((response)=>{
    provider = response
  });
  dispatch({
    type : RESET_PROVIDER,
    payload : provider
  })
}


export async function exportProvidersListAction(dispatch , obj ){
  let url = "/export/fournisseur"
  let exportList;
  await postData(GET_PROVIDER , ERROR_PROVIDERS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_PROVIDERS_LIST,
    payload : exportList
  })
}

export async function exportMailListAction(dispatch , obj ){
  let url = "/export/fournisseur/emails"
  let exportList;
  await postData(EXPORT_PROVIDERS_MAIL , ERROR_PROVIDERS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_PROVIDERS_MAIL,
    payload : exportList
  })
}

export async function updateProviderAction(dispatch , data ){
  let url = "/company/" + data.id 
  let providerUpdated;
  await putData( GET_PROVIDER , ERROR_PROVIDERS, url, dispatch, data , true).then((response)=>{
    providerUpdated = response;
  })
  await getProviderAction(dispatch, data.id)
  dispatch({
    type : MESSAGE_PROVIDER,
    payload : providerUpdated.message
  });
}

export async function postProviderAction(dispatch , data ){
  let url = "/company/"
  let providerUpdated;
  await postData(POST_PROVIDER , ERROR_PROVIDERS , url, dispatch , data, true ).then((response)=>{
    providerUpdated = response;
  })
  dispatch({
    type : MESSAGE_PROVIDER,
    payload : providerUpdated.message
  });
}

export async function createShowroomAction(dispatch , obj){
  let url = "/showroom";
  var newShowroom;

  await postData(GET_PROVIDER , ERROR_PROVIDERS , url, dispatch , obj, true ).then((response)=>{
    newShowroom = response
  });

  dispatch({
    type : MESSAGE_PROVIDER,
    payload : newShowroom.message
  });
};

export async function updateShowroomAction(dispatch , obj){
  let url = "/showroom/" + obj._id;
  var updatedShowroom;

  await putData(GET_PROVIDER , ERROR_PROVIDERS , url, dispatch , obj, true ).then((response)=>{
    updatedShowroom = response;
  });
  dispatch({
    type : MESSAGE_PROVIDER,
    payload : updatedShowroom.message
  });
};

export async function getShowroomLabelAction(dispatch , id , fileName ){
  let url = "/showroom/label/" + id
  await getFile(ERROR_PROVIDERS, url, dispatch , true).then((response)=>{
    var blob = new Blob([response], {type: "data:application/pdf"});
    FileSaver.saveAs(blob, `${fileName}.pdf`);
  })
};

