import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import '../../../../styles/showroomSize.scss';

import Input from '../../../Partials/form_fields/Input'
import Emplacement1 from '../../../../assets/images/emplacement-1.svg'
import Emplacement2 from '../../../../assets/images/emplacement-2.svg'

import selectStyle from '../../../Partials/selectStyle';
import { initPrices } from '../../../../utils/utils';

const options = [];

for (let i = 1 ; i < 11 ; i++) {
  options.push({ label : i + " Emplacements", value : i},)
};

export default function ShowroomSize(props){

  const { showroom , setDimension , setDirection  , longueur , profondeur , setLongueur , setProfondeur , initShowroom , setCustomDimension , activeOption , setActiveOption , customError , directionError} = props;

  const [ nbEmplacement, setNbEmplacement ] = useState(options[0]);
 


  useEffect(() => {
    if (initShowroom && initShowroom.option) {
      if (initShowroom.option.direction) {
        selectDirection(initShowroom.option.direction);
        setActiveOption("option-1")
        if (initShowroom.dimension ===  6) {
          selectNbEmplacement(options[0])
        } else {
          selectNbEmplacement(options[(initShowroom.dimension / 6) -1])
        } 
      } else {
        setActiveOption("option-2");
        setNbEmplacement("")
        if (initShowroom.option.customDimension && initShowroom.option.customDimension.l) {
          setLongueur(initShowroom.option.customDimension.l);
        }
        if (initShowroom.option.customDimension && initShowroom.option.customDimension.L) {
          setProfondeur(initShowroom.option.customDimension.L);
        }
      }
    };
    // eslint-disable-next-line
  },[initShowroom]);

  //OPTION 1
  function selectNbEmplacement(val){
    setCustomDimension(null)
    setDimension(2*(val.value*3))
    setNbEmplacement(val)
    setActiveOption("option-1")
  }

  // OPTION 1
  function selectDirection(val){
    if(nbEmplacement && val === "l"){
      setDimension(3*(nbEmplacement.value*2))
    }
    if(nbEmplacement && val === "L"){
      setDimension(2*(nbEmplacement.value*3))
    }

    setDirection(val)
    setActiveOption("option-1")
  }

  // OPTION 2
  function changeCustomDimension(val,field){
    let value = parseInt(val) > 0 ? parseInt(val) : 0
    if( field === "l"){
      setLongueur(value)
    }

    if(field ==="L"){
      setProfondeur(value)
    }
    setDirection(null)
    setNbEmplacement("")
    setActiveOption("option-2")
  }

  function nextStep(opt){
    setActiveOption(opt)
    if (opt === "option-1") {
      setCustomDimension(null)
      setDimension(6)
      setDirection("l")
      setLongueur(0)
      setProfondeur(0)
      selectNbEmplacement(options[0])
    } else {
      setDimension(0)
      setDirection(null)
      setNbEmplacement("")
    }
  }

  function totalOption1(){
    if( showroom.option.direction === "l"){
      return ((3*(nbEmplacement.value*2))/6)*5400
    } else if(showroom.option.direction === "L"){
      return ((2*(nbEmplacement.value*3))/6)*5400
    } else if (nbEmplacement === "") {
      return 0
    } else {
      return ((2*(nbEmplacement.value*3))/6)*5400
    }
  }

  function totalOption2(){
    if (nbEmplacement === "") {
      let surface = profondeur * longueur
      if (surface <= 6) {
        return initPrices.m6 ;
      } else {
        return ((Math.floor(surface/6))* initPrices.m6) + (surface%6) * initPrices.mPlus
      }
    } else {
      return 0
    }
  }

  return(
    <div className="choose-showroom-type">
      <div className={` choix-2-options ${activeOption !== "option-1"? "reverse" : ""}`}>
        <section className={`option choix-2-option-1 card showroom-opt ${activeOption !== "option-1"? "not-active" : ""}`}>
            <div>
              <h4 >Option 1</h4>
              <p>
                Multiplication des stands de bases <br/>
                (x1, x2, x3, x4)
              </p>
              <Select
               value={nbEmplacement}
               onChange={selectNbEmplacement}
               options={options}
               isSearchable={false}
               styles={selectStyle}
              />
              <div className="total">
                <p>TOTAL</p>
                <p>{showroom ? totalOption1() +"€" : "0" }</p>

              </div>
              { activeOption !== "option-1" ? <button onClick={() => nextStep("option-1")} >Choisir</button> : null }
              <p style={{ marginTop: 10 , maxWidth: 180 }} className="text-error">{directionError}</p>
            </div>
            { nbEmplacement && nbEmplacement.value > 1 ?

              <div className="option-1">
                <div className={showroom.option.direction === "l" ? "selected" : ""} onClick={() => selectDirection("l")}>
                  <img src={Emplacement1} alt="emplacement type 1"/>
                  <p>
                    Emplacements<br/>
                    Dos à dos:<br/>
                    3 x { nbEmplacement ? nbEmplacement.value*2 : 0}m
                  </p>
                </div>
                <div className={showroom.option.direction === "L" ? "selected" : ""} onClick={() => selectDirection("L")}>
                  <img src={Emplacement2} alt="emplacement type 2"/>
                  <p>
                    Emplacements<br/>
                    côte à côte:<br/>
                    { nbEmplacement ? nbEmplacement.value*3 : 0} x 2 m
                  </p>
                </div>
              </div> : null
            }
        </section>
        <section className={`option choix-2-option-2 card showroom-opt ${activeOption !== "option-2"? "not-active" : ""}`}>
          <h4>Option 2</h4>
          <p>
            Surface sur mesure avec au minimum <br/>
            6m2 + ajout de m2 supplémentaires<br/>
            <span className="sub">({initPrices.mPlus} € / m2 supplémentaire)</span>
          </p>
          <div className="input-dimension">
            <span>L</span>
            <Input
              value={profondeur}
              placeholder={""}
              type={"number"}
              style={{ maxWidth: 50 }}
              required={false}
              loading={false}
              onChange={(val) => changeCustomDimension(val,"L")}
            />
            <span>
            x P
            </span>
            <Input
              value={longueur}
              placeholder={""}
              type={"number"}
              style={{ maxWidth: 50 }}
              required={false}
              loading={false}
              onChange={(val) => changeCustomDimension(val,"l")}
            />
          </div>
          <div className="total">
            <p>TOTAL pour({ profondeur * longueur } m<sup>2</sup>) </p>
            <p>{showroom && showroom.option.customDimension ? totalOption2() +"€" : "0" }</p>
          </div>
          { activeOption !== "option-2" ? <button onClick={() => nextStep("option-2")}>Choisir</button> : null }
          <p style={{ marginTop: 10}} className="text-error">{customError}</p>
        </section>
      </div>

    </div>
  )

}
