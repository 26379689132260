import React, { useState , useEffect } from 'react';

export default function Textarea(props) {
  const [ error , setError ] = useState(false);
  const [ value , setValue ] = useState(null)

  const formats = {
    'phone' : /^(\d{2}\s){4}\d{2}$/,
    'email': /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    'leclercEmail': /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i
  }

  useEffect(() => {
    if(props.format && value){
      let regex = formats[props.format];
      if(!regex.test(value)){
        setError(true)
      } else{
        setError(false)
      }
    }
  },[value , formats , props.format ]);

  function handleChange(val) {
    props.onChange(val);
    setValue(val)
  }

  return (
    <div className={props.className}>
      <label className="bold">{props.label}</label>
      <textarea
        className={error ? "error" : ""}
        type={props.type}
        style={props.style || {} }
        value={props.value || '' }
        onChange={(e) => handleChange(e.target.value)}/>
        <span className="text-error">{props.error ? props.error : ""}</span>
    </div>
  )
}
