import React from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import checkValid  from "../../../../assets/images/icons/check-valid.svg";
import checkRefused  from "../../../../assets/images/icons/check-refused.svg";
import checkPending  from "../../../../assets/images/icons/check-pending.svg";
import { calcOrderTotal } from '../../../../utils/utils';

export default function OrderRow(props) {
  const { order } = props;
 
  function renderUpdatedLabel() {
    var updatedDate = new Date(order.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  function renderStatus() {
    if(order.status === "confirmed") {
      return (
        <>
          <p className="statut">Validée</p>
          <img className="check" src={checkValid} alt="check-valid"/>
        </>
      );
    } else if(order.status === "refused") {
      return (
        <>
          <p className="statut">Refusée</p>
          <img className="check" src={checkRefused} alt="check-refused"/>
        </>
      );
    } else {
      return (
        <>
          <p className="statut">En attente</p>
          <img className="check" src={checkPending} alt="check-pending"/>
        </>
      );
    };
  };

  return (
    // <Link to={"/offer-view/" + offer._id}>

      <Link to={`/order-edit/${order._id}`} className="list-row">

        {renderUpdatedLabel()}

        <div className="info order">
          <p className="name">
            {order.company.name}
          </p>

          <p className="name">
            {order.products.length && order.products[0] && order.products[0].company && order.products[0].company.name}
          </p>

          <p>
            {order.products.length}
          </p>

          <p className="products">
            {order.products.length && _.uniqBy(order.products, "product").map((product, i ) => (<span key={i}>{product.product} </span>))}
          </p>

          <p className="total bold text-primary">
            {calcOrderTotal(order.products)} €
          </p>

          <div className="picto">
            {renderStatus()}
          </div>

        </div>

      </Link>

    // </Link>
  );
};
