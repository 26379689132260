import React , { useEffect , useCallback, useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { translateDate } from "../../../utils/utils";

import { useGlobalContext } from '../../context/GlobalContext';
import { getAssemblyAction, updateAssemblyAction } from '../../../actions/assemblyActions';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

import Loader from "../../Partials/Loader";

export default function AnimationView(props) {
  props.setActivePage("assemblies")
  const [ context , dispatch ] = useGlobalContext();
  const [ comment , setComment ] = useState("");

  const assembly = context.assemblies.assembly;

  const getAssembly = useCallback(() => {
    getAssemblyAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  function handleChangeAssembly(data) {
    updateAssemblyAction(dispatch, props.match.params.id, data)
  }

  useEffect(() => {
    getAssembly();
  }, [getAssembly]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (assembly.comment) {
      setComment(assembly.comment);
    }
  }, [assembly])

  function renderAssembly() {
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>
            {assembly.company.name}
            <span className="hide-mobile text" style={{ marginLeft: 20 }}>
              Demande de montage
            </span>
          </h1>
        </header>

        <main className="page-content">

          <Link to={"/assemblies"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <div className="row col-2">
            <div className="col-2">

              <div className="card">

                <div className="information-container">

                  <p className="p-col-3">
                    <span className="bold">Heure de début de montage</span><br/>
                    {assembly.time}
                  </p>

                  <p className="p-col-3">
                    <span className="bold">Temps de montage</span><br/>
                    {assembly.duration}
                  </p>

                  <p className="p-col-3">
                    <span className="bold">Nombre de personnes</span><br/>
                    {assembly.contributorCount}
                  </p>

                  <p className="p-col-3">
                    <span className="bold">Volume (en palettes)</span><br/>
                    {assembly.volume}
                  </p>

                  <p className="p-col-3">
                    <span className="bold">Type de camion</span><br/>
                    {assembly.truckType}
                  </p>

                  {assembly.forkliftTruck ?
                    <p className="p-col-3">
                      <span className="bold">Chariot élévateur</span><br/>
                    </p>
                    :
                    null
                  }

                  {assembly.delivery ?
                    <p className="p-col-3">
                      <span className="bold">Livraison de type sèche</span><br/>
                    </p>
                    :
                    null
                  }

                  <div className="col-1">

                    {assembly.installationContact &&
                      <div className="contact-card">
                        <p className="label">Installeur</p>
                        <p className="bold" style={{ marginTop: 10 }}>{assembly.installationContact.name}</p>
                        <a className="tel" href={`tel:${assembly.installationContact.phone}`}>{assembly.installationContact.phone}</a>
                      </div>
                    }

                    {assembly?.deliveryContact &&
                      <div className="contact-card">
                        <p className="label">Livreur</p>
                        <p className="bold" style={{ marginTop: 10 }}>{assembly.deliveryContact.name}</p>
                        <a className="tel" href={`tel:${assembly.deliveryContact.phone}`}>{assembly.deliveryContact.phone}</a>
                      </div>
                    }
                    
                  </div>

                  <div className="col-2">
                    <h3 className="bold" style={{ marginTop: 0 }}>Stockage</h3>
                    <div className="list-container">
                      <button
                        onClick={() => handleChangeAssembly({ storage: true })}
                        className={`btn list-item ${assembly?.storage ? "selected" : ""}`}>
                        oui
                      </button>
                      <button
                        onClick={() => handleChangeAssembly({ storage: false })}
                        className={`btn list-item ${!assembly?.storage ? "selected" : ""}`}>
                        non
                      </button>
                    </div>
                  </div>

                  <div className="col-2">
                    <h3 className="bold" style={{ marginTop: 0 }}>Présence</h3>
                    <div className="list-container">
                      <button
                        onClick={() => handleChangeAssembly({ presence: true })}
                        className={`btn list-item ${assembly?.presence ? "selected" : ""}`}>
                        oui
                      </button>
                      <button
                        onClick={() => handleChangeAssembly({ presence: false })}
                        className={`btn list-item ${!assembly?.presence ? "selected" : ""}`}>
                        non
                      </button>
                    </div>
                  </div>

                </div>

                <span style={{ fontSize: 12 , opacity: 0.58 }} >Modifiée le {translateDate(assembly.updatedAt, 'fr')}</span>
              
              </div>

            </div>
            <div className="col-2">
              <div
                className="card notes"
                style={{ minHeight: 130}}
                >
                <textarea
                  placeholder="Notes sur le montage"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}/>
                {comment &&
                  <button
                    className="btn-edit"
                    style={{ marginTop: 10, width : 100}}
                    onClick={() => handleChangeAssembly({ comment })}
                  >
                    Enregistrer
                  </button>
                  }
              </div>
            </div>
          </div>

        </main>
      </>
    );
  };

  return (
    <div className="page-container">
      {assembly && assembly.company ? 
        renderAssembly()
      :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  );
};