import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { validateEmail } from '../../utils/utils';
import { Translate, withLocalize } from "react-localize-redux";

import { updateUserAction , createUserAction , deleteUserAction } from "../../actions/usersActions";

import SwitchField from './form_fields/SwitchField';
import selectStyle from './selectStyle';
import Input from './form_fields/Input';
import SelectList from './SelectList';

function ContactRow({ contact , daysOptions , companiesOptions, categoriesOptions , activitiesOptions , companyId , page , error , contactType , ...props }) {

    const [ lastName , setLastName ] = useState("");
    const [ firstName, setFirstName] = useState("");
    const [ email , setEmail ] = useState("");
    const [ phone , setPhone ] = useState("");
    const [ job , setJob ] = useState("");
    const [ category , setCategory ] = useState([]);
    const [ companies , setCompanies ] = useState([]);
    const [ role , setRole ] = useState("Member");
    const [ userId , setUserId ] = useState(null);
    const [ activity , setActivity ] = useState([]);

    const [ lastNameError , setLastNameError ] = useState(null);
    const [ firstNameError , setFirstNameError ] = useState(null);
    const [ emailError , setEmailError ] = useState(null);
    const [ presenceDays , setPresenceDays ] = useState([]);
    const [ isSubmit , setIsSubmit ] = useState(false);

    let userCompaniesOptions = companiesOptions?.map((c) => ({ value: c._id , label: c.name }));

    const roleOptions = [
      {
        value:"Owner",
        label: props.translate("roles.owner")
      },
      {
        value:"Member",
        label: props.translate("roles.member")
      },
    ];

    const user = {
        id: userId,
        company: companies.map((c) => c.value || c),
        email: email,
        role: role,
        profile: {
          lastName: lastName,
          firstName: firstName,
          phone: phone,
          function: job,
          category: category && category.length > 0 ? category.map(opt => (opt.value)) : [],
          activity: activity && activity.length > 0 ? activity.map(opt => (opt.value)) : [],
        }
    };

    if(contactType === 'shop') {
      userCompaniesOptions = companiesOptions?.filter((c) => c.type === "magasin")?.map((c) => ({ value: c._id , label: c.name }));
      user.type = "magasin"
    }
    if(contactType === 'provider') {
      userCompaniesOptions = companiesOptions?.filter((c) => c.type === "fournisseur")?.map((c) => ({ value: c._id , label: c.name }));;
      user.type = "user"
    }

    useEffect(() => {
      if(contact) {
        setUserId(contact._id)
        setLastName(contact.profile.lastName);
        setFirstName(contact.profile.firstName);
        setPhone(contact.profile.phone);
        setEmail(contact.email);
        if (contact.profile.function) setJob(contact.profile.function);
        if (contact.profile.category && categoriesOptions && categoriesOptions.length ) {
          var initCategory = []
          categoriesOptions.map(opt => {
            if (contact.profile.category.find(item => item === opt.value)) {
              initCategory.push(opt)
            }
            return opt;
          });
          setCategory(initCategory)
        }
        if (contact.profile.activity && activitiesOptions && activitiesOptions.length ) {
          var initActivity = []
          activitiesOptions.map(opt => {
            if (contact.profile.activity.find(item => item === opt.value)) {
              initActivity.push(opt)
            }
            return opt;
          });
          setActivity(initActivity)
        }
        if (contact.company) {
          let userCompanies = contact.company.map((c) => {
            let found = companiesOptions.find((f) => f._id === c)
            if (found?._id) {
              return { value: found._id , label: found.name }
            }
            return { value: c._id , label: c.name }
          });
          setCompanies(userCompanies);
        }
        if (contact.role) setRole(contact.role);
        if (contact.presence) setPresenceDays(contact.presence)
      }
       // eslint-disable-next-line
    }, [contact , companiesOptions]);

    useEffect(() => { 
      if(props.addCompany) {
        setCompanies([props.addCompany])
      }
    },[props.addCompany])

    useEffect(() => {
      if (isSubmit && error?.data?.error?.code === 11000 ) {
        setEmailError("Cet email est déjà utilisé");
        setIsSubmit(false)
      }
      if (isSubmit && error?.data?.error === "That email address is already in use.") {
        setEmailError("Cet email est déjà utilisé");
        setIsSubmit(false)
      }
      // eslint-disable-next-line
    },[error]) 
    
    function submitUser() {
      if (contactType !== 'shop' && contact.type !== 'magasin' && !validateEmail(user?.email)) return setEmailError("L'adresse email est invalide");
      setEmailError();
      if (userId) {
        user.presence = presenceDays
        setIsSubmit(true)
        updateUserAction( props.dispatch, user , props.contactType )
      }
    };

    function addUser() {
      setLastNameError(null);
      setFirstNameError(null);
      setEmailError(null)
      if (!user.profile.lastName) setLastNameError("Compléter le nom de l'utilisateur");
      if (!user.profile.firstName) setFirstNameError("Compléter le prénom de l'utilisateur");
      if (!validateEmail(user.email)) setEmailError("L'adresse email est invalide");
      if (user.profile.lastName && user.profile.firstName && validateEmail(user.email)) {
        if (user.role !== "Admin") {
          user.id = user.company
          user.presence = presenceDays
          setIsSubmit(true)
          createUserAction( props.dispatch, user , props.contactType )
        } else {
          setIsSubmit(true)
          user.presence = presenceDays
          createUserAction( props.dispatch, user , props.contactType )
        }
      }
    }

    function handleChangePresence( type , value) {
      let presence = [];
      if(type === "days") {
        if (value.length > 0 ) {
          value.map(day => {
            let dayValue = daysOptions.find(d => d.label === day).value
            if(presence.find(d => d === dayValue )) {
              presence = presence.filter(d => d !== dayValue );
            } else {
              presence.push(dayValue);
            }
            return null
          });
        }
        if(presenceDays.find(d => d === 'cocktail')) {
          presence.push('cocktail')
        }
      }
      if(type === "cocktail") {
        presence = [...presenceDays.filter(d => d !== 'cocktail' )]
        if (value) {
          presence.push('cocktail')
        }
      }
      return setPresenceDays(presence)
    }

    return (
      <div className="card">
        {page === "user" && <h1>{contact?.company.map((d , i ) => d.name)}</h1>} 
        <div className="row">
          <div className="col-3">
            <Input
              label={<Translate id="contacts.lastName"/>}
              value={lastName}
              type={"text"}
              error={lastNameError}
              className="input"
              onChange={(value) => setLastName(value.toUpperCase())}
              />
          </div>
          <div className="col-3">
            <Input
              label={<Translate id="contacts.firstName"/>}
              value={firstName}
              type={"text"}
              error={firstNameError}
              className="input"
              onChange={(value) => setFirstName(value)}
              />
          </div>
          <div className="col-3">
            <Input
              label={<Translate id="contacts.email"/>}
              value={email}
              type={"text"}
              format={"leclercEmail"}
              error={emailError}
              className="input"
              onChange={(value) => setEmail(value)}
              />
          </div>
        </div>
        
        <div className="row">
          <div className="col-3">
            <Input
              label={<Translate id="contacts.phone"/>}
              value={phone}
              type={"tel"}
              maskNumber={'## ## ## ## ##'}
              className="input"
              onChange={(value) => setPhone(value)}
              />
          </div>
          {role !== "Admin" && 
          <>
            <div className="col-3">
              <Input
                label={<Translate id="contacts.function"/>}
                value={job}
                type={"text"}
                className="input"
                onChange={(value) => setJob(value)}
                />
            </div>
            <div className="col-3">
              <label className="bold" style={{ fontSize: 14 }}><Translate id="contacts.role"/></label>
              <div style={{ margin: '4px 0px'}}>
                <Select
                  value={roleOptions.filter(opt => opt.value === role)}
                  onChange={(opt) => setRole(opt.value)}
                  options={roleOptions}
                  placeholder={props.translate("placeholder.select")}
                  isSearchable={false}
                  styles={selectStyle}
                />
              </div>
            </div>
          </>
        }
        </div>

        {role !== "Admin"?
          <div className="row" style={{ marginBottom: 15}}>
            {contact?.company.find(c => c.type !== "magasin") && categoriesOptions?.length > 0 &&
              <>
                <div className="col-3">
                  <label className="bold" style={{ fontSize: 14 }}><Translate id="contacts.category"/></label>
                  <Select
                    value={category}
                    onChange={(opt) => setCategory(opt)}
                    options={categoriesOptions}
                    isMulti={true}
                    placeholder={props.translate("placeholder.select")}
                    isSearchable={false}
                    styles={selectStyle}
                  />
                </div>
              </>
            }

            <div className="col-3">
              <label className="bold" style={{ fontSize: 14 }}><Translate id="contacts.company"/></label>
              <Select
                value={companies}
                onChange={(opt) => setCompanies(opt)}
                options={userCompaniesOptions}
                isMulti={true}
                placeholder={props.translate("placeholder.select")}
                isSearchable={true}
                styles={selectStyle}
              />
            </div>
            
            {contact?.company.find(c => c.type !== "fournisseur") && activitiesOptions?.length > 0 &&
              <div className="col-3">
                <label className="bold" style={{ fontSize: 14 }}><Translate id="contacts.salesPlace"/></label>
                <Select
                  value={activity}
                  onChange={(opt) => setActivity(opt)}
                  options={activitiesOptions}
                  isMulti={true}
                  placeholder={props.translate("placeholder.select")}
                  isSearchable={false}
                  styles={selectStyle}
                />
              </div>
            }
          </div>
          :
          null
        }
        

        <div className="row">
          <div className="col-3">
            <SelectList
              label={<Translate id="contacts.presenceDays"/>}
              list={daysOptions.filter(d => d.label !== "Cocktail").map(d => d.label )}
              activeList={daysOptions.filter(d => presenceDays.find(day => day === d.value )).map(d => d.label )}
              onChange={(array) => handleChangePresence("days" , array )}
            />
          </div>
          <div className="col-3">
            <label className="bold" style={{ fontSize: 14 }}><Translate id="contacts.cocktail"/></label>
            <div style={{ marginTop: 7 }}>
              <SwitchField
                checked={presenceDays.find(d => d === "cocktail") ? true : false }
                handleChange={(val) => handleChangePresence("cocktail" , val )}
              >
              </SwitchField>
            </div>
          </div>
        </div>

        {props.addUserActive && props.setAddUserActive? 
          <div className="btn-container">
            <button
              className={"btn-secondary"}
              onClick={() => props.setAddUserActive(false)}>
              <Translate id="btn.cancel"/>
            </button>
            <button
              className="btn-save"
              onClick={() => addUser()}>
              <Translate id="btn.addUser"/>
            </button>
          </div>
          :
          <div className="btn-container">
            {role !== "Admin"?
              <button
                className="btn-warning"
                onClick={() => deleteUserAction( props.dispatch , userId , props.contactType )}>
                <Translate id="btn.remove"/>
              </button>
              :
              null
            }
            <button
              className="btn-save"
              onClick={() => submitUser()}>
              <Translate id="btn.save"/>
            </button>
          </div>
        }
      </div>
    )
}

export default withLocalize(ContactRow)
