/* eslint-disable react-hooks/exhaustive-deps */
import React , { useEffect , useCallback , useState } from 'react';
// import { Translate } from "react-localize-redux";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { triAlpha , triCentrale } from '../../../utils/utils';


import { useGlobalContext } from '../../context/GlobalContext';
import { getShopsListAction , exportShopsListAction } from '../../../actions/shopsActions';

import ShopRow from './Shops_Partials/ShopsRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import filterIcon from "../../../assets/images/icons/filters.svg";
import searchIcon from "../../../assets/images/icons/search.svg";

import '../../../styles/List.scss';
import { EXPORT_SHOPS_LIST, SET_SHOPS_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function ShopsList(props) {
  props.setActivePage("shops");

  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.shops
  const [ search , setSearch ] = useState("");
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByCentrale , setSortByCentrale ] = useState(false);
  const [ sortByCity , setSortByCity ] = useState(false);
  const [ sortByContacts , setSortByContacts] = useState(false);
  const [ sortByPresence , setSortByPresence ]= useState(false);
  const [ filtredList , setFiltredList ] = useState();
  const [ exportIsLoading , setExportIsLoading] = useState(null);


  const getshopsList = useCallback(() => {
    getShopsListAction(dispatch);
    setSearch(filters.search);
    dispatch({
      type : 'SCROLL_ON_STANDS',
      payload : 0
    });
    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });
  }, [dispatch]);

  useEffect(() => {
      getshopsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.shops.scrollPos)
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    let shops = context.shops.shopsList;

    shops.sort(function(a, b) {
      var dateA = new Date(a.updatedAt)
      var dateB = new Date(b.updatedAt)
      return dateA.getTime() - dateB.getTime()
    }).reverse();

    if (search) {
      dispatch({
        type: SET_SHOPS_FILTERS,
        payload: { search }
      })
    }

    shops = context.shops.shopsList.filter(shop => shop.name.toLowerCase().includes(search.toLowerCase()) || shop.centrale.toLowerCase().includes(search.toLowerCase()) )

    setFiltredList(shops);

  }, [context.shops.shopsList, search]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByCity) {
        setFiltredList(newList.sort(function(a, b){
          var aCity = a.location.city.split(' ').join('').toLowerCase();
          var bCity = b.location.city.split(' ').join('').toLowerCase();
          if(aCity < bCity) { return -1; }
          if(aCity > bCity) { return 1; }
          return 0;
        }));
      } else {
        setFiltredList(newList.sort(function(a, b) {
          var aCity = a.location.city.split(' ').join('').toLowerCase();
          var bCity = b.location.city.split(' ').join('').toLowerCase();
          if(aCity < bCity) { return -1; }
          if(aCity > bCity) { return 1; }
          return 0;
        }).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCity]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByAlpha) {
        setFiltredList(newList.sort(triAlpha));
      } else {
        setFiltredList(newList.sort(triAlpha).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByCentrale) {
        setFiltredList(newList.sort(triCentrale));
      } else {
        setFiltredList(newList.sort(triCentrale).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCentrale]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByContacts) {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }));
      } 
    }
    // eslint-disable-next-line
  }, [sortByContacts]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByPresence) {
        setFiltredList(sortArrayByBooleen(newList , "presence"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "presence").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByPresence]);


  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item && item[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };


  function renderExportBtn() {
    if (context.shops.exportShopsList && context.shops.exportShopsList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.shops.exportShopsList.fileName}
        dataToExport={context.shops.exportShopsList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_SHOPS_LIST,
            payload : null
          });
          setExportIsLoading();
        }} 
        />
    } else {
      return <IconBtn
        label={"Exporter"}
        icon="export"
        isLoading={exportIsLoading === "shops"}
        handleClick={() => {
          exportShopsListAction(dispatch , {});
          setExportIsLoading("shops");
        }}
      />
    }
  }

  function renderShopsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map((shop, i) => <ShopRow key={i} shop={shop}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      }
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des magasins</h1>
      </header>
      <main className="page-content">
        <div className="row export">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          {renderExportBtn()}
        </div>
        <div className="filters">

          <div
            className="filter"
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du magasin</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(25%)'}}
            onClick={() => setSortByContacts(!sortByContacts)}>
            <span>Nombre d’invités</span>
            <img src={filterIcon} className={sortByContacts? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(40%)'}}
            onClick={() => setSortByCentrale(!sortByCentrale)}>
            <span>Centrale</span>
            <img src={filterIcon} className={sortByCentrale? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(120%)'}}
            onClick={() => setSortByCity(!sortByCity)}>
            <span>Ville</span>
            <img src={filterIcon} className={sortByCity? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(50%)'}}
            // onClick={() => setSortByCity(!sortByCity)}
            >
            <span>Utilisateur présent</span>
            {/* <img src={filterIcon} className={sortByCity? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            onClick={() => setSortByPresence(!sortByPresence)}>
            <span>Magasin présent</span>
            <img src={filterIcon} className={sortByPresence? "rotate180": ""} alt="filter"/>
          </div>

        </div>
        {context.shops.shopsList.length > 0 ?
          renderShopsList()
          :
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        }
      </main>
    </div>
  )
}