import React , { useCallback , useEffect } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getUserAction } from '../../../actions/usersActions';
import { getEditListsAction, getProvidersListAction } from '../../../actions/providersActions';

import ContactRow from '../../Partials/ContactRow';
import { getShopsListAction } from '../../../actions/shopsActions';

export default function UserEdit(props) {
  props.setActivePage("users");

  const [ context , dispatch ] = useGlobalContext();

  var users = context.users;
  var lists = context.providers.lists;
  const companiesOptions = [...context.providers.providersList, ...context.shops.shopsList];

  const getUser = useCallback(() => {
    getUserAction(dispatch, props.match.params.id);
    getProvidersListAction(dispatch);
    getShopsListAction(dispatch);
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });
    dispatch({
      type:'ERROR_USERS',
      payload: null
    })
  }, [dispatch , props.match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getUser();
    getEditLists();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (context.users.message === "User successfully updated!") {
      successUpdate();
    }
    if (context.users.message === "User successfully removed!") {
      successRemove();
    }
    // eslint-disable-next-line
  }, [context.users.message]);

  function successRemove() {
    props.history.push("/users")
    store.addNotification({
      message: "Utilisateur supprimé",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successUpdate() {
    store.addNotification({
      message: "Utilisateur mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function renderUserEdit() {
    if(users && users.user && users.user.email && lists.categories) {
      var companyId = props.match.params.id;
      const categoriesOptions = lists.categories.map(category => ({ label: category , value: category }));
      const activitiesOptions = lists.activities.map(domain => ({ label: domain , value: domain }));
      const daysOptions = context.providers?.lists?.days;
      return (
        <>
          <header className="page-header">
            <h1><Translate id="nav.users"/></h1>
          </header>
          <main className="page-content">
            <Link to={"/users"} className="btn-edit mb-10">
              <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
            </Link>
            <ContactRow 
              companyId={companyId}
              dispatch={dispatch}
              contact={users.user}
              error={users.error}
              page={'user'}
              daysOptions={daysOptions}
              companiesOptions={companiesOptions}
              activitiesOptions={activitiesOptions}
              categoriesOptions={categoriesOptions} />          
          </main>
        </>
      )
    }
  }

  return (
    <div className="page-container">
      {renderUserEdit()}
    </div>
  )
}
