import { getData , postData, putData } from './index';

import {
  GET_ASSEMBLY_LIST,
  ERROR_ASSEMBLY,
  GET_ASSEMBLY,
  PUT_ASSEMBLY,
  EXPORT_ASSEMBLY
} from "./types"

export async function getAssemblyListAction(dispatch){
  let url = "/assembly"
  let assemblyList;
  await getData( ERROR_ASSEMBLY, url, dispatch , true).then((response)=>{
    assemblyList = response.assemblies
  })
  dispatch({
    type : GET_ASSEMBLY_LIST,
    payload : assemblyList
  })
}

export async function getAssemblyAction(dispatch , id){
  let url = "/assembly/" + id 
  let assembly;
  await getData( ERROR_ASSEMBLY, url, dispatch , true).then((response)=>{
    assembly = response.assembly
  })
  dispatch({
    type : GET_ASSEMBLY,
    payload : assembly
  })
}

export async function updateAssemblyAction(dispatch , id, data ){
  let url = "/assembly/" + id 
  let assembly;
  await putData( PUT_ASSEMBLY , ERROR_ASSEMBLY, url, dispatch, data , true).then((response)=>{
    assembly = response.assembly
  })
  dispatch({
    type : PUT_ASSEMBLY,
    payload : assembly
  });

}

export async function exportAssembliesAction(dispatch , obj ){
  let url = "/export/assembly"
  let exportList;
  await postData(EXPORT_ASSEMBLY , ERROR_ASSEMBLY , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_ASSEMBLY,
    payload : exportList
  })
}
