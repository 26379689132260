import React, { useState , useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate , withLocalize } from "react-localize-redux";
import "../../../styles/Login.scss";

import bgLogin from "../../../assets/images/login-img.jpg";
import logo from "../../../assets/images/logo-leclerc.svg";
import eye from "../../../assets/images/icons/eye.svg"
import lock from "../../../assets/images/icons/lock.svg"

import { useGlobalContext } from '../../context/GlobalContext';
import { resetPasswordAction } from '../../../actions/authActions';

function ResetPassword(props) {

  props.setActivePage("login");

  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();

  const token = props.match.params.token;
  const [ password , setPassword ] = useState();
  const [ confirmPassword , setConfirmPassword ] = useState();
  const [ validateIsDisabled , setValidateIsDisabled] = useState(true);
  const [ message , setMessage ] = useState();
  const [ error, setError] = useState();
  const [ hidePassword , setHidePassword ] = useState(true);
  const [ backToLogin , setBackToLogin ] = useState(false);

  useEffect(()=> {
    setMessage();
    if (context.auth.error.data && context.auth.error.data.error === "Your token has expired. Please attempt to reset your password again.") {
      setBackToLogin(true);
      setError(props.translate("login.errors.expired"));
    }
    if (context.auth.error.data && context.auth.error.data.error === "You must enter a password strong enough.") {
      setError(props.translate("login.errors.reset"));
    }
    if (context.auth.message.reset) {
      setBackToLogin(true);
      setMessage(props.translate("login.message.reset"));
    }
  }, [context.auth.error , context.auth.message, props])
  
  function sumbitNewPassword() {
    if (!validateIsDisabled) {
      const confirm = {
        password : password,
        token: token
      };
      resetPasswordAction(dispatch, confirm);
    }
  }

  useEffect(() => {
    if (confirmPassword === password && password !== undefined && confirmPassword !== undefined ) {
      setValidateIsDisabled(false);
    } else {
      setValidateIsDisabled(true);
    }
  }, [confirmPassword , password]);

  function renderSetNewPassword() {
    return (
      <>
        <div className="input-login">
          <input
            type={hidePassword ? "password" : "text"}
            placeholder={props.translate("login.placeholder.password")}
            onChange={(e) => setPassword(e.target.value)}
            />
          <img src={hidePassword ? eye : lock} alt="lock" onClick={() => setHidePassword(!hidePassword)} />
        </div>
        <div className="input-login">
          <input
            type={hidePassword ? "password" : "text"}
            placeholder={props.translate("login.placeholder.confirmPassword")}
            onChange={(e) => setConfirmPassword(e.target.value)}
            />
          <img src={hidePassword ? eye : lock} alt="lock" onClick={() => setHidePassword(!hidePassword)} />
        </div>
        <button className={`btn-connection ${validateIsDisabled ? "disable" : ""}`} onClick={() => sumbitNewPassword()}><Translate id="login.reset"/></button>
      </>
    )
  }

  return (
    <div className="login-container">
      <div className="col img">
        <img src={bgLogin}  alt="bg-login"/>
      </div>
      <div className="col login">
        <div className="login-content">
          <img src={logo} alt="logo leclerc" />
          <h1>BTlec Ouest</h1>
          <h2>Salon Multimédia et Electro<br/> 15/16 mai 2024</h2>
          {backToLogin ?
            <>
              <button className="btn-connection" onClick={() => history.push("/")}><Translate id="login.backToLogin"/></button>
            </>
          :
            renderSetNewPassword()
          }
          <div className="login-message">
            <span className="text-error">{error}</span>
            <span className="text-success">{message}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLocalize(ResetPassword);