import React from "react";
import useDimension from '../customHooks/useDimension';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '../../assets/images/icons/info_icon.svg';

export default function TooltipInfo({text}){
  const dimension = useDimension()

  return (
    <Tooltip placement={dimension.width > 768 ? "right-end" : "top"}
      title={
        <React.Fragment>
          <div className="content-tooltip">
            <p>{text}</p>
          </div>
        </React.Fragment>
      }>
      <span className="tooltip"><img src={InfoIcon} alt="info" /></span>
    </Tooltip>

  )
}
