import React , { useEffect , useCallback , useState } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
// import { Translate } from "react-localize-redux";

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import ProviderRow from './Providers_Partials/ProviderRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import { useGlobalContext } from '../../context/GlobalContext';
import { triAlpha } from '../../../utils/utils';
import { getProvidersListAction , exportProvidersListAction , exportMailListAction } from '../../../actions/providersActions';

import '../../../styles/List.scss';
import { Link } from 'react-router-dom';
import { EXPORT_PROVIDERS_LIST, EXPORT_PROVIDERS_MAIL, SET_PROVIDER_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';


export default function ProvidersList(props) {
  props.setActivePage("providers");

  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.providers;
  const [ search , setSearch ] = useState("");
  const [ filtredList , setFiltredList ] = useState();
  const [ sortBySize , setSortBySize ]= useState(false);
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByElec , setSortByElec ] = useState(false);
  const [ sortByWater , setSortByWater ] = useState(false);
  const [ sortByStorage , setSortByStorage ] = useState(false);
  const [ sortByContacts , setSortByContacts ] = useState(false);
  const [ exportIsLoading , setExportIsLoading] = useState(null);


  const getProvidersList = useCallback(() => {
    getProvidersListAction(dispatch);
    setSearch(filters.search);
    dispatch({
      type : 'EXPORT_PROVIDERS_LIST',
      payload : null
    });

    dispatch({
      type : 'EXPORT_PROVIDERS_MAIL',
      payload : null
    });

    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });

    dispatch({
      type : 'REMOVE_PROVIDER_VIEW',
      payload : {}
    });

    dispatch({
      type : 'SCROLL_ON_STANDS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

  }, [dispatch, filters.search]);

  useEffect(() => {
      getProvidersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.providers.scrollProviders)
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    let providers = context.providers.providersList
    
    providers.sort(function(a, b) {
      var dateA = new Date(a.updatedAt)
      var dateB = new Date(b.updatedAt)
      return dateA.getTime() - dateB.getTime()
    }).reverse();


    dispatch({
      type: SET_PROVIDER_FILTERS,
      payload: { search }
    })
  

    var newList = providers.filter((provider) => {
      if (provider?.name?.toLowerCase()?.includes(search?.toLowerCase())){
        return provider;
      } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user?.email?.toLowerCase()?.includes(search?.toLowerCase()))) {
        return provider;
      } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user?.profile?.lastName?.toLowerCase()?.includes(search?.toLowerCase()))) {
        return provider;
      }
      return null;
    });
    setFiltredList(newList);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.providers.providersList, search])

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortBySize) {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }));
      } 
    }
    // eslint-disable-next-line
  }, [sortBySize]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByContacts) {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }));
      } 
    }
    // eslint-disable-next-line
  }, [sortByContacts]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByStorage) {
        setFiltredList(sortByStorageDimension(newList).reverse());
      } else {
        setFiltredList(sortByStorageDimension(newList));
      } 
    }
    // eslint-disable-next-line
  }, [sortByStorage]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByElec) {
        setFiltredList(sortArrayByBooleen(newList , "power"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "power").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByElec]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByWater) {
        setFiltredList(sortArrayByBooleen(newList , "water"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "water").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByWater]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByAlpha) {
        setFiltredList(newList.sort(triAlpha));
      } else {
        setFiltredList(newList.sort(triAlpha).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };

  function sortByStorageDimension(array)  {
    var noStorage  = []
    var storage = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption.storageSize) {
        storage.push(item)
      } else {
        noStorage.push(item)
      }
    })
    return [...noStorage , ...storage.sort((a,b) => a.showroomOption.storageSize - b.showroomOption.storageSize) ];
  };

  function renderExportProviders() {
    if (context.providers.exportProvidersList && context.providers.exportProvidersList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.providers.exportProvidersList.fileName}
        dataToExport={context.providers.exportProvidersList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_PROVIDERS_LIST,
            payload : null
          });
          setExportIsLoading();
        }} 
      />
    } else {
      return <IconBtn
      label={"Exporter"}
      icon="export"
      isLoading={exportIsLoading === "providers"}
      handleClick={() => {
        exportProvidersListAction(dispatch , { company: null })
        setExportIsLoading("providers");
      }}
      />
    }
  }

  function renderExportMail() {
    if (context.providers.exportProvidersMails && context.providers.exportProvidersMails.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.providers.exportProvidersMails.fileName}
        dataToExport={context.providers.exportProvidersMails.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_PROVIDERS_MAIL,
            payload : null
          });
          setExportIsLoading();
        }} 
      />
    } else {
      return <IconBtn
      label={"Exporter les emails"}
      icon="invit"
      isLoading={exportIsLoading === 'email'}
      handleClick={() => {
        exportMailListAction(dispatch , { company: null })
        setExportIsLoading("email");
      }} 
    />
    }
  }

  function renderProviderList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map((provider, i) => <ProviderRow key={i} provider={provider} activePage={props.activePage} dashBoard={false}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else {
        return (
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        )
      }
    } 
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des fournisseurs</h1>
      </header>
      <main className="page-content">
        <div className="row export">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className="search-icon" src={searchIcon} alt="search"/>
          </div>
          <div className='btns'>
            {renderExportMail()}
            {renderExportProviders()}
            <Link to={'/provider-create/'}>
            <button 
              className="btn-edit"
              style={{ height:36, paddingLeft: 15 , paddingRight: 15 }}>
              Ajouter un fournisseur
            </button>
            </Link>
          </div>
        </div>
        <div className="filters">
          <div className="filter" onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Trier par ordre alphabétique</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>
          <div style={{ minWidth: '70%', display: 'flex', justifyContent: 'space-between'}}>
            <div className="filter" onClick={() => setSortByContacts(!sortByContacts)}>
              <span>Nombre d’invités </span>
              <img src={filterIcon} className={sortByElec? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByElec(!sortByElec)}>
              <span>Electricité </span>
              <img src={filterIcon} className={sortByElec? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByWater(!sortByWater)}>
              <span>Eau</span>
              <img src={filterIcon} className={sortByWater? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByStorage(!sortByStorage)}>
              <span>Stockage</span>
              <img src={filterIcon} className={sortByStorage? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" style={{ minWidth: '26%', display: 'flex', justifyContent: 'flex-end'}} onClick={() => setSortBySize(!sortBySize)}>
              <span>Trier par dimensions</span>
              <img src={filterIcon} className={sortBySize? "rotate180": ""} alt="filter"/>
            </div>
          </div>
        </div>
        {renderProviderList()}
      </main>
    </div>
  )
}