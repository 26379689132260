import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import star from "./../../assets/images/icons/star.svg";
import starActive from "./../../assets/images/icons/star_active.svg"
import { useGlobalContext } from '../context/GlobalContext';
import { getProviderAction } from '../../actions/providersActions';
import { updateUserAction } from '../../actions/usersActions';
import { getShopAction } from '../../actions/shopsActions';

export default function Contacts({ contacts , companyId , daysOptions, root, ...props }) {
  const dispatch = useGlobalContext()[1];

  async function handleChangeUserRole(id, role) {
    if (role !== "Admin") {
      const data = {
        id: id,
        role: role === "Owner" ? "Member": "Owner"
      }
      await updateUserAction(dispatch, data);
      if (root === 'shop') {
        await getShopAction(dispatch, companyId);
      } else {
        await getProviderAction(dispatch, companyId);
      }
    }
  }
  function renderPresenceDays(contact) {
    var days = []
    if (!daysOptions) return null;
    daysOptions.forEach(day => {
      let found = contact.presence.find(d => d === day.value)
      if (found) days.push(day.label)
    });
    return days.map((item ,i ) => <div key={i} className="list-item selected">{item}</div>)
  }
  return (
    <>
      <div className="title">
        <h2><Translate id="contacts.title"/></h2>
        <Link to={"/" + root + "-contact-edit/" + companyId} >
          <button className="btn-edit" style={{ width : 100}} ><Translate id="btn.edit"/></button>
        </Link>
      </div>
      <div className="row contact-list">
        {contacts.map((contact,i) => {
          return (
            <div key={i} className="col-1">
              <div className="card contact-card">
                <h2>{contact.profile.firstName} {contact.profile.lastName}</h2>
                {contact.role !== "Admin" &&
                  <button
                    className="star"
                    onClick={() => handleChangeUserRole(contact._id , contact.role)}
                  >
                    <img src={contact.role === "Owner"? starActive : star} alt="star" />
                  </button>
                }
                  {contact.profile.function ? <div className="text-primary" >{contact.profile.function}</div> : null }
                <p className="email">{contact.email}</p>
                <p className="email">{contact.profile?.phone}</p>
                <div className="information-container">
                  {renderPresenceDays(contact)}
                </div>
              </div>
            </div>
          )
        })} 
      </div>
    </>
  )
}
