import React , { useCallback , useEffect , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , getEditListsAction, getProvidersListAction } from '../../../actions/providersActions';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import ContactRow from '../../Partials/ContactRow';

export default function ProviderContactsEdit(props) {
  props.setActivePage("providers");

  const [ context , dispatch ] = useGlobalContext();

  const [ addUserActive , setAddUserActive ] = useState(false);

  var provider = context.providers.provider;
  var lists = context.providers.lists;
  const companiesOptions = context.providers.providersList;

  const getProvider = useCallback(() => {
    getProviderAction(dispatch, props.match.params.id);
    getProvidersListAction(dispatch);
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });
    dispatch({
      type:'ERROR_USERS',
      payload: null
    })
  }, [dispatch , props.match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  useEffect(() => {
    if (addUserActive) {
      var el = document.getElementById("add-user");
      el.scrollIntoView();
    }
  }, [addUserActive])

  useEffect(() => {
    getEditLists();
    if(!provider.company) {
      getProvider();
    }
    // eslint-disable-next-line
  }, [getProvider , getEditLists , provider]);

  useEffect(() => {
    if (context.users.message === "User successfully updated!") {
      successUpdate();
      getProvider();
    }
    if (context.users.message === "user register !") {
      successCreate();
      getProvider();
    }
    if (context.users.message === "User successfully removed!") {
      successRemove();
      getProvider();
    }
    // eslint-disable-next-line
  }, [context.users.message]);

  function successRemove() {
    store.addNotification({
      message: "Utilisateur supprimé",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successUpdate() {
    store.addNotification({
      message: "Utilisateur mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successCreate() {
    setAddUserActive(false)
    store.addNotification({
      message: "Utilisateur inscrit!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  function renderProviderContacts() {
    if(provider.users && lists.categories) {
      var companyId = props.match.params.id;
      const daysOptions = lists.days;
      const categoriesOptions = lists.categories.map(category => ({ label: category , value: category }));
      return (
        <>
          <header className="page-header">
            <div className="icon">
              <img src={providerIcon} alt="icon-provider"/>
            </div>
            <h1>{provider.company.name}</h1>
          </header>
          <main className="page-content">
            <Link to={"/provider-view/" + companyId } className="btn-edit mb-10">
              <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
            </Link>

            {context.providers.provider.users.filter(user => user._id !== context.auth.user._id).map(( contact ,i ) => <ContactRow 
              key={i}
              companyId={companyId}
              contact={contact}
              dispatch={dispatch}
              error={context.users.error}
              contactType="provider"
              daysOptions={daysOptions}
              companiesOptions={companiesOptions}
              categoriesOptions={categoriesOptions} />)}

            {addUserActive ?
              <div id="add-user">
                <ContactRow 
                  companyId={companyId}
                  addUserActive={addUserActive}
                  addCompany={addUserActive && { value: companyId , label: provider.company.name }}
                  setAddUserActive={setAddUserActive}
                  dispatch={dispatch}
                  error={context.users.error}
                  contactType="provider"
                  daysOptions={daysOptions}
                  companiesOptions={companiesOptions}
                  categoriesOptions={categoriesOptions} />
              </div>
              :            
              <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
                <button
                  className={"btn-secondary"}
                  onClick={() => setAddUserActive(true)}
                  >
                    <Translate id="btn.addaUser"/>
                </button>
              </div>
            }

          </main>
        </>
      )
    }
  }

  return (
    <div className="page-container">
      {renderProviderContacts()}
    </div>
  )
}
