import React , { useEffect , useCallback } from 'react';
import { API_URL } from '../../../actions/index';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import format from 'format-number';

import { translateDate } from "../../../utils/utils";

import { useGlobalContext } from '../../context/GlobalContext';
import { getOfferAction } from '../../../actions/offersActions';

// import Input from "../../Partials/form_fields/Input";
import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

// import Animation from "./Animations_Partials/Animation";
import Loader from "../../Partials/Loader";

export default function OfferView(props) {
  props.setActivePage("offers");

  const [ context , dispatch ] = useGlobalContext();

  const offer = context.offers.offer;

  const getOffer = useCallback(() => {
    getOfferAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  useEffect(() => {
    getOffer();
  }, [getOffer]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function fnFormatFixed(number) {
    let value = format({integerSeparator: " "})(number.toFixed(1))
    if(/.0/.test(value)){
      return value.replace(".0","")
    }else{
      return value
    }
  }

  function renderOffer() {
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>
            <span className="hide-mobile text" style={{ marginLeft: 0 }}>
              {offer.ref}
            </span>
          </h1>
        </header>

        <main className="page-content">

          <Link to={"/offers"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <Link to={`/offer-edit/${offer._id}`} className="btn-edit mb-10" style={{  marginLeft: 10}}>
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.edit"/></button>
          </Link>

          <div className="row col-2">
            <div className="col-70">

              <div className="card">
                <div className="product-img">
                {offer.image?.path && <img
                  src={`${API_URL}/files/${offer.image?.path.replace('upload/', '')}`}
                  alt="product"/>}
              </div>

                <div className="information-container offer">

                  <p className="p-col-3 offer">
                    <span className="bold">Avantage salon :</span><br/>
                    <span className="number bold text-orange">{fnFormatFixed(offer.discount*100)} %</span>
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Prix net HT :</span><br/>
                    <span className="number bold text-primary">{offer.price && offer.price.toFixed(2)} €</span>
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Prix PA :</span><br/>
                    <span className="number bold text-primary">{offer.pricePA} €</span>
                  </p>

                </div>

                <div className="information-container offer border">
                  <p className="p-col-3 offer">
                    <span className="bold">Fournisseur :</span><br/>
                    {offer.company.name}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Famille de produit :</span><br/>
                    {offer.product}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Numéro de dossier :</span><br/>
                    {offer.fileNumber}
                  </p>

                </div>

                <div className="information-container offer border">

                  <p className="p-col-3 offer">
                    <span className="bold">Code article :</span><br/>
                    {offer.code}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Référence produit :</span><br/>
                    {offer.ref}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Gencod :</span><br/>
                    {offer.gencod}
                  </p>

                </div>

                <div className="information-container offer border">

                  <p className="p-col-3 offer">
                    <span className="bold">Marque :</span><br/>
                    {offer.brand}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Désignation produit :</span><br/>
                    {offer.description}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Typo :</span><br/>
                    {offer.type}
                  </p>

                </div>

                <div className="information-container offer border">

                  <p className="p-col-3 offer">
                    <span className="bold">Colisage :</span><br/>
                    {offer.packing}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">DEEE HT :</span><br/>
                    {offer.deee}
                  </p>

                  <p className="p-col-3 offer">
                    <span className="bold">Autres taxes : </span><br/>
                    {offer.tax}
                  </p>

                </div>

                <span style={{ fontSize: 12 , opacity: 0.58 }} >Modifiée le {translateDate(offer.updatedAt, 'fr')} </span>
              
              </div>

            </div>
          </div>

        </main>
      </>
    );
  };

  return (
    <div className="page-container">
      {offer && offer.company ? 
        renderOffer()
      :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  );
};