import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { calculPrice , translateDate } from "../../../../utils/utils";

export default function Showroom(props) {

  function renderShape(shape) {
    switch (shape) {
      case 'U':
        return "En U";
      case 'L':
        return "En L";
      case 'back':
        return "Cloison de fond";
      default:
        return "En îlot";
    }
  }

  function renderShowroom() {

    if(props.showroom && props.showroom.option) {
      const { showroom } = props;
      const option = showroom.option;

      return (
        <>
          <div className="information-container">

            <p className="p-col-3">
              <span className="bold"><Translate id="showroom.type"/></span><br/>
              {renderShape(option.shape)}
            </p>

            {option.direction ?
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.position"/></span><br/>
                {option.direction === "l" ? "Dos à dos" : "côte à côte"}
              </p>
              :
              null
            }

            {option.customDimension && option.customDimension.L ?
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.customSize"/></span><br/>
                {showroom.dimension} m<sup>2</sup> ({option.customDimension.L} x {option.customDimension.l} m)
              </p>
              :
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.size"/></span><br/>
                {showroom.dimension} m<sup>2</sup>
              </p>
            }

            {option.carpet &&
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.moquette"/></span><br/>
                aux couleurs du salon
              </p>
            }

            {option.customCarpet &&
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.moquette"/></span><br/>
                {option.carpetDesc}
              </p>
            }

            {option.kit &&
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.kit"/></span><br/>
                {option.countKit} x Kit(s)
              </p>
            }

            <p className="p-col-3">
              <span className="bold"><Translate id="showroom.electricity"/></span><br/>
              {option.power? "20 kVa" : "6 kVa"}
            </p>

            {option.water &&
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.water"/></span><br/>
                {option.waterType}
              </p>
            }

            {option.storage &&
              <p className="p-col-3">
                <span className="bold"><Translate id="showroom.stock"/></span><br/>
                {option.storageSize} (équivalent palette 80x120)
              </p>
            }

          </div>

          {option.standiste &&
            <div className="col-1">
              <span className="bold"><Translate id="showroom.standiste"/></span><br/>
              <div className="information-container">
                <p className="p-col-3 contact border">
                  {option.standiste.name}
                </p>
                <p className="p-col-3 contact border">
                  <a className="tel" href={`tel:${option.standiste.phone}`}>{option.standiste.phone}</a>
                </p>
                <p className="p-col-3 contact">
                  {option.standiste.email}
                </p>
              </div>
            </div>
          }

          <p className="col-1">
            <span className="bold"><Translate id="total"/></span><br/>
            {calculPrice(showroom).total} €
          </p>

          <p className="col-1" style={{ fontSize: 12 , opacity: 0.58 }} >Modifié le {translateDate(showroom.updatedAt, 'fr')}</p>
        
        </>
      );
    } else {
      return (
        <div className="container-center">
          <Link to={`/showroom-edit/${props.companyId}'/providers`} className="btn-save" style={{ padding: "5px 15px"}} ><Translate id="btn.createShowroom"/></Link>
        </div>
      );
    };
  }

  return (
    <>

      <div className="title">
        <h2><Translate id="showroom.title"/></h2>
        {props.showroom && props.showroom.company &&
          <Link to={`/showroom-edit/${props.showroom.company}/providers`} className="btn-edit">
            <Translate id="btn.edit"/>
          </Link>
        }
      </div>

      <div className="card info">
        {renderShowroom()}
      </div>

    </>
  );
};
