import { postData , getData, putData } from './index';

import {
  IS_AUTH,
  USER_AUTH,
  ERROR_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_DASHBOARD,
  GET_EXHIBITION
} from "./types"

export async function loginAction(dispatch , obj){
  let url = "/auth/login";
  var login;

  await postData(IS_AUTH , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    login = response
  });

  if (login.user.role === "Admin" || login.user.role === "Accueil") {
    localStorage.setItem("token" , login.token);
    dispatch({
      type : IS_AUTH,
      payload : true
    });
  }
};

export async function forgotPasswordAction(dispatch , obj){
  let url = "/auth/forgot-password";
  var mailSend;

  await postData(FORGOT_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    mailSend = response
  });

  dispatch({
    type : FORGOT_PASSWORD,
    payload : mailSend
  });
};

export async function resetPasswordAction(dispatch , obj){
  let url = "/auth/reset-password/" + obj.token;
  var reset;

  await postData(RESET_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    reset = response
  });
  
  dispatch({
    type : RESET_PASSWORD,
    payload : reset
  });
};

export async function getUserAction(dispatch){
  let url = "/profile";
  var user;

  await getData( ERROR_AUTH, url, dispatch, true ).then((response)=>{
    user = response.user;
  });

  dispatch({
    type : ERROR_AUTH,
    payload : {}
  });

  dispatch({
    type : USER_AUTH,
    payload : user
  });
};

export async function getDashbordAction(dispatch){
  let url = "/company/dashboard";
  var dashbord;

  await getData( ERROR_AUTH, url, dispatch, true ).then((response)=>{
    dashbord = response;
  });
  dispatch({
    type : ERROR_AUTH,
    payload : {}
  });
  dispatch({
    type : GET_DASHBOARD,
    payload : dashbord
  });
};

export async function getExhibitionAction(dispatch){
  let url = "/exhibition";
  var exhibition;

  await getData( ERROR_AUTH, url, dispatch, true ).then((response)=>{
    exhibition = response.exhibition
    ;
  });
  dispatch({
    type : GET_EXHIBITION,
    payload : exhibition,
  });

};

export async function putExhibitionAction(dispatch , obj ){
  let url = "/exhibition/"
  var exhibition;
  await putData(GET_EXHIBITION,  ERROR_AUTH , url, dispatch , obj, true ).then((response)=>{
    exhibition = response.exhibition
  });

  dispatch({
    type : GET_EXHIBITION,
    payload : exhibition
  })
}
