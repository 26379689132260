import {
  GET_OFFERS_LIST,
  ERROR_OFFERS,
  SCROLL_ON_OFFERS,
  GET_OFFER,
  SET_OFFERS_FILTERS,
  PUT_OFFER,
  DELETE_OFFER,
  EXPORT_OFFERS
} from "../actions/types"

const initialState = {
  offersList: [],
  offer: {},
  error: {},
  scrolloffers: 0,
  message: {},
  exportList: null,
  filters: {
    search: "",
    products: [],
  },
};

export default function offersReducer(state = initialState, action) {
  const offersList = state.offersList;
  const offerIndex = offersList.findIndex((o) => o._id === action.payload?._id)
  switch (action.type) {
    case SET_OFFERS_FILTERS:
      return {...state, filters : {...state.filters, ...action.payload }}
    case PUT_OFFER:
      if (offerIndex !== -1) {
        offersList[offerIndex] = action.payload;
      }
      return {
        ...state,
        offersList: [...offersList],
        offer: action.payload
      }
    case GET_OFFER:
      return {...state, offer : action.payload}
    case DELETE_OFFER:
      return {...state, offersList: offersList.filter((o) => o._id !== action.payload)}
    case GET_OFFERS_LIST:
      return {...state, offersList : action.payload}
    case EXPORT_OFFERS:
      return {...state, exportList : action.payload}
    case ERROR_OFFERS:
        return {...state, error: action.payload}
    case SCROLL_ON_OFFERS:
      return {...state, scrollOffers: action.payload}
    default:
      return {...state};
  }
}
