export const  GET_USER_LIST = 'GET_USER_LIST',
              ERROR_USERS = 'ERROR_USERS',
              MESSAGE_USERS = 'MESSAGE_USERS',
              GET_USER = 'GET_USER',
              SET_USERS_FILTERS = 'SET_USERS_FILTERS',
              SET_PROVIDER_USER = 'SET_PROVIDER_USER',
              EXPORT_PRESENCES = 'EXPORT_PRESENCES',
              //AUTH_ACTIONS
              IS_AUTH =  'IS_AUTH',
              USER_AUTH ='USER_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              INIT_AUTH ='INIT_AUTH',
              GET_DASHBOARD ='GET_DASHBOARD',
              SET_FILE = 'SET_FILE',
              GET_EXHIBITION = 'GET_EXHIBITION',
              //PROVIDERS & STANDS ACTIONS
              GET_PROVIDERS_LIST='GET_PROVIDERS_LIST',
              ERROR_PROVIDERS='ERROR_PROVIDERS',
              GET_PROVIDER='GET_PROVIDER',
              RESET_PROVIDER ='RESET_PROVIDER',
              REMOVE_PROVIDER_VIEW='REMOVE_PROVIDER_VIEW',
              GET_EDIT_LISTS= 'GET_EDIT_LISTS',
              POST_PROVIDER = 'POST_PROVIDER',
              MESSAGE_PROVIDER= 'MESSAGE_PROVIDER',
              SCROLL_ON_PROVIDERS = 'SCROLL_ON_PROVIDERS',
              SET_STANDS_FILTERS = 'SET_STANDS_FILTERS',
              SCROLL_ON_STANDS = 'SCROLL_ON_STANDS',
              SET_PROVIDER_FILTERS = 'SET_PROVIDER_FILTERS',
              EXPORT_PROVIDERS_LIST ='EXPORT_PROVIDERS_LIST',
              EXPORT_PROVIDERS_MAIL = 'EXPORT_PROVIDERS_MAIL',
              //SHOPS_ACTIONS
              GET_SHOPS_LIST = 'GET_SHOPS_LIST',
              GET_SHOP = 'GET_SHOPS',
              ERROR_SHOPS = 'ERROR_SHOPS',
              MESSAGE_SHOPS = 'MESSAGE_SHOPS',
              SET_SHOPS_FILTERS = 'SET_SHOPS_FILTERS',
              SCROLL_ON_SHOPS = 'SCROLL_ON_SHOPS',
              EXPORT_SHOPS_LIST = 'EXPORT_SHOPS_LIST',
              //ANIMATIONS_ACTIONS
              GET_ANIMATIONS_LIST = 'GET_ANIMATIONS_LIST',
              ERROR_ANIMATIONS = 'ERROR_ANIMATIONS',
              SCROLL_ON_ANIMATIONS = 'SCROLL_ON_ANIMATIONS',
              SET_ANIMATIONS_FILTERS = 'SET_ANIMATIONS_FILTERS',
              GET_ANIMATION = 'GET_ANIMATION',
              MESSAGE_ANIMATIONS = 'MESSAGE_ANIMATIONS',
              REMOVE_FILE ='REMOVE_FILE',
              //ASSEMBLY ACTIONS
              GET_ASSEMBLY_LIST = 'GET_ASSEMBLY_LIST',
              ERROR_ASSEMBLY = 'ERROR_ASSEMBLY',
              POST_ANIMATION = 'POST_ANIMATION',
              SCROLL_ON_ASSEMBLY = 'SCROLL_ON_ASSEMBLY',
              SET_ASSEMBLY_FILTERS = 'SET_ASSEMBLY_FILTERS',
              GET_ASSEMBLY = 'GET_ASSEMBLY',
              EXPORT_ASSEMBLY = 'EXPORT_ASSEMBLY', 
              PUT_ASSEMBLY = 'PUT_ASSEMBLY',
              //RECEPTION ACTIONS
              GET_RECEPTION_LIST ='GET_RECEPTION_LIST',
              GET_RECEPTION_USER ='GET_RECEPTION_USER',
              ERROR_RECEPTION ='ERROR_RECEPTION',
              MESSAGE_RECEPTION ='MESSAGE_RECEPTION',
              GET_BADGE ='GET_BADGE',
              //OFFERS ACTIONS
              GET_OFFERS_LIST = 'GET_OFFERS_LIST',
              ERROR_OFFERS = 'ERROR_OFFERS',
              SET_OFFERS_FILTERS = 'SET_OFFERS_FILTERS',
              SCROLL_ON_OFFERS = 'SCROLL_ON_OFFERS',
              GET_OFFER = 'GET_OFFER',
              PUT_OFFER = 'PUT_OFFER',
              MESSAGE_OFFERS = 'MESSAGE_OFFERS',
              DELETE_OFFER = 'DELETE_OFFER',
              EXPORT_OFFERS = 'EXPORT_OFFERS',
              //ORDERS ACTIONS
              GET_ORDERS_LIST = 'GET_ORDERS_LIST',
              ERROR_ORDERS = 'ERROR_ORDERS',
              SCROLL_ON_ORDERS = 'SCROLL_ON_ORDERS',
              GET_ORDER = 'GET_ORDER',
              GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST',
              SET_ORDERS_FILTERS = 'SET_ORDERS_FILTERS',
              MESSAGE_ORDERS ='MESSAGE_ORDERS',
              EXPORT_ORDERS = 'EXPORT_ORDERS',
              VALIDATE_ORDER = 'VALIDATE_ORDER',
              DELETE_ORDER = 'DELETE_ORDER';


 