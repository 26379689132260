import React, { useState } from 'react';
import { Link } from "react-router-dom";

export default function NavLink(props) {

  const [ isHover , setIsHover ] = useState(false);
  const link = props.link;

  return (
    <li
      onClick={() => props.setShowNav(!props.showNav)}>
      <Link
        className="btn-nav"
        onClick={() => props.setActivePage(link.name) && props.setShowReception(false)}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        to={link.url}>
        <img src={isHover || link.name === props.activePage ? link.iconHover : link.icon} alt={link.name} />
        <span className={isHover || link.name === props.activePage ? "nav-label active" : "nav-label"}>{link.label}</span>
      </Link>
    </li>
  )
}
