import React , { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useParams } from 'react-router-dom';
import _ from  'lodash';
import { calculPrice } from '../../../utils/utils';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , createShowroomAction , updateShowroomAction } from '../../../actions/providersActions';

import ShowroomSize from './Providers_Partials/ShowroomSize';
import ShowroomShape from './Providers_Partials/ShowroomShape';
import ShowroomOptions from './Providers_Partials/ShowroomOptions';

import '../../../styles/Edit.scss';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

export default function ShowroomEdit(props) {
  props.setActivePage("providers");

  const params = useParams();

  const [ context , dispatch ] = useGlobalContext();

  const [ activeOption, setActiveOption ] = useState("option-1");
  const [ dimension, setDimension ] = useState(6);
  const [ companyId ,setCompanyId ] = useState(null);
  const [ userId ,setUserId ] = useState(null);
  const [ showroomId ,setShowroomId ] = useState(null);
  const [ direction, setDirection ] = useState('l');
  const [ customDimension , setCustomDimension] = useState(null);
  const [ shape, setShape ] = useState();
  const [ carpet, setCarpet] = useState(false);
  const [ customCarpet, setCustomCarpet] = useState(false);
  const [ carpetDesc, setCarpetDesc] = useState("");
  const [ kit, setKit] = useState(false);
  const [ countKit, setCountKit] = useState(null);
  const [ power, setPower] = useState(false);
  const [ water, setWater ] = useState(false);
  const [ waterType, setWaterType] = useState(null);
  const [ storage, setStorage] = useState(false);
  const [ internet, setInternet] = useState(false);
  const [ storageSize, setStorageSize] = useState("");
  const [ standisteName, setStandisteName ] = useState(null);
  const [ standisteEmail, setStandisteEmail ] = useState(null);
  const [ standistePhone, setStandistePhone ] = useState(null);

  const [ longueur , setLongueur ] = useState(0);
  const [ profondeur  , setProfondeur ] = useState(0);

  var customDimensionObj = {
    l: longueur,
    L: profondeur
  };

  useEffect(() => {
    if (activeOption === "option-2") {
      setCustomDimension(customDimensionObj);
      setDimension(profondeur * longueur);
    } else {
      setCustomDimension(null);
    }
    // eslint-disable-next-line
  }, [longueur , profondeur , activeOption ])

  const [ submitIsActive , setSubmitIsActive ] = useState(false);

  const [ shapeError , setShapeError ] = useState();
  const [ customError , setCustomError] = useState(null);
  const [ directionError , setDirectionError ] = useState(null);
  const [ customCarpetError , setCustomCarpetError ] = useState(null);
  const [ kitError , setKitError ] = useState(null);
  const [ waterError , setWaterError ] = useState(null);
  const [ storageError , setStorageError ] = useState(null);

  const [ initShowroom , setIntiShowroom ] = useState({});
  const [ updatedShowroom , setUpdatedShowroom ] = useState();

  var provider = context.providers.provider;

  var standiste = {
    name: standisteName,
    phone: standistePhone,
    email: standisteEmail
  }

  var showroom = {
    _id: showroomId,
    dimension: dimension,
    company: companyId,
    user: userId,
    option:{
      direction : direction,
      customDimension : customDimension,
      shape: shape,
      carpet: carpet,
      customCarpet: customCarpet,
      carpetDesc: carpetDesc,
      kit: kit,
      internet,
      countKit: countKit,
      power: power,
      water: water,
      waterType: waterType ,
      storage: storage,
      storageSize: storageSize,
      standiste: standisteEmail || standisteName  || standistePhone ? standiste : null
    }
  }

  const getProvider = useCallback(() => {
    getProviderAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  useEffect(() => {
    if (context.auth.user && context.auth.user._id)
    setUserId(context.auth.user._id);
  }, [context.auth]);

  useEffect(() => {
    resetShowroomData();
    window.scrollTo(0, 0);
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetShowroomData();
     // eslint-disable-next-line
  }, [provider]);

  function resetShowroomData() {
    if (provider.company) {
      setCompanyId(provider.company._id)
    }
    if (provider.showrooms && provider.showrooms.length > 0) {
      const lastShowroom  = _.cloneDeep(provider.showrooms[0]);
      if (lastShowroom) {
        setShowroomId(lastShowroom._id)
        setIntiShowroom(lastShowroom)
        setInternet(!!lastShowroom.option.internet);
        setDirection(lastShowroom.option.direction);
        setDimension(lastShowroom.dimension);
        if (lastShowroom.option.customDimension) setCustomDimension(lastShowroom.option.customDimension);
        if (lastShowroom.option.carpet) {
          setCarpet(lastShowroom.option.carpet);
          setCustomCarpet(false);
        } else {
          setCarpet(false);
          setCustomCarpet(true);
        }
        if (lastShowroom.option.carpetDesc) {
          setCarpetDesc(lastShowroom.option.carpetDesc);
        }
        if (lastShowroom.option.kit) {
          setKit(lastShowroom.option.kit);
        } else {
          setKit(false);
          setCountKit(null);
        }
        if (lastShowroom.option.countKit) setCountKit(lastShowroom.option.countKit);
        if (lastShowroom.option.power) setPower(lastShowroom.option.power);
        if (lastShowroom.option.shape) setShape(lastShowroom.option.shape);
        if (lastShowroom.option.water) setWater(lastShowroom.option.water);
        if (lastShowroom.option.waterType) setWaterType(lastShowroom.option.waterType);
        if (lastShowroom.option.storage) setStorage(lastShowroom.option.storage);
        if (lastShowroom.option.storageSize) {
          setStorageSize(lastShowroom.option.storageSize)
        };
        if (lastShowroom.option.storageSize === 0 ) {
          console.log(lastShowroom.option.storageSize)

          setStorageSize(0)
        };
        if (lastShowroom.option.standiste && lastShowroom.option.standiste.name) setStandisteName(lastShowroom.option.standiste.name);
        if (lastShowroom.option.standiste && lastShowroom.option.standiste.email) setStandisteEmail(lastShowroom.option.standiste.email);
        if (lastShowroom.option.standiste && lastShowroom.option.standiste.phone) setStandistePhone(lastShowroom.option.standiste.phone)
      }
    }
  }

  useEffect(() => {
    setUpdatedShowroom(showroom);

    setKitError(null)
    setShapeError(null);
    setCustomError(null);
    setDirectionError(null);
    setCustomCarpetError(null);
    setWaterError(null);
    setStorageError(null);

    if(activeOption  === "option-1" && !direction && dimension >  6) {
      setDirectionError("Sélectionner la direction de votre emplacement.");
      setSubmitIsActive(false);
    } else if (!shape) {
      setShapeError("Sélectionner la forme de votre stand.");
      setSubmitIsActive(false);
    } else if (customCarpet && !carpetDesc) {
      setCustomCarpetError("Complèter la description de votre moquette.");
      setSubmitIsActive(false);
    } else if (kit && !countKit) {
      setKitError("Séléctionner le nombre de kit(s).");
      setSubmitIsActive(false);
    } else if (water && !waterType) {
      setWaterError("Séléctionner le type d'alimentation en eau.");
      setSubmitIsActive(false);
    } else if (storage && !storageSize) {
      setStorageError("Compléter la superficie en m2.");
      setSubmitIsActive(false);
    } else {
      setSubmitIsActive(true);
    };
    // eslint-disable-next-line
  },[ dimension , internet, direction, customDimension , carpet , customCarpet , activeOption , profondeur , longueur , shape , carpetDesc , countKit, kit , power , water, waterType, storage , storageSize, standisteEmail, standisteName, standistePhone, ] );

  useEffect(() => {
    if(!provider.company) {
      getProvider();
    }
    // eslint-disable-next-line
  }, [getProvider]);

  function subitShowroom() {
    if (updatedShowroom._id === null) {
      createShowroomAction(dispatch , updatedShowroom)
    } else {
      updateShowroomAction(dispatch , updatedShowroom)
    }
  }

  useEffect(() => {
    if (context.providers.message === 'Showroom successfully updated!') {
      successUpdate();
    }
    if (context.providers.message === 'showroom saved') {
      successCreated();
    }
    // eslint-disable-next-line 
  }, [context.providers.message])

  function successCreated() {
    store.addNotification({
      message: "Stand a été créé avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
  }

  function successUpdate() {
    store.addNotification({
      message: "Stand mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
  }

  function renderShowroomEdit() {
    var id;
    if (provider && provider.company && provider.company._id ) {
      id = provider.company._id
    };
    if (provider && provider.company) {
      return (
        <>
          <header className="page-header">
            <div className="icon">
              <img src={providerIcon} alt="icon-provider"/>
            </div>
            <h1>{provider.company.name}</h1>
          </header>
          <main className="page-content">
            <Link to={params.activePage !== "stands" ? "/provider-view/" + id : "/stands"} className="btn-edit mb-10">
              <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
            </Link>
            <ShowroomSize
              setSubmitIsActive={setSubmitIsActive}
              direction={direction}
              initShowroom={initShowroom}
              showroom={updatedShowroom}
              setDimension={setDimension}
              setDirection={setDirection}
              setCustomDimension={setCustomDimension}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              customError={customError}
              directionError={directionError}
              setLongueur={setLongueur}
              setProfondeur={setProfondeur}
              longueur={longueur}
              profondeur={profondeur}
              />
            <ShowroomShape
              shape={shape}
              setShape={setShape}
              shapeError={shapeError}
            />
            <ShowroomOptions
              showroom={updatedShowroom}
              setKit={setKit}
              setCountKit={setCountKit}
              setCarpet={setCarpet}
              setCustomCarpet={setCustomCarpet}
              setCarpetDesc={setCarpetDesc}
              setPower={setPower}
              setWater={setWater}
              setWaterType={setWaterType}
              setStorage={setStorage}
              setStorageSize={setStorageSize}
              setStandisteEmail={setStandisteEmail}
              setStandisteName={setStandisteName}
              setStandistePhone={setStandistePhone}
              customCarpetError={customCarpetError}
              setInternet={setInternet}
              kitError={kitError}
              waterError={waterError}
              storageError={storageError}
            />
            {updatedShowroom ? <h1 style={{ textAlign: 'center' }}> TOTAL : {calculPrice(updatedShowroom).total} € </h1> : null }
            <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
              <button
                className={`btn-save ${submitIsActive? "" : "disabled"}`}
                disabled={!submitIsActive}
                onClick={() => subitShowroom()}
                >
                  <Translate id="btn.save"/>
              </button>
            </div>
          </main>
        </>
      );
    }
  }

  return (
    <div className="page-container">
      {renderShowroomEdit()}
    </div>
  )
}
