import React, { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";

import { useGlobalContext } from '../../context/GlobalContext';
import { getDashbordAction, getExhibitionAction, putExhibitionAction } from '../../../actions/authActions';

import ProviderRow from '../Providers/Providers_Partials/ProviderRow';
import ShopRow from '../../Pages/Shops/Shops_Partials/ShopsRow';

import water from '../../../assets/images/icons/water-dashboard.svg';
import elec from '../../../assets/images/icons/elec-dashboard.svg';

import '../../../styles/Dashboard.scss'
import Loader from '../../Partials/Loader';
import SwitchField from '../../Partials/form_fields/SwitchField';

export default function Home(props) {
  props.setActivePage("home");

  const [ context , dispatch ] = useGlobalContext();
  const [ providers , setProviders ] = useState([]);
  const [ shops , setShops ] = useState([]);
  const { exhibition } = context.auth;

  const getDashbord = useCallback(() => {
    getDashbordAction(dispatch);
    getExhibitionAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDashbord();
    window.scrollTo(0, 0);
  },[getDashbord]);

  useEffect(() => {
    if (context.auth.dashboard && context.auth.dashboard.fournisseurs ) {
      setProviders(context.auth.dashboard.fournisseurs);
      setShops(context.auth.dashboard.magasins);
    }
  }, [context.auth.dashboard]);

  function renderDashbord() {

    return (
      <>
        <section className="dashboard-row" style={{ marginBottom: 20 }}>
          {/* BLUE CARD */}
          <div className="col-2-3">

            <div className="dashboard-card providers bold">

              <div className="dashboard-row">

                <div className="col-2-3 main-infos">

                  <div className="number-container">
                    <h2>fournisseurs INSCRITS</h2>
                    <h1 className="number-xl">{providers.nbFournisseursWithStand} / {providers.nbFournisseurs}</h1>
                  </div>

                  <div className="number-container">
                    <h2>Nombre de contacts</h2>
                    <h1 className="number">{providers.nbContactsFournisseurs} </h1>
                  </div>

                  {/* <div className="dashboard-row">

                    <div className="col-2 number-container">
                      <h2>stand réservés</h2>
                      <span className="number">{providers.surfaceStand} <span className="regular">m<sup>2</sup></span></span>
                    </div>

                    <div className="col-2 number-container">
                      <h2>stockage</h2>
                      <span className="number">{providers.surfaceStockage} <span className="regular">m<sup>2</sup></span></span>
                    </div>

                  </div> */}

                    <div className="dashboard-row mb-20">

                      <div className="number-container">
                        <h2>Mercredi</h2>
                        <span className="number">{providers.nbContactsMercredi ? providers.nbContactsMercredi : 0 }</span>
                      </div>

                      <div className="number-container">
                        <h2>Jeudi</h2>
                        <span className="number">{providers.nbContactsJeudi ? providers.nbContactsJeudi : 0 }</span>
                      </div>

                      <div className="number-container">
                        <h2>Cocktail</h2>
                        <span className="number">{providers.nbContactsCocktail ? providers.nbContactsCocktail : 0 }</span>
                      </div>
                    
                    <div>

                    </div>

                  </div>

                </div>
                {/* YELLOW CARD */}
                <div className="col-1-3 prestas">

                  <div className="number-container">
                    <h2>Prestations eau</h2>
                    <div className="number-icon">
                      <span className="number">{providers.nbEau}</span>
                      <img src={water} alt="icon-water"/>
                    </div>
                  </div>

                  <div className="number-container">
                    <h2>boitiers Electriques</h2>
                    <div className="number-icon">
                      <span className="number">{providers.nbElectrique}</span>
                      <img src={elec} alt="icon-elec"/>
                    </div>
                  </div>

                  <div className="number-container">
                    <h2>stand réservés</h2>
                    <span className="number">{providers.surfaceStand} <span className="regular">m<sup>2</sup></span></span>
                  </div>

                  <div className="number-container">
                  <h2>stockage</h2>
                      <span className="number">{providers.surfaceStockage} <span className="regular">m<sup>2</sup></span></span>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <div className="col-1-3">

            <div className="dashboard-card shops bold">

              <div className="col-1">
                <div style={{padding: 20 }}>
                  <h2>Magasins inscrits (hors outils métier)</h2>
                  <h1 className="number-xl">{shops.nbMagasinsPresent} / {shops.nbMagasins}</h1>
                  <h2 style={{ marginTop: 10 }}>Nombre de contacts</h2>
                  <h1 className="number">{shops.nbContactsMagasins}</h1>
                </div>
              </div>

              <div className="col-1">

                <div className="days-container">

                  <div className="day">
                    <h2>Mercredi</h2>
                    <span className="number">{shops.nbContactsMercredi}</span>
                  </div>

                  <div className="day">
                    <h2>Jeudi</h2>
                    <span className="number">{shops.nbContactsJeudi}</span>
                  </div>

                  <div className="day">
                    <h2>Cocktail</h2>
                    <span className="number">{shops.nbContactsCocktail ? shops.nbContactsCocktail : 0}</span>
                  </div>

                </div>

              </div>

            </div>
          </div>

        </section>

        <section className="row col-2">

          <div className="col-2">
            <div className="title">
              <h2>Derniers fournisseurs inscrits</h2>
            </div>
            {providers.fournisseurs.map((provider,i) => <ProviderRow key={i} provider={provider} dashboard={true}/>)}
          </div>

          <div className="col-2">
            <div className="title">
              <h2>Derniers magasins inscrits</h2>
            </div>
            {shops.magasins.map((shop,i) => <ShopRow key={i * 10 } dashboard={true} shop={shop}/>)}
          </div>

        </section>
      </>
    )
  }

  return (
    <div className="page-container">

      <header className="page-header">
        <h1><Translate id="nav.home"/></h1>
      </header>

      <main className="page-content">
        {exhibition?._id &&
          <div className="exhibition">
            <div className='toggle'>
              <SwitchField
                checked={exhibition?.loginActive ? true : false}
                handleChange={(val) => putExhibitionAction(dispatch, {
                  loginActive: val
                })}
              >
                <p>
                  Connexion magasin autorisé
                </p>
              </SwitchField>
            </div>
            <div className='toggle'>
              <SwitchField
                checked={exhibition?.offersActive ? true : false}
                handleChange={(val) => putExhibitionAction(dispatch, {
                  offersActive: val
                })}
              >
                <p>
                  Accès aux offres autorisé
                </p>
              </SwitchField>
            </div>
          </div>
        }
        {shops.magasins && providers.fournisseurs ?
          renderDashbord()
          :
          <div style={{ height: '80vh'}}>
            <Loader />
          </div>
        }
        
      </main>

    </div>
  );
};