import React from 'react';
import styles from './IconBtn.module.scss';
import invitIcon from './images/invit.svg';
import importIcon from './images/import.svg';
import exportIcon from './images/export.svg';
import downloadIcon from './images/download.svg';
import loaderIcon from './images/loader.svg';
import pictureIcon from './images/picture.svg';
import refreshIcon from './images/refresh.svg';

const icons = {
  invit: invitIcon,
  import: importIcon,
  export: exportIcon,
  download: downloadIcon,
  loader: loaderIcon,
  picture: pictureIcon,
  refresh: refreshIcon,
}

export default function IconBtn({ label, icon , isLoading , handleClick }) {
  return (
    <div className={styles.btn}>
      <button
        className={`${icon === "download" ? styles.download :''}`}
        onClick={() => handleClick()}
      >
        {isLoading ? <img className={styles.loading} src={icons.loader} alt={label}/> : <img src={icons[icon]} alt={label}/>}
      </button>
      <span className={styles.tooltip}>{label}</span>
    </div>
  )
}
