import React from 'react';

export default function SelectList(props) {
  
  function handleChangeList(value) {
    var array = props.activeList
    if (value === array.find(item => item === value)) {
      array = array.filter(item => item !== value);
      props.onChange(array);
    } else {
      props.onChange([...array , value]);
    }
  }
  return (
    <>
      <span className="bold" style={{ fontSize: 14 }}>{props.label}</span>
      <div className="list-container">
        {props.list.map((item ,i) => {
          let found = props.activeList.find(product => item === product);
          return (
            <div
              key={i}
              className={found ? "list-item selected btn" :"list-item btn"}
              onClick={() => handleChangeList(item)}
              >
              {item}
            </div>
          )
        })}
      </div>
    </>
  )
}
