import React , { useState , useEffect } from "react";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

import { useGlobalContext } from '../context/GlobalContext';

import '../../styles/Nav.scss';
// import LanguageToggle from "./LanguageToggle";
import logo from '../../assets/images/logo-leclerc.svg';
import bars from '../../assets/images/icons/bars.svg';
import close from '../../assets/images/icons/close.svg';
import user from '../../assets/images/icons/user.svg';
import userHover from '../../assets/images/icons/user_hover.svg';
import provider from '../../assets/images/icons/provider.svg';
import providerHover from '../../assets/images/icons/provider_hover.svg';
import home from '../../assets/images/icons/home.svg';
import homeHover from '../../assets/images/icons/home_hover.svg';
import shops from '../../assets/images/icons/shops.svg';
import shopsHover from '../../assets/images/icons/shops_hover.svg';
import market from '../../assets/images/icons/market.svg';
import marketHover from '../../assets/images/icons/market_hover.svg';
import anim from '../../assets/images/icons/anim.svg';
import animHover from '../../assets/images/icons/anim_hover.svg';
import assembly from '../../assets/images/icons/assembly.svg';
import assemblyHover from '../../assets/images/icons/assembly_hover.svg';
import offers from '../../assets/images/icons/offers.svg';
import offersHover from '../../assets/images/icons/offers_hover.svg';
import orders from '../../assets/images/icons/orders.svg';
import ordersHover from '../../assets/images/icons/orders_hover.svg';

import accueil from '../../assets/images/icons/accueil.svg';

import NavLink from '../Partials/NavLink';

export default function Nav(props) {

  const context = useGlobalContext()[0];

  const [ showNav , setShowNav ] = useState(false);

  const [ showReception ,  setShowReception ] = useState(false);

  useEffect(() => {
    if(isReceptionPage()) {
      setShowReception(true);
    } else {
      setShowReception(false);
    }
    // eslint-disable-next-line
  }, [props.activePage]);

  function isReceptionPage() {
    if(props.activePage === "reception-day1"|| props.activePage === "reception-day2"|| props.activePage === "reception-cocktail" || props.activePage === "reception-conference1" || props.activePage === "reception-conference2"  ) {
      return true;
    } else {
      return false;
    }
  }

  const navLinks = [
    {
      name: "home",
      icon: home,
      iconHover: homeHover,
      url: "/",
      label: <Translate id="nav.home"/>
    },
    {
      name: "providers",
      icon: provider,
      iconHover: providerHover,
      url: "/providers",
      label: <Translate id="nav.providers"/>
    },
    {
      name: "shops",
      icon: shops,
      iconHover: shopsHover,
      url: "/shops",
      label: <Translate id="nav.shops"/>
    },
    {
      name: "stands",
      icon: market,
      iconHover: marketHover,
      url: "/stands",
      label: <Translate id="nav.stands"/>
    },
    {
      name: "animations",
      icon: anim,
      iconHover: animHover,
      url: "/animations",
      label: <Translate id="nav.animations"/>
    },
    {
      name: "assemblies",
      icon: assembly,
      iconHover: assemblyHover,
      url: "/assemblies",
      label: <Translate id="nav.assembly"/>
    },
    {
      name: "offers",
      icon: offers,
      iconHover: offersHover,
      url: "/offers",
      label: <Translate id="nav.offers"/>
    },
    {
      name: "users",
      icon: user,
      iconHover: userHover,
      url: "/users",
      label: <Translate id="nav.users"/>
    },
    {
      name: "orders",
      icon: orders,
      iconHover: ordersHover,
      url: "/orders",
      label: <Translate id="nav.orders"/>
    },
  ];

  function renderProfile() {
    if (context.auth.user && context.auth.user.profile) {
      return (
        <>
          <p>
            <span>{context.auth.user.profile.firstName} {context.auth.user.profile.lastName}</span><br/>
            <span className="role">{context.auth.user.role}</span>
          </p>
          <button className="btn-logout" onClick={() => props.logout()}><Translate id="nav.logout"/></button>
        </>
      );
    };
  };

  function renderLinks() {
    if (context.auth.user.role && context.auth.user.role ==="Admin") {
      return navLinks.map((link, i ) => {
        return (
          <NavLink
            key={i}
            showNav={showNav}
            setShowNav={setShowNav}
            activePage={props.activePage}
            setShowReception={setShowReception}
            setActivePage={props.setActivePage}
            link={link} />
        );
      })
    }
  };

  function closeReceptionNav() {
    if(showReception) {
      setShowReception(false);
    }
  }

  function renderReceptionLink() {
    return (
      <li
        onClick={() => setShowReception(true)}
        onMouseOver={() => setShowReception(true)}
        onMouseOut={() => closeReceptionNav()}
        className={`reception ${showReception ? "isActive" : ""}`}>
        <div
          className="btn-nav reception"
          >
          <img src={accueil} alt="accueil"/>
          <span className="nav-label">Accueil</span>
        </div>

        <ul className={showReception || isReceptionPage() ? "isActive" : ""}>

          <li>
            <Link
              onClick={() => { props.setActivePage("reception-day1"); setShowNav(false)}}
              className={props.activePage === "reception-day1" ? "isActive": ""}
              to={"/reception/day1"}>
              <span className="bold">Mercredi 14 septembre</span>
            </Link>
          </li>

          <li>
            <Link
              onClick={() => {  props.setActivePage("reception-day2"); setShowNav(false)}}
              className={props.activePage === "reception-day2" ? "isActive": ""}
              to={"/reception/day2"}>
              <span className="bold" >Jeudi 15 septembre</span>
            </Link>
          </li>

          <li>
            <Link
              onClick={() => { props.setActivePage("reception-cocktail"); setShowNav(false)}}
              className={props.activePage === "reception-cocktail" ? "isActive": ""}
              to={"/reception/cocktail"}>
              <span className="bold">Cocktail</span>
            </Link>
          </li>

          <li>
            <Link
              onClick={() => {  props.setActivePage("reception-conference1"); setShowNav(false)}}
              className={props.activePage === "reception-conference1" ? "isActive": ""}
              to={"/reception/conference1"}>
              <span className="bold" >Conférence 1</span>
            </Link>
          </li>

          <li>
            <Link
              onClick={() => {  props.setActivePage("reception-conference2"); setShowNav(false)}}
              className={props.activePage === "reception-conference2" ? "isActive": ""}
              to={"/reception/conference2"}>
              <span className="bold" >Conférence 2</span>
            </Link>
          </li>

        </ul>
      </li>
    )
    
  };

  return (
    <>
    <nav className={`main-nav ${showNav? "mobile" : ""}`}>
      <div className="user-profile">
        {renderProfile()}
      </div>
      <ul className="links-list">
        {renderLinks()}
        {renderReceptionLink()}
      </ul>
      <div className="logo-container">
        <img src={logo} alt="logo-btlec"/>
        <span className="bold">BTlec Ouest</span>
      </div>
      {/* <LanguageToggle /> */}
    </nav>
    <button className="btn-nav-toggle" onClick={() => setShowNav(!showNav)}><img src={showNav? close : bars} alt="burger-menu"/></button>
    </>
  )
}