import React , { useState , useEffect , useCallback } from 'react';
import { useParams , Link } from  'react-router-dom';
// import * as FileSaver from 'file-saver';
import useDimension from '../../customHooks/useDimension';
// import { Translate } from "react-localize-redux";

import ReceptionRow from './ReceptionRow';
import Loader from "../../Partials/Loader";

import { useGlobalContext } from '../../context/GlobalContext';
import { getReceptionListAction } from '../../../actions/receptionActions';
import { resetUserAction } from '../../../actions/usersActions';
import { setEventTitle } from '../../../utils/utils';

import searchIcon from "../../../assets/images/icons/search.svg";
import qr from "../../../assets/images/icons/qr-icon.png";
import filterIcon from "../../../assets/images/icons/filters.svg";

export default function RecepetionList(props) {
  const dimension = useDimension()
  const param = useParams();

  const { setActivePage } = props;

  const [ context, dispatch ] = useGlobalContext();
  const [ search , setSearch ] = useState("");
  const [ filtredList , setFiltredList ] = useState();

  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByPresence , setSortByPresence ]= useState(false);
  const [ sortByCompanies , setSortByCompanies ] = useState(false);

  useEffect(() => {
    setActivePage("reception-" + param.event);
  }, [param , setActivePage]);

  const getReceptionList = useCallback(() => {
    setFiltredList();
    resetUserAction(dispatch);
    getReceptionListAction(dispatch , param.event);
    dispatch({
      type : 'MESSAGE_RECEPTION',
      payload : ""
    });
    dispatch({
      type : 'GET_RECEPTION_USER',
      payload : {}
    });
  }, [dispatch, param.event]);

  useEffect(() => {
    getReceptionList();
    window.scrollTo(0, 0);
  }, [getReceptionList]);

  useEffect(() => {

    if (filtredList && context.users.user && context.users.user.isHere) {
      var list = filtredList
      list[list.findIndex(item => item._id === context.users.user._id )].isHere = context.users.user.isHere;
      setFiltredList(list);
      resetUserAction(dispatch);
    }
    // eslint-disable-next-line
  }, [context.users.user]);

  useEffect(() => {
    setFiltredList(context.reception.receptionList);
  }, [context.reception.receptionList])

  useEffect(() => {
    setFiltredList(context.reception.receptionList.filter((user) => {
      if (user.profile && user.profile.lastName && user.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
        return user;
      } else if (user.profile && user.profile.firstName && user.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
        return user;
      } else if (user.company && user.company[0] && user.company[0].name && user.company[0].name.toLowerCase().includes(search.toLowerCase())) {
        return user;
      } else if (user.email && user.email.toLowerCase().includes(search.toLowerCase())) {
        return user;
      }
      return null;
    }));
  }, [search , context.reception.receptionList]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a , b ) => a.profile.lastName > b.profile.lastName ? 1 : -1 ));
      } else {
        setFiltredList(filtredList.sort((a , b ) => a.profile.lastName > b.profile.lastName ? 1 : -1 ).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByCompanies) {
        setFiltredList(filtredList.sort((a , b ) => {
          if (a.company && b.company && a.company[0] && b.company[0]) {
            return a.company[0].name > b.company[0].name ? 1 : -1
          } else {
            return 0
          }
        }));
      } else {
        setFiltredList(filtredList.sort((a , b )  => {
          if (a.company && b.company && a.company[0] && b.company[0]) {
            return a.company[0].name > b.company[0].name ? 1 : -1
          } else {
            return -1
          }
        }).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCompanies]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPresence) {
        setFiltredList(filtredList.sort((a , b ) => a.isHere && a.isHere.find(item => item === param.event) ? 1 : -1 ));
      } else {
        setFiltredList(filtredList.sort((a , b ) => a.isHere && a.isHere.find(item => item === param.event) ? 1 : -1 ).reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByPresence]);

  function renderPresenceCounters() {
    var counters = {
      providers: 0,
      providersHere: 0,
      shops: 0,
      shopsHere: 0,
    }
    context.reception.receptionList.forEach(user => {
      if (user.company && user.company[0] && user.company[0].type === "fournisseur") {
        counters.providers += 1
        if (user.isHere.find(item => item === param.event)) {
          counters.providersHere += 1
        }
      }
      if (user.company && user.company[0] && user.company[0].type === "magasin") {
        counters.shops += 1
        if (user.isHere.find(item => item === param.event)) {
          counters.shopsHere += 1
        }
      }

    });
    return (
      <div className="counters">
        <div className="col providers bold">
          FOURNISSEURS INSCRITS<br/>
          <span>{counters.providersHere} / {counters.providers}</span>
        </div>
        <div className="col shops bold">
          MAGASINS INSCRITS<br/>
          <span>{counters.shopsHere} / {counters.shops}</span>
        </div>
      </div>
    )
  }

  function renderUserList() {
    if (filtredList && filtredList.length > 0) {
      return filtredList.map((user, i) => <ReceptionRow key={user._id} user={user} event={param.event} />);
    } else if (search && filtredList.length === 0) {
      return <span className="text-error">Aucun résultat ne correspond à votre recherche</span>;
    } else {
      return (
        <div style={{ height: '70vh'}}>
          <Loader />
        </div>
      )
    }
  }


  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des visiteurs {dimension.width < 768 ? <br/> : null } <span className="text-primary">{setEventTitle(param.event)}</span></h1>
      </header>
      <main className="page-content">
        <div className="row">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
        </div>

        {renderPresenceCounters()}

        <div className="filters"
          style={{ width: '71%'}}
          >

          <div
            className="filter"
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByCompanies(!sortByCompanies)}
            style={{ transform: 'translateX(-10%)'}}>
            <span>Société</span>
            <img src={filterIcon} className={sortByCompanies? "rotate180": ""} alt="filter"/>
          </div>


          <div
            className="filter"
            onClick={() => setSortByPresence(!sortByPresence)}>
            <span>Présence</span>
            <img src={filterIcon} className={sortByPresence? "rotate180": ""} alt="filter"/>
          </div>

        </div>

        {renderUserList()}
      </main>
      {filtredList && filtredList.length > 0 &&
        <div className="btn-qr-container">
          <Link
            className="btn-scan slide-bottom"
            to={'/scan/'+ param.event}>
            <img src={qr} alt="qr-code"/>  
          </Link>
        </div>
      }
    </div>
  )
}