import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProvidersListAction } from '../../../actions/providersActions';
import { getShopsListAction } from '../../../actions/shopsActions';

import selectStyle from '../../Partials/selectStyle';
import filterIcon from "../../../assets/images/icons/filters.svg";

import ProductsRow from "./Orders_Partials/ProductsRow";

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import { deleteOrderAction, getOrderAction, getProductsAction , postOrderAction, putOrderAction } from '../../../actions/ordersActions';

export default function EditOrder(props) {
  props.setActivePage("orders");

  var history = useHistory();
  const { id } = useParams();
  const [ context , dispatch ] = useGlobalContext();
  const {  order } = context.orders;

  const [ providersOptions , setProvidersOptions ] = useState();
  const [ selectedProvider , setSelectedProvider ] = useState();
  const [ shopsOptions , setShopsOptions ] = useState();
  const [ filtredList , setFiltredList ] = useState();

  const [ form , setForm ] = useState({
    user: context.auth.user._id,
    company: null,
    domain:  null,
    products: []
  });

  const domainsOptions = shopsOptions?.find(
    (s) => s.value._id === form.company)?.value?.domains?.map(
      (item) => ({ label: item.label, value: item.code }));

  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);

  const getProvidersList = useCallback(() => {
    getProvidersListAction(dispatch);
    getProductsAction(dispatch);
    getShopsListAction(dispatch);
    if (id) {
      getOrderAction(dispatch, id)
    }
  }, [dispatch, id]);

  useEffect(() => {
      getProvidersList();
  }, [getProvidersList]);

  useEffect(() => {

    if (context.providers.providersList) {
      setProvidersOptions(context.providers.providersList.map(item => ({ label: item.name , value: item._id })));
    }

    if (context.shops.shopsList) {
      setShopsOptions(context.shops.shopsList.map(item => ({ label: item.name , value: item })));
    }

    if (context.orders.productsList) {
      setFiltredList(context.orders.productsList);
    }

  }, [context.providers.providersList , context.shops.shopsList , context.orders.productsList]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  useEffect(() => {
    if (context.orders.message === "order saved") {
      successMessage("La commande a été créé avec succès!");
      history.push("/orders")
    }
    // eslint-disable-next-line
  }, [context.orders.message])

  useEffect(() => {
    if (order?._id) {
      const orderForm = {
        ...order,
        company: order?.company?._id,
        products: order?.products.map(item => item._id), 
      }
      if (order?.products?.length > 0 ) {
        const foundProvider = providersOptions.find((p) => p.value === order.products[0].company?._id);
        console.log(foundProvider)
        setSelectedProvider(foundProvider);
      }
      setForm(orderForm);
    }
    // eslint-disable-next-line
  }, [order, providersOptions])

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);


  function successMessage(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_ORDERS',
      payload : {}
    });
  }

  function handleChangeProducts(id , qty ) {
    var productsList = [...form.products.filter(item => item !== id)];
    for (let i = 0; i < qty; i++) {
      productsList.push(id);
    }
    setForm({...form, products: productsList })
  }

  async function submit() { 
    if(order?._id) {
      await putOrderAction(dispatch, order._id, form);
      return successMessage("La commande a été modifée");
    }
    return postOrderAction(dispatch , form);
  }

  async function deleteOrder(id) {
    await deleteOrderAction(dispatch, id);
    history.push("/orders");
  }

  function renderProductsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.filter(item => item.company._id === selectedProvider.value).map(
          ( product , i) => <ProductsRow key={product?._id} handleChangeProducts={handleChangeProducts} product={product} defaultValue={form.products.filter(item => item === product?._id)?.length}/>);
      } else if (filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  return (
    <div className="page-container">
      <header className="page-header">

        <div className="icon">
          <img src={providerIcon} alt="icon-provider"/>
        </div>

        <h1>
          {order?._id ? "Modification d'une commande": "Création d'une commande"} {selectedProvider &&  `pour ${selectedProvider.label}`}
        </h1>

        </header>

        <main className="page-content">
          <div className="row space-between" style={{ marginBottom: 20 }}>
            <Link to={"/orders"}>
              <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
            </Link>
            {order?._id
              && <button
              className="btn-delete"
              onClick={() => deleteOrder(order?._id)}
              style={{ marginBottom: 10 }}>
                Supprimer la commande
              </button>
            }
          </div>


          <div className="row space-between">
            <div className="col-3">
              <Select
                options={shopsOptions}
                onChange={(val) => setForm({ ...form, company: val.value._id, products: []})}
                value={shopsOptions?.find((d) => d.value?._id === form?.company)}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un magasin"
                />
            </div>
            <div className="col-3">
              {domainsOptions?.length > 0 && <Select
                value={domainsOptions?.find((d) => d.value === form?.domain)}
                options={domainsOptions}
                onChange={(val) => setForm({ ...form, domain: val._id })}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un lieu de vente"
                />
              }
            </div>
            <div className="col-3">
              {form?.company && <Select
                options={providersOptions}
                onChange={(val) => {
                  setSelectedProvider(val);
                  setForm({ ...form, products: []})
                }}
                value={providersOptions?.find((d) => d.value === selectedProvider?.value)}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un fournisseur"
                />
              }
            </div>

            {form.products?.length > 0 &&
              <button
                onClick={() => submit()}
                className="btn-save"
                >Valider
              </button>
            }

          </div>

          {selectedProvider && selectedProvider.value &&

            <div className="filters">

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Nom du fournisseur</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Segment</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByRef(!sortByRef)}>
                <span>Référence</span>
                <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByGencode(!sortByGencode)}>
                <span>Gencod</span>
                <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"

                onClick={() => setSortByPack(!sortByPack)}>
                <span>Colistage</span>
                <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                style={{ minWidth: '250px'}}
                onClick={() => setSortByTotal(!sortByTotal)}>
                <span>Prix net salon HT/unité</span>
                <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
              </div>

              <div className="filter">
                <span>Nbre de colis</span>
              </div>


            </div>
          }

        {selectedProvider &&  selectedProvider.value && renderProductsList()}

        </main>
    </div>
  );
  
};