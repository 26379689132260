import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { useGlobalContext } from '../../../context/GlobalContext';
import arrow from '../../../../assets/images/icons/arrow.svg';
import gift from '../../../../assets/images/icons/gift.svg';
import fist from '../../../../assets/images/icons/fist.svg';
import promo from '../../../../assets/images/icons/promo.svg';
import styles from './EditOffer.module.scss';
import { ERROR_OFFERS, MESSAGE_OFFERS } from '../../../../actions/types';
import { availabilityOptions, offersStatusOptions } from '../../../../constants/constants';
import { deleteOfferAction, getOfferAction, postProductAction, putProductAction } from '../../../../actions/offersActions';
import Input from '../../../Partials/form_fields/Input';
import InputFile from '../../../Partials/form_fields/InputFile/InputFile';
import Textarea from '../../../Partials/form_fields/Textarea';
import { getProviderAction, getProvidersListAction } from '../../../../actions/providersActions';
import StyledSelect from '../../../Partials/form_fields/StyledSelect/StyledSelect';

const required = "Ce champ est requis";

export default function EditOffer({ setActivePage  }) {
  const [ context , dispatch ] = useGlobalContext();
  const { id } = useParams();
  const history = useHistory();

  const productsOptions = context.providers?.provider?.company?.products?.map((p) => ({ value: p, label: p }));
  const { offer, message, error }  = context.offers;
  const providersOptions = context.providers?.providersList?.map((p) => ({ value: p._id, label: p.name})) || [];

  const containerRef = useRef();
  const [ adventage, setAdventage] = useState({ type: null , value: null });
  const [form, setForm ] = useState({
    status: 'pending',
  });
  const discountValue = form.price && form.pricePA ? 100 - (form.price * 100) / form.pricePA : 0;
  const discount = parseFloat(discountValue).toFixed(2);
  const [errors, setErrors] = useState({})

  const getData = useCallback(() => {
    setActivePage("offers");
    getProvidersListAction(dispatch);
    if (id) {
      getOfferAction(dispatch ,id);
    }
  }, [dispatch, setActivePage, id]);

  async function deleteOffer(id) {
    await deleteOfferAction(dispatch, id);
    history.push("/offers");
  }

  function handleChangeForm(obj) {
    const data = {...form, ...obj}
    if (data.price && data?.price % 1 !== 0) {
      data.price = parseFloat(data.price).toFixed(2);
    }
    if (data?.pricePA && data?.pricePA % 1 !== 0) {
      data.pricePA = parseFloat(data.pricePA).toFixed(2);
    }
    let value = data?.price && data?.pricePA ? 100 - (data.price * 100) / data.pricePA : 0;
    value = parseFloat(value).toFixed(2);
    if (value < 0) {
      value = 0;
    }
    if (adventage.type === 'discount') {
      setAdventage({
        ...adventage,
        value,
      })
    }
    setForm(data);
  } 

  function submit(obj = {}) {
    const data = {...form, ...obj };

    const errorsData = {}  
    if (!data.ref) {
      errorsData.ref = required;
    }
    if (data.ref?.length > 19) {
      errorsData.ref = "la référence a plus de 19 caractères";
    }
    // if (!data.brand) {
    //   errorsData.brand = required;
    // }
    if (data?.brand?.length > 30) {
      errorsData.brand = "la marque a plus de 30 caractères";
    }
    if (!data.gencod) {
      errorsData.gencod = required;
    }
    if (data?.gencod?.length !== 13) {
      errorsData.gencod = "le gencod n'a pas 13 caractères";
    }
    if (!data.description) {
      errorsData.description = required;
    }
    if (data.description.length > 100) {
      errorsData.description = "la description a plus de 100 caractères";
    }
    if (!data.packing) {
      errorsData.packing = required;
    }
    if (!data.company) {
      errorsData.company = required;
    }
    if (!data.price) {
      errorsData.price = required;
    }
    if (!data.image) {
      errorsData.image =  "l'image est requise";
    }
    if (!data.product) {
      errorsData.product = required;
    }
    if (!data.deee) {
      errorsData.deee = required;
    }
    if (!data.tax && data.tax !== 0) {
      errorsData.tax = required;
    }
    if (!data.brand) {
      errorsData.brand = required;
    }
    if (!data.availability) {
      errorsData.availability = required;
    }
    if (data?.availability === 'other' && !data.availabilityComment) {
      errorsData.availabilityComment = required;
    }
    if (!adventage.value) {
      errorsData.adventages = required;
    }
    setErrors(errorsData);
    if (Object.keys(errorsData).length > 0) {
      return null;
    }
    data.discount = null;
    data.punch = null;
    data.gift = null;


    if (adventage.type && adventage.value) {
      data[adventage.type] = adventage.value;
    }
    if (form?.company?._id) {
      data.company = form?.company?._id;
    }
    if (form?.image?._id) {
      data.image = form?.image?._id;
    }
    if (form?.file?._id) {
      data.file = form?.file?._id;
    }
    if(!offer?._id) {
      return postProductAction(dispatch, {...data });
    }
    putProductAction(dispatch, offer?._id,  data );
    return store.addNotification({
      message: "L'offre a été modifiée",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (offer?._id) {
      if (offer.discount) {
        setAdventage({ type: "discount", value: parseFloat(offer.discount) });
      }
      if (offer.punch) {
        setAdventage({ type: "punch", value: offer.punch });
      }
      if (offer.gift) {
        setAdventage({ type: "gift", value: offer.gift });
      }
      setForm(offer);
    }
  },[offer]);

  useEffect(() => {
    if (form.company) {
      getProviderAction(dispatch, form?.company?._id || form.company);
    }
  }, [dispatch, form, form.company])

  useEffect(() => {
    if (typeof message === 'string') {
      store.addNotification({
        message,
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      dispatch({
        type : MESSAGE_OFFERS,
        payload : null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[message]);

  useEffect(() => {
    if (error?.data?.message === 'This product is used in some Orders') {
      store.addNotification({
        message: "Ce produit est utilisé dans certaines commandes",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      });
      dispatch({
        type : ERROR_OFFERS,
        payload : null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[error?.data?.message]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.back}>
        {offer?._id ?
          <Link to={`/offer-view/${offer?._id }`}>
           <img src={arrow} alt="back"/> <p>Retour</p>
          </Link>
        :
          <Link to={`/offers`}>
           <img src={arrow} alt="back"/> <p>Retour</p>
          </Link>
        }
      </div>
      <header className={styles.header}>
        <h1>{offer?.ref || 'Nouveau produit' }</h1>
        <div className={styles.actions}>
          {offer?._id
              && <button
                className={styles.delete}
                onClick={() => deleteOffer(offer?._id)}
             >
                Supprimer le produit
              </button>
            }
          <button
            type="button"
            className={styles.submit}
            onClick={() => submit()}
          >
            Enregistrer
          </button>
        </div>
      </header>

      <main className={styles.content}>
        <div className={styles['col-2']}>
          <div className={styles.block}>
            <h2>Info</h2>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <StyledSelect
                  className="input secondary"
                  label="Status de l'offre"
                  value={offersStatusOptions?.find((d) => d.value === form?.status)}
                  options={offersStatusOptions}
                  placeholder="Sélectionner"
                  onChange={(opt) => handleChangeForm({ status: opt.value })}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="Référence du produit"
                  value={form?.ref || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.ref}
                  loading={false}
                  onChange={(val) => handleChangeForm({ ref : val?.toUpperCase() })}
                />
              </div>
              {productsOptions?.length > 0 &&
                <div className={styles['col-2']}>
                  <StyledSelect
                    className="input secondary"
                    label="Famille de produit"
                    value={productsOptions?.find((d) => d.value === form?.product)}
                    options={productsOptions}
                    error={errors?.product}
                    placeholder="Sélectionner"
                    onChange={(opt) => handleChangeForm({ product: opt.value })}
                  />
                </div>
              }
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <StyledSelect
                  className="input secondary"
                  label="Fournisseur"
                  value={providersOptions?.find((d) => d.value === form?.company?._id || d.value === form?.company)}
                  options={providersOptions}
                  error={errors?.company}
                  placeholder="Sélectionner"
                  onChange={(opt) => handleChangeForm({ company: opt.value })}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="Marque"
                  value={form?.brand || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.brand}
                  loading={false}
                  onChange={(val) => handleChangeForm({ brand : val?.toUpperCase() })}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label="Gencod"
                  value={form?.gencod || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.gencod}
                  loading={false}
                  onChange={(val) => handleChangeForm({ gencod : val })}
                />
              </div>
            </div>
            {<div className={styles.row}>
              <div className={styles['col-1']} style={{ marginBottom: 20 }}>
                <InputFile
                  file={form?.image || ''}
                  id="cover"
                  label="Télécharger l'image du produit"
                  postFile={!offer?._id ? (image) => submit({ ...form, image }) : null}
                  onChange={(val) => putProductAction(dispatch, offer?._id, { image: val })}
              />
                <span className={`text-error ${styles.error}`}>{errors?.image ? errors.image : ""}</span>
              </div>
            </div>
            }
            {<div className={styles.row}>
              <div className={styles['col-1']} style={{ marginBottom: 20 }}>
                <InputFile
                  file={form?.file || ''}
                  id="file"
                  label="Télécharger le fichier du produit"
                  postFile={!offer?._id ? (file) => submit({ ...form, file }) : null}
                  onChange={(val) => putProductAction(dispatch, offer?._id, { file: val })}
              />
                <span className={`text-error ${styles.error}`}>{errors?.file ? errors.file : ""}</span>
              </div>
            </div>
            }
            <div className={styles.row}>
              <div className={styles['col-1']}>
                <Textarea
                  label="Designation du produit"
                  value={form?.description || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.description}
                  loading={false}
                  onChange={(val) => handleChangeForm({ description : val})}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-1']}>
                <Input
                  label="Typo"
                  value={form?.type || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.type}
                  loading={false}
                  onChange={(val) => handleChangeForm({ type : val})}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="Code article"
                  value={form?.code || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.code}
                  loading={false}
                  onChange={(val) => handleChangeForm({ code : val })}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label="Numéro de dossier"
                  value={form?.fileNumber || ''}
                  placeholder={""}
                  type={"text"}
                  className="input secondary"
                  error={errors?.fileNumber}
                  loading={false}
                  onChange={(val) => handleChangeForm({ fileNumber : val })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles['col-2']}>
          <div className={styles.block}>
            <h2>Colisage</h2>
            <div className={styles.row}>
              <div className={styles.toggles}>
                <button
                  type="button"
                  onClick={() => handleChangeForm({ packing: '' })}
                  className={form?.packing !== 'box' ? styles.active : ''}
                >
                  Colis
                </button>
                <button
                  type="button"
                  onClick={() => handleChangeForm({ packing: "box"})}
                  className={form?.packing === 'box' ? styles.active : ''}
                >
                  Box
                </button>
              </div>
            </div>
            <div className={styles.row}>
              {form.packing === 'box' &&
                <div className={styles['col-1']}>
                  <Textarea
                    label="Composition du box"
                    value={form?.box || ''}
                    placeholder={""}
                    type={"text"}
                    className="input secondary"
                    loading={false}
                    onChange={(val) => handleChangeForm({ box : val})}
                  />
                </div>
              }
              {form.packing !== 'box' &&
                <div className={styles['col-2']}>
                  <Input
                    label="Nbre d'UVC par colis"
                    value={form?.packing || ''}
                    placeholder={""}
                    type={"text"}
                    error={errors?.packing}
                    className="input secondary"
                    loading={false}
                    onChange={(val) => handleChangeForm({ packing : val})}
                  />
                </div>
              }
            </div>
          </div>
          <div className={styles.block}>
            <h2>Tarif</h2>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="Prix net salon"
                  value={form?.price}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  loading={false}
                  step="0.01"
                  error={errors?.price}
                  onChange={(val) => handleChangeForm({ price: parseFloat(val) })}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label="PA permanent (si permanent)"
                  value={form?.pricePA}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  step="0.01"
                  error={errors?.pricePA}
                  loading={false}
                  onChange={(val) => handleChangeForm({ pricePA : parseFloat(val)  })}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="PA BRUT"
                  value={form?.pricePABrut}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  step="0.01"
                  error={errors?.pricePABrut}
                  loading={false}
                  onChange={(val) => handleChangeForm({ pricePABrut : parseFloat(val)  })}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label="TVA"
                  value={form?.tva}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  step="0.01"
                  error={errors?.tva}
                  loading={false}
                  onChange={(val) => handleChangeForm({ tva : parseFloat(val)  })}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <Input
                  label="DEEE"
                  value={form?.deee}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  loading={false}
                  step="0.01"
                  error={errors?.deee}
                  onChange={(val) => handleChangeForm({ deee: parseFloat(val) })}
                />
              </div>
              <div className={styles['col-2']}>
                <Input
                  label="Autres taxes"
                  value={form?.tax}
                  placeholder={""}
                  type={"number"}
                  className="input secondary"
                  error={errors?.tax}
                  loading={false}
                  onChange={(val) => handleChangeForm({ tax : parseFloat(val)  })}
                />
              </div>
            </div>
            <div className={styles.adventages}>
              <label className='bold'>Avantage salon</label>
              <div className={styles.list}>
                <div className={styles.item}
                  onClick={() => setAdventage(adventage.type !== "gift" ? { type: "gift" , value: "" } : { type: null , value: null })}
                > 
                  <div className={`${styles.icon} ${adventage.type === "gift" &&  styles.active}`}> 
                    <img src={gift} alt="gift" />
                  </div>
                  <p>Cadeau</p>
                </div>
                <div className={styles.item}
                  onClick={() => setAdventage(adventage.type !== "punch" ? { type: "punch" , value: "" } : { type: null , value: null })}
                > 
                  <div className={`${styles.icon} ${adventage.type === "punch" && styles.active}`}> 
                    <img src={fist} alt="gift" />
                  </div>
                  <p>Coup de poing</p>
                </div>
                <div className={styles.item}
                  onClick={() => setAdventage(adventage.type !== "discount" ? { type: "discount" , value: discount } : { type: null , value: null })}
                > 
                  <div className={`${styles.icon} ${adventage.type === "discount"  && styles.active}`}> 
                    <img src={promo} alt="gift" />
                  </div>
                  <p>% remise</p>
                </div>
              </div>
              {errors.adventages && <p className={styles.error}>{errors.adventages}</p>}
            </div>
              <div className={styles.row}>
                {adventage.type === "gift" &&
                  <div className={styles['col-2']}>
                    <Input
                      label="Cadeau"
                      value={adventage.value}
                      placeholder={""}
                      type={"text"}
                      className="input secondary"
                      loading={false}
                      onChange={(val) => setAdventage({ type: "gift", value: val })}
                    />
                  </div>
                }
                {adventage.type === "punch" &&
                  <div className={styles['col-2']}>
                    <Input
                      label="Coup de poing"
                      value={adventage.value}
                      placeholder={""}
                      type={"text"}
                      className="input secondary"
                      loading={false}
                      onChange={(val) => setAdventage({ type: "punch", value: val })}
                    />
                  </div>
                }
                {adventage.type === "discount" &&
                  <div className={styles['col-2']}>
                    <Input
                      label="Promotion"
                      value={adventage.value ? parseFloat(adventage.value) : ''}
                      placeholder={""}
                      type={"number"}
                      className="input secondary"
                      loading={false}
                      step="0.01"
                      onChange={(val) => setAdventage({ type: "discount", value: val > 0 ? parseFloat(val): val })}
                    />
                  </div>
                }
              </div>
          </div>
          <div className={styles.block}>
            <h2>Disponibilité</h2>
            <div className={styles.row}>
              <div className={styles['col-2']}>
                <StyledSelect
                  className="input secondary"
                  label="Disponibilité produit"
                  value={availabilityOptions?.find((d) => d.value === form?.availability)}
                  options={availabilityOptions}
                  placeholder="Sélectionner"
                  onChange={(opt) => handleChangeForm({ availability: opt.value })}
                />
              </div>
            </div>
            {form?.availability === "other" &&
              <div className={styles.row}>
                <div className={styles['col-1']}>
                  <Input
                    label="Délai de livraison"
                    value={form?.availabilityComment || ''}
                    placeholder={""}
                    type={"text"}
                    className="input secondary"
                    error={errors?.availabilityComment}
                    loading={false}
                    onChange={(val) => handleChangeForm({ availabilityComment : val})}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </main>

    </div>
  )
}
