import React, { useEffect, useState } from 'react'
import Select from 'react-select';

import '../../../../styles/showroomOptions.scss';

import selectStyle from '../../../Partials/selectStyle';
import SwitchField from '../../../Partials/form_fields/SwitchField';
import SelectItems from '../../../Partials/form_fields/SelectItems';
import Input from '../../../Partials/form_fields/Input';
import TooltipInfo from '../../../Partials/TooltipInfo';

import { initPrices } from '../../../../utils/utils';

import PdfEvier from '../../../../assets/doc/evier.pdf';

const textStorage = "L'accès à la zone de stockage ne sera pas autorisé pendant le salon\nFermeture de la zone de stockage le mardi 2 juin à 19h (après la journée de montage fournisseurs)\nOuverture de la zone de stockage le jeudi 4 juin à 18h (après fermeture du salon aux magasins).\nPour toutes demandes pendant le salon, merci de se rendre au commissariat général."

export default function ListOptions(props){

  const {
    showroom,
    setCarpet,
    setCustomCarpet,
    setCarpetDesc,
    setKit,
    setCountKit,
    setPower,
    setWater,
    setWaterType,
    setStorage,
    setStorageSize,
    setStandisteName,
    setStandisteEmail,
    setInternet,
    setStandistePhone,
    kitError,
    customCarpetError,
    waterError,
    storageError
  } = props; 

  const [ optionsKit, setOptionsKit ] = useState([]);
  const [ selectedKit , setSelectedKit ] = useState(null);

  useEffect(() => {
    let maxKit = 0;
    if(showroom && showroom.dimension){
      maxKit = Math.ceil(showroom.dimension/6)
    };
    let optionsKit = new Array(maxKit);

    if(maxKit){
      optionsKit = optionsKit.fill(null).map((d,i)=>{
        let k = i;
        k++;
        let kit = k+" kit";
        kit = k>1 ? kit+"s" : kit;
        return { label : kit, value : k};
      })
      setOptionsKit(optionsKit);
      if (showroom.option.countKit) {
        setSelectedKit(optionsKit.find(item => item.value === showroom.option.countKit))
      };
    }
  }, [showroom]);

  function handleChange(val,field){

    if(field === "carpet") {
      setCarpet(val);
      setCustomCarpet(!val);
      if(val) {
        setCarpetDesc(null);
      };
    };

    if(field === "customCarpet") {
      setCustomCarpet(val);
      if(!val) {
        setCarpet(true);
        setCarpetDesc(null);
      };
    };
    console.log(field, val)
    if (field === "internet") {
      setInternet(val);
    }
    if(field === "kit") {
      setKit(val);
      if(!val) {
        setCountKit(null);
        setSelectedKit(null);
      };
    };

    if(field === "power") {
      setPower(val);
    };

    if(field === "water") {
      setWater(val);
      if(!val) {
        setWaterType(null);
      };

    };

    if(field === "waterType") {
      setWaterType(val);
    };

    if(field === "storage") {
      setStorage(val);
      if(!val) {
        setStorageSize(null);
      };
    };

    if(field === "storageSize") {
      setStorageSize(parseFloat(val));
    };

    if(field === "standisteName") {
      setStandisteName(val);
    };

    if(field === "standisteEmail") {
      setStandisteEmail(val);
    };

    if(field === "standistePhone") {
      setStandistePhone(val);
    };

  }

  function renderOptionsList() {
    if(showroom) {
      const option = showroom.option ;
      return (
        <div className="list-options card">
          <section>
            <div className="container-switch">
              <SwitchField
                checked={option.carpet}
                handleChange={(val) => handleChange(val,"carpet")}
              >
                <p>
                  Avez-vous besoin de moquette aux couleurs du salon ? <span className="sub">(compris dans l’offre)</span><br/>
                  <span className="sub">(Bleu pour les fournisseurs « multimédia » et Orange pour les fournisseurs « Maison »)</span>
                </p>
              </SwitchField>
              {option.carpet ?
                null :
                <SwitchField
                  checked={option.customCarpet}
                  handleChange={(val) => handleChange(val,"customCarpet")}
                  conditional={option.customCarpet ?
                    <>
                      <textarea
                        style={{ marginTop : 5 , fontSize: 14 , padding: 5 , fontFamily: "Montserrat-Regular"}}
                        rows="5" cols="63"
                        value={option.carpetDesc ? option.carpetDesc : ""}
                        onChange={(e) => setCarpetDesc(e.target.value,"carpetDesc")}
                      />
                      <br/>
                      <span className="text-error">{customCarpetError}</span>
                    </>
                    :
                    null
                  }
                >
                  <p>
                    Apportez vous un sol personnalisé ?
                  </p>
                </SwitchField>
              }
              <SwitchField
                checked={option.kit}
                handleChange={(val) => handleChange(val,"kit")}
                conditional={option.kit ?
                  <>
                  {showroom.dimension ?
                    <>
                      <Select
                        value={selectedKit}
                        onChange={(val) => setCountKit(val.value)}
                        options={optionsKit}
                        isSearchable={false}
                        styles={selectStyle}
                      />
                      <br/><span className="text-error">{kitError}</span>
                    </> 
                    :
                    <p className="warn">
                      Vous devez indiquer la dimension
                      de l'espace d'exposition à l'étape précédente
                      pour pouvoir selectionner un nombre de kit
                  </p>
                  }
                  </>
                  : null
                }
              >
                <p>
                  Souhaitez vous disposer du mobilier « kit de base » pour 6m² ? <span className="sub">(compris dans l’offre)</span><br/>
                  <span className="sub">(1 table et 2 chaises) </span>
                </p>
              </SwitchField>
              <SwitchField
                checked={option.power}
                handleChange={(val) => handleChange(val,"power")}
              >
                <p>
                  Avez-vous besoin d'une puissance électrique de 20kVa ? <span className="sub">(supplément {initPrices.power}€)</span><br/>
                  <span className="sub">(Puissance mise à disposition : 6 kVa)</span>
                </p>
              </SwitchField>
              <SwitchField
                checked={option.water}
                handleChange={(val) => handleChange(val,"water")}
                conditional={ option.water ?
                  <>
                    <div className="options-water">
                      <SelectItems
                        selectedItems={option.waterType}
                        uniq={true}
                        items={["alimentation eau seul", "alimentation eau + évier"]}
                        handleChange={(val) => handleChange(val,"waterType")}
                      />
                      { option.waterType === "alimentation eau + évier" ?
                        <a href={PdfEvier} target="_blank" rel="noopener noreferrer" download="evier.pdf" >(détail évier)</a> : null
                      }
                    </div>
                    <br/><span className="text-error">{waterError}</span>
                  </>
                  : null
                }
              >
                <p>
                  Avez-vous besoin d’une alimentation en eau ? <span className="sub">(supplément {initPrices.water}€)</span>
                </p>
              </SwitchField>
              <SwitchField
                checked={option.storage}
                handleChange={(val) => handleChange(val,"storage")}
                conditional={option.storage ?
                  <>
                  <div className="row" style={{ marginBottom: 0 }}>
                    <div className="col-3" style={{ marginBottom: 0}} >
                      <Input
                        className={"input"}
                        style={{ maxWidth: 50 }}
                        value={option.storageSize ? option.storageSize : 0 }
                        title={"Espace de stockage"}
                        placeholder={""}
                        type={"number"}
                        onChange={(val) => handleChange(val,"storageSize")}
                      />
                      <span className="text-error">{storageError}</span>
                    </div>
                  </div>
                  <p>
                    <span className="sub">
                      Nous dégageons toute responsabilités en cas de vol et/ou détérioration des produits et/ou matériels stockés dans cet espace. Hors période d'installation et de démontage l'espace ne sera pas accessible (fermé sous clés).
                    </span>
                  </p>
                  {option.storageSize !== "" ?
                    <p><span className="sub">Supplément : {option.storageSize*initPrices.storage}€</span></p> :
                    null
                  }
                  </>
                  : null
                }
              >
                <p>
                  Avez-vous besoin d’un espace de stockage ?
                  <TooltipInfo text={textStorage}/>
                  <span className="sub">({initPrices.storage}€ / équivalent palette 80x120)</span>
                </p>
              </SwitchField>
              <SwitchField
                checked={option.internet}
                handleChange={(val) => handleChange(val,"internet")}
                conditional={option.internet ?
                  <>
                    <ul>
                      <li>
                        <span className="sub">
                          Une ligne filaire dans votre espace privatif
                        </span>
                      </li>
                      <li>
                        <span className="sub">
                          VLAN dédié et sécurisé
                        </span>
                      </li>
                      <li>
                        <span className="sub">
                          Débit symétrique garanti : 2 Mo avec nb max d’utilisateurs/équipements conseillé
                        </span>
                      </li>
                      <li>
                        <span className="sub">
                          Switch 7 ports disponibles inclus
                        </span>
                      </li>
                      <li>
                        <span className="sub">
                          Pas de portail de connexion
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span className="sub">
                        Désactiver le WIfi de leurs équipements qui n'utilisentp as le wifi<br />pour leur présentation sur le salon ( ex : imprimantes sans fil ). "Trop de wifi tue le Wifi"
                      </span>
                    </p>
                  </>
                  : null
                }
              >
                <p>
                  Avez-vous besoin d’une connexion internet filaire ? (300 € la connexion 2Mo)
                </p>
              </SwitchField>
            </div>
            <div className="standiste">
              <p className="section-title bold">Information sur le standiste</p>
              <div className="container-inputs row">
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option.standiste && option.standiste.name ? option.standiste.name : ""}
                    label={"Nom"}
                    placeholder={""}
                    type={"text"}
                    onChange={(val) => handleChange(val,"standisteName")}
                  />
                </div>
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option.standiste && option.standiste.email ? option.standiste.email : ""}
                    label={"Adresse mail"}
                    placeholder={""}
                    format={"email"}
                    type={"string"}
                    onChange={(val) => handleChange(val,"standisteEmail")}
                  />
                </div>
                <div className="col-3">
                  <Input
                    className={"input"}
                    value={option.standiste && option.standiste.phone ? option.standiste.phone : ""}
                    label={"Téléphone"}
                    placeholder={""}
                    type={"text"}
                    maskNumber={"## ## ## ## ##"}
                    format={"phone"}
                    onChange={(val) => handleChange(val,"standistePhone")}
                  />
                </div>
              </div>
            </div> 
          </section>
        </div>
      )
    }
  }

  return(
    <>
     {renderOptionsList()}
    </>
  )

}
