/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState , useCallback , useEffect , useMemo } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';
import { Link } from 'react-router-dom'

import { getOrdersListAction , exportOrdersAction } from '../../../actions/ordersActions';
import { useGlobalContext } from '../../context/GlobalContext';

import OrderRow from './Orders_Partials/OrderRow';
import ExportExcel from '../../Partials/ExportExcel';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { EXPORT_ORDERS, SET_ORDERS_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OrdersList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.orders;

  const [ filtredList , setFiltredList ] = useState();

  const [ search , setSearch ] = useState("");
  const [ selectedProvider , setSelectedProvider ] = useState([]);
  const [ selectedClient  , setSelectedClient ] = useState([]);
  
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);
  const [ sortByNbProducts , setSortByNbProducts ] = useState(false);
  const [ sortByStatus , setSortByStatus ] = useState(false);
  const [ exportIsLoading , setExportIsLoading] = useState(null);


  const getOrdersList = useCallback(() => {
    setActivePage("orders");
    setSearch(filters.search);
    setSelectedProvider(filters.providers);
    setSelectedClient(filters.shops);
    getOrdersListAction(dispatch);

    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

    dispatch({
      type : 'GET_ORDER',
      payload : null
    });

    dispatch({
      type : 'EXPORT_ORDERS',
      payload : null
    });

  }, [dispatch, filters.providers, filters.search, filters.shops, setActivePage]);

  useEffect(() => {
    getOrdersList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.orders.scrollOrders)
  });

  useEffect(() => {
    if (context.orders.ordersList.length > 0 ) {
      let list = context.orders.ordersList;
      list.forEach(item => {
        var total = 0
        item.products.forEach(product => total += product.price )
        item.total = total
      })
      setFiltredList(list.sort(function(a, b) {
        var dateA = new Date(a.updatedAt)
        var dateB = new Date(b.updatedAt)
        return dateA.getTime() - dateB.getTime()
      }).reverse());
    }
    // eslint-disable-next-line 
  }, [context.orders.ordersList])

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_ORDERS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (context.orders.ordersList) {

      dispatch({
        type: SET_ORDERS_FILTERS,
        payload: { search, providers: selectedProvider ,  shops: selectedClient }
      });

      var newList = context.orders.ordersList.filter(
        (order) =>  {
        if (!search) {
          return order;
        }
        if (order?.company?.name?.toLowerCase().includes(search.toLowerCase())) {
          return order
        }
        if (order?.gencod?.toLowerCase().includes(search.toLowerCase())) {
          return order
        }
        return null
      });
      newList.forEach(item => {
        var total = 0
        item.products.forEach(product => total += product.price )
        item.total = total
      })

      if (selectedProvider) {
        if (selectedProvider.length) {
          selectedProvider.forEach(option => {
            newList = newList.filter(order => {
              var found = order.products.find(product => product.company.name === option.value)
              if (found) return true;
              else return false
            });
          })
        }
        if (selectedClient  && selectedClient.length) {
          selectedClient.forEach(option => {
            newList = newList.filter(order => {
              var found = order.company.name === option.value
              if (found) return true;
              else return false;
            });
          })
        }
      }
      
      setFiltredList(newList);
    } 
    // eslint-disable-next-line 
  }, [search , context.orders.ordersList, selectedProvider , selectedClient]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    setSortByStatus();
    if (filtredList) {
      if (sortByStatus) {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.status > b.status)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  },  [sortByStatus]);

  useEffect(() => {
    if (filtredList) {
      if (sortByNbProducts) {
        setFiltredList(filtredList.sort((a ,b )=> (a.total > b.total)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.total > b.total)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByNbProducts]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.products.length > b.products.length)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.products.length > b.products.length)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  function renderExportBtn() {
    if (context.orders.exportList && context.orders.exportList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.orders.exportList.fileName}
        dataToExport={context.orders.exportList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_ORDERS,
            payload : null
          });
          setExportIsLoading();
        }} 
      />
    } else {
      return <IconBtn
        label={"Exporter les commandes"}
        icon="export"
        isLoading={exportIsLoading === "orders"}
        handleClick={() => {
          exportOrdersAction(dispatch);
          setExportIsLoading("orders");
        }}
      />
    }
  }

  
  function renderOrdersList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map(( order , i) => <OrderRow key={i} order={order}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (selectedClient  && selectedClient.length && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (selectedProvider && selectedProvider.length && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };;
    };
  };

  function renderSelectProviders() {
    var options = []
    if (context.orders.ordersList) {
      context.orders.ordersList.forEach(order => {
        order.products.forEach( product => {
          var option = {
            label: product.company.name,
            value: product.company.name
          }
          options.push(option)
        })
      })
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={selectedProvider}
        onChange={(val) => setSelectedProvider(val)}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par fournisseur"
        />
    )
  }

  function renderSelectClients() {
    var options = []
    if (context.orders.ordersList) {
      options = context.orders.ordersList.map(item => ({
        label: item.company.name,
        value: item.company.name
       }))
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={selectedClient}
        onChange={(val) => setSelectedClient(val)}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par magasins"
        />
    )
  }


  return useMemo(()=> { return (

    <div className="page-container">
      <header className="page-header space-between">
        <h1>Les commandes</h1>
        <div className="btn-container export">
          {renderExportBtn()}
          <Link to={'/create-order'}>
            <button
              className="btn-edit"
              style={{ height:36 , paddingLeft: 20 , paddingRight: 20 }}>
              Créer une commande
            </button>
          </Link>
        </div>
      </header>

      <main className="page-content">

        <div className="row search-container">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="col-3 select">
            {renderSelectProviders()}
          </div>
          <div className="col-3 select">
            {renderSelectClients()}
          </div>
        </div>

        <div className="filters">

          <div
            className="filter"
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Magasin</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(5%)'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Fournisseur</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(10%)'}}
            onClick={() => setSortByNbProducts(!sortByNbProducts)}>
            <span>Nombre d’articles</span>
            <img src={filterIcon} className={sortByNbProducts? "rotate180": ""} alt="filter"/>
          </div>

          <div
            style={{ transform: 'translateX(-42%)'}}
            className="filter">
            {/* onClick={() => setSortByAlpha(!sortByAlpha)}*/}
            <span>Types de produits</span>
            {/*<img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-70%)'}}
            onClick={() => setSortByTotal(!sortByTotal)}>
            <span>Montant total</span>
            <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
          </div>

          <div
            style={{ transform: 'translateX(-50%)'}}
            className="filter">
            {/* onClick={() => setSortByStatus(sortByStatus)}> */}
            <span>status</span>
            {/* <img src={filterIcon} className={sortByStatus? "rotate180": ""} alt="filter"/> */}
          </div>

        </div>

        {renderOrdersList()}

      </main>

    </div>
  )
  // eslint-disable-next-line
  }, [filtredList , search , sortByAlpha , sortByNbProducts, sortByTotal ,context.providers.lists.products , context.orders.exportList]);
};
