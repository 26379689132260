/* eslint-disable react-hooks/exhaustive-deps */
import React , { useEffect , useCallback , useState } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
// import { Translate } from "react-localize-redux";

import Select from 'react-select';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import StandRow from './Stands_Partials/StandRow';
import Loader from "../../Partials/Loader";

import { useGlobalContext } from '../../context/GlobalContext';
import { triAlpha , triCloison } from '../../../utils/utils';
import { getProvidersListAction , getEditListsAction } from '../../../actions/providersActions';
import { getAnimationsListAction } from '../../../actions/animationsActions';

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { SET_STANDS_FILTERS } from '../../../actions/types';

export default function StandsList(props) {
  props.setActivePage('stands');

  const [ context, dispatch ] = useGlobalContext();
  const {  standsFilters } = context.providers;

  const [ search , setSearch ] = useState("");
  const [ selectedProducts , setSelectedProducts] = useState();

  const [ filtredList , setFiltredList ] = useState();
  const [ dashboardData , setDashboardData ] = useState();

  const [ sortBySize , setSortBySize ]= useState(false);
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByStand , setSortByStand ] = useState(false);
  const [ sortByCloison , setSortByCloison ] = useState(false);
  const [ sortByStorage , setSortByStorage ] = useState(false);
  const [ sortByKit , setSortByKit ] = useState(false);
  const [ sortByPlaced , setSortByPlaced ] = useState(false);

  const getProvidersList = useCallback(() => {
    setSearch(standsFilters.search);
    setSelectedProducts(standsFilters.products);
    getAnimationsListAction(dispatch);
    getProvidersListAction(dispatch);
    getEditListsAction(dispatch)
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });
    dispatch({
      type : 'REMOVE_PROVIDER_VIEW',
      payload : {}
    });
    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });
    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });
  }, [dispatch]);

  useEffect(() => {
      getProvidersList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.providers.scrollStands)
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_STANDS',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    var standsList = context.providers.providersList.filter(provider => provider.showroomDimension > 0 );

    let dashboard = {
      standByProducts: [],
      totalCarpet: 0,
      totalStands: 0,
      totalAnimations: 0,
    }

    context.animations.animationsList.forEach(anim => {
      if(anim.dimensionL && anim.dimensionP && anim?.isValid === "valid" ) {
        dashboard.totalAnimations += anim.dimensionL * anim.dimensionP;
      }
    });

    const productsList = context.providers.lists?.products
    if (productsList) {
      productsList.forEach(p => {
        dashboard.standByProducts.push({
          name: p,
          count: standsList.filter(s => s?.products?.find(d => d === p)).length
        })
      });
      standsList.forEach(s => {
        if (s?.showroomDimension > 0 ) {
          dashboard.totalStands += s.showroomDimension
        }
        if (s?.showroomOption?.carpet) {
          dashboard.totalCarpet += s.showroomDimension
        }
      })
    }

    setDashboardData(dashboard)

    standsList.sort(function(a, b) {
      var dateA = new Date(a.showroomUpdatedAt)
      var dateB = new Date(b.showroomUpdatedAt)
      return dateA.getTime() - dateB.getTime()
    }).reverse();

    dispatch({
      type: SET_STANDS_FILTERS,
      payload: { search, products: selectedProducts }
    });
   

    standsList = standsList.filter(provider => {
      if (provider.name.toLowerCase().includes(search.toLowerCase())){
        return provider;
      } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user.email.toLowerCase().includes(search.toLowerCase()))) {
        return provider;
      } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user.profile.lastName.toLowerCase().includes(search.toLowerCase()))) {
        return provider;
      }
      return null;
    });
    if (selectedProducts && selectedProducts.length > 0) {
      var filtredByProduct = [];
      selectedProducts.forEach(option => {
        standsList.forEach(provider => {
          var found = provider?.products?.find(product => product === option.value);
          if (found) filtredByProduct.push(provider);
        });
      })
      setFiltredList(_.uniqBy(filtredByProduct, "_id"))
    } else {
      setFiltredList(standsList);
    }

  }, [context.providers.providersList , context.animations.animationsList ,context.providers.lists , search, selectedProducts])

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortBySize) {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }));
      } 
    }
    // eslint-disable-next-line
  }, [sortBySize]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByStorage) {
        setFiltredList(sortByStorageDimension(newList).reverse());
      } else {
        setFiltredList(sortByStorageDimension(newList));
      } 
    }
    // eslint-disable-next-line
  }, [sortByStorage]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      var sortedWithNumber = [
        ...newList.filter(item => item.showroomNumber).sort(function(a, b) { return a.showroomNumber - b.showroomNumber }),
        ...newList.filter(item => !item.showroomNumber)
      ];

      if (sortByStand) {
        setFiltredList(sortedWithNumber.reverse());
      } else {
        setFiltredList(sortedWithNumber);
      } 
    }
    // eslint-disable-next-line
  }, [sortByStand]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByCloison) {
        setFiltredList(newList.sort(triCloison));
      } else {
        setFiltredList(newList.sort(triCloison).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByCloison]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByAlpha) {
        setFiltredList(newList.sort(triAlpha));
      } else {
        setFiltredList(newList.sort(triAlpha).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByKit) {
        setFiltredList(sortByKitCount(newList));
      } else {
        setFiltredList(sortByKitCount(newList).reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByKit]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByPlaced) {
        setFiltredList(sortArrayByBooleen(newList , "showroomPlaced"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "showroomPlaced").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByPlaced]);

  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };
  
  function sortByKitCount(array)  {
    var noStorage  = []
    var storage = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption.countKit) {
        storage.push(item)
      } else {
        noStorage.push(item)
      }
    })
    return [...noStorage , ...storage.sort((a,b) => a.showroomOption.countKit - b.showroomOption.countKit) ];
  };

  function sortByStorageDimension(array)  {
    var noStorage  = []
    var storage = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption.storageSize) {
        storage.push(item)
      } else {
        noStorage.push(item)
      }
    })
    return [...noStorage , ...storage.sort((a,b) => a.showroomOption.storageSize - b.showroomOption.storageSize) ];
  };

  function renderSelectProduct() {
    if (context.providers.lists.products) {
      const options = context.providers.lists.products.map(item => ({
        label: item,
        value: item
       }))
      return (
        <Select
          options={options}
          value={selectedProducts}
          onChange={(val) => setSelectedProducts(val)}
          isMulti={true}
          styles={selectStyle}
          placeholder="Filtrer par type de produits"
          />
      )
    }
  }

  function renderStandsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map((provider, i) => <StandRow key={i} provider={provider} activePage={"stands"} />);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else {
        return (
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        )
      }
    } 
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des stands</h1>
      </header>
      <main className="page-content">
        {dashboardData?.standByProducts &&
          <div className="stands-dashboard">
            <div className="stands-products">
              {dashboardData?.standByProducts.map((p,i) =>
                <div key={`key-${i}`} className="count">
                  <label>{p.name.split(" ")[0]}</label>
                  <h2>{p.count}</h2>
                </div>  
              )}
            </div>
            <div className="stands-global">
              <div className="count">
                <label>M2 de moquettes</label>
                <h2>{dashboardData.totalCarpet} m<sup>2</sup></h2>
              </div>
              <div className="count">
                <label>Animations / Stands</label>
                <h2>{dashboardData.totalAnimations} m<sup>2</sup> / {dashboardData.totalStands} m<sup>2</sup> </h2>
              </div>
            </div>
          </div>
        }
        <div className="row search-container">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="select-products">
            {renderSelectProduct()}
          </div>
        </div>
        <div className="filters">
          <div className="filter" onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du fournisseurs</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>
          <div style={{ minWidth: '75%', display: 'flex', justifyContent: 'space-between'}}>
            <div
              className="filter"
              style={{ marginLeft: '1%' }}
              // onClick={() => setSortByUnivers(!sortByUnivers)}
              >
              <span>Types de produits </span>
              {/* <img src={filterIcon} className={sortByUnivers? "rotate180": ""} alt="filter"/> */}
            </div>
            <div
              className="filter"
              style={{ marginLeft: '3%' }}
              onClick={() => setSortByStand(!sortByStand)}>
              <span>Stand </span>
              <img src={filterIcon} className={sortByStand? "rotate180": ""} alt="filter"/>
            </div>
            <div
              className="filter"
              style={{ marginLeft: '3%' }}
              onClick={() => setSortByCloison(!sortByCloison)}>
              <span>Cloison</span>
              <img src={filterIcon} className={sortByCloison? "rotate180": ""} alt="filter"/>
            </div>
            <div
              className="filter"
              style={{ marginLeft: '2%' }}
              onClick={() => setSortBySize(!sortBySize)}>
              <span>m<sup>2</sup></span>
              <img src={filterIcon} className={sortBySize? "rotate180": ""} alt="filter"/>
            </div>
            <div
              className="filter"
              style={{ marginLeft: '-3%' }}
              onClick={() => setSortByStorage(!sortByStorage)}>
              <span>Stockage</span>
              <img src={filterIcon} className={sortByStorage? "rotate180": ""} alt="filter"/>
            </div>
            <div
              className="filter"
              style={{ marginLeft: '-4%' }}
              onClick={() => setSortByKit(!sortByKit)}>
              <span>Kits</span>
              <img src={filterIcon} className={sortByKit? "rotate180": ""} alt="filter"/>
            </div>
            <div
              className="filter"
              style={{ marginLeft: '-4%' }}
              onClick={() => setSortByPlaced(!sortByPlaced)}>
              <span>Sur plan</span>
              <img src={filterIcon} className={sortByPlaced? "rotate180": ""} alt="filter"/>
            </div>
            <div className="masked-div" style={{ minWidth: '12%' }}></div>
          </div>
        </div>
        {renderStandsList()}
      </main>
    </div>
  )
};