import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , updateShowroomAction , getShowroomLabelAction } from '../../../actions/providersActions';

import Input from '../../Partials/form_fields/Input';
import SwitchField from '../../Partials/form_fields/SwitchField';
import Loader from "../../Partials/Loader";

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import carpet from "../../../assets/images/icons/carpet-white.svg";
import water from "../../../assets/images/icons/water-white.svg";
import evier from "../../../assets/images/icons/evier-white.svg";
import elec from "../../../assets/images/icons/elec-white.svg";


import shapeU from '../../../assets/images/shape-U.svg';
import shapeL from '../../../assets/images/shape-L.svg';
import shapeBack from '../../../assets/images/shape-back.svg';
import shapeOpen from '../../../assets/images/shape-open.svg';


import '../../../styles/StandView.scss';

export default function ProviderView(props) {
  props.setActivePage('stands')
  const [ context , dispatch ] = useGlobalContext();

  const [ showroom , setShowroom ] = useState();
  const [ updatedShowroom , setUpdatedShowroom ] = useState();

  const [ number , setNumber ]= useState();
  const [ name , setName ]= useState();
  const [ isPlaced , setIsPlaced ] = useState(false);
  const [ isActive , setIsActive ] = useState(true);
  const [ isValid , setIsValid ] = useState(false);

  const [ notes , setNotes ] = useState();

  var provider = context.providers.provider;

  const getProvider = useCallback(() => {
    getProviderAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  useEffect(() => {
    getProvider();
  }, [getProvider]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (provider && provider.showrooms && provider.showrooms.length > 0 ) {
      const lastShowroom = provider.showrooms[0];
      setShowroom(lastShowroom);
      if (lastShowroom.notes) {
        setNotes(lastShowroom.notes);
      };
      if (lastShowroom.name) {
        setName(lastShowroom.name);
      };
      if (lastShowroom.number) {
        setNumber(lastShowroom.number);
      };
      if (lastShowroom.isValid) {
        setIsValid(lastShowroom.isValid);
      }
      if (lastShowroom.isPlaced) {
        setIsPlaced(lastShowroom.isPlaced);
      };
      if (lastShowroom?.isActive) {
        setIsActive(lastShowroom?.isActive);
      }
    }
    // eslint-disable-next-line 
  }, [context.providers.provider])

  useEffect(() => {
    if (showroom && showroom._id) {
      const newShowroom = {
        _id: showroom._id,
        notes: notes,
        number: number,
        name,
        isPlaced,
        isActive,
        isValid,
      };
      setUpdatedShowroom(newShowroom);
    }
    // eslint-disable-next-line 
  }, [ notes , number , isPlaced, name, isActive, isValid ])

  useEffect(() => {
    if (context.providers.message === 'Showroom successfully updated!') {
      successUpdate();
    }
    // eslint-disable-next-line 
  }, [context.providers.message])

  function successUpdate() {
    store.addNotification({
      message: "Stand mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_PROVIDER',
      payload : {}
    });
    getProvider();
  }

  async function subitShowroom() {
    if (updatedShowroom) {
      const newShowroom = {
        _id: showroom._id,
        notes: notes,
        number: number,
        name,
        isPlaced,
        isActive,
        isValid,
      };
      await updateShowroomAction(dispatch , newShowroom);
    }
  }

  function renderShape() {
    if (showroom.option.shape) {
      switch (showroom.option.shape) {
        case "U":
          return (
            <div className="col-container">
              <img src={shapeU} alt="shape U"/>
              <p className="bold uppercase info-label">En u</p>
            </div>
          );
        case "L":
          return (
            <div className="col-container">
              <img src={shapeL} alt="shape L"/>
              <p className="bold uppercase info-label">En L</p>
            </div>
          );
        case "back":
          return (
            <div className="col-container">
              <img src={shapeBack} alt="shape back"/>
              <p className="bold uppercase info-label">Cloison de fond</p>
            </div>
          );
        case "open":
          return (
            <div className="col-container">
              <img src={shapeOpen} alt="shape open"/>
              <p className="bold uppercase info-label">En îlot</p>
            </div>
          );
      
        default:
          return;
      }
    }
  }

  function renderSize() {
    const option =  showroom.option
    const dimension = showroom.dimension / 6 
    if (option.customDimension && option.customDimension.l) {
      return (
        <div className="col-container">
          <p>Dimension sur mesure</p>
          <p className="bold uppercase info-label">{option.customDimension.l} x {option.customDimension.L} m</p>
        </div>
      );
    } else {
      return (
        <div className="col-container">
            <p className="nb-xl">{dimension.toFixed()}</p>
            <p>
              Emplacement{dimension.toFixed() > 1 ? "s": null }
              {option.direction === 'l' ? " dos à dos" : null }
              {option.direction === 'L' ? " côte à côte" : null }
            </p>
            <p className="bold uppercase info-label">{showroom.dimension} m<sup>2</sup></p>
        </div>
      );
    }

  }

  function renderStand() {
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>{provider.company.name}</h1>
        </header>
        <main className="page-content">
          <div className="btn-container">
            <Link to={"/stands"}>
              <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
            </Link>
            <div>
              <button
                className="btn-edit edit-shoowroom"
                onClick={() => getShowroomLabelAction(dispatch , showroom._id , `livraison-${provider.company.name}${showroom.number ? `-stand-${showroom.number }`:''}` )}
                style={{ marginRight: 20 ,marginBottom: 10 , width : 200}}>
                <Translate id="btn.showroomLabel"/>
              </button>
              <Link to={`/showroom-edit/${showroom._id}/${props.activePage}`}>
                <button className="btn-edit edit-shoowroom" style={{ marginBottom: 10 , width : 140}}><Translate id="btn.editShowroom"/></button>
              </Link>
            </div>
          </div>

          <div className="row col-2">

            <div className="col-2">
              <div
                style={{ minHeight: 130}}
                className="card"
                >
                {provider?.company?.products &&
                  <div className="col-1">
                    <span className="bold"><Translate id="company.products"/></span><br/>
                    <div className="information-container">
                      <div className="col-1">
                        {provider?.company?.products?.map((item ,i ) => <div key={i} className="list-item selected">{item}</div>)}
                      </div>
                    </div>
                  </div>
                }
                <div className="col-2">
                  <Input
                    label={"Numéro de stand"}
                    value={number}
                    type={"number"}
                    className="input"
                    onChange={(value) => setNumber(parseFloat(value))}
                    />
                </div>
                <div className="col-2">
                  <Input
                    label={"Nom de stand"}
                    indication={"(Si différent de la raison sociale)"}
                    value={name}
                    type={"text"}
                    className="input"
                    onChange={(value) => setName(value)}
                    />
                </div>
                <div className="col-1">
                  <SwitchField
                    checked={isPlaced}
                    handleChange={(val) => setIsPlaced(val)}>
                    <span className="bold">Le stand est placé sur le plan ?</span>
                  </SwitchField>
                </div>
                <div className="col-1" style={{ marginTop: 10 }}>
                  <SwitchField
                    checked={isValid}
                    handleChange={(val) => setIsValid(val)}>
                    <span className="bold">Le stand est validé ?</span>
                  </SwitchField>
                </div>
                <div className="col-1" style={{ marginTop: 10 }}>
                  <SwitchField
                    checked={isActive}
                    handleChange={(val) => setIsActive(val)}>
                    <span className="bold">Le stand est actif ?</span>
                  </SwitchField>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div
                className="card notes"
                style={{ minHeight: 130}}
                >
                <textarea
                  placeholder="Notes sur le stand"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}/>
              </div>
            </div>

          </div>

          <div className="row showroom-info">

            <div className="col-shoowroom">
              {renderShape()}
            </div>

            <div className="col-shoowroom border">
              {renderSize()}
            </div>

            <div className="col-shoowroom border">
              <ul>

                <li className={`${showroom.option.carpet ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <img src={carpet} alt="carpet"/>
                  </div>
                  <p className="bold">{showroom.option.carpetDesc ? showroom.option.carpetDesc : <span>Moquette aux couleurs du salon</span> }</p>
                </li>

                <li className={`${showroom.option.kit ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <span className="bold">{showroom.option.countKit ? showroom.option.countKit : "0"}</span>
                  </div>
                  <p className="bold">kit{showroom.option.countKit && showroom.option.countKit > 1 ? "s" : null } de mobilier </p>
                </li>

                <li className={`${showroom.option.water ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <img src={water} alt="water"/>
                  </div>
                  <p className="bold">Alimentation en eau</p>
                </li>

                <li className={`${showroom.option.waterType === "alimentation eau + évier" ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <img src={evier} alt="evier"/>
                  </div>
                  <p className="bold">Evier</p>
                </li>

                <li className={`${showroom.option.power ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <img src={elec} alt="elec"/>
                  </div>
                  <p className="bold">Puissance électrique supplémentaire</p>
                </li>

                <li className={`${showroom.option.storageSize && parseFloat(showroom.option.storageSize) > 0   ? "" : "not-active"}`}>
                  <div className="icon-white">
                    <div className="icon-white">
                      <span className="bold">{showroom.option.storageSize ? showroom.option.storageSize  : "0"} (équivalent palette 80x120)</span>
                    </div>
                  </div>
                  <p className="bold">Espace de stockage</p>
                </li>

              </ul>
            </div>

          </div>

          {provider && provider.animations && provider.animations.length > 0 &&
            <div className="row col-2">
              {provider.animations.map(( anim , i ) => {
                if (anim.dimensionL) {
                  return (
                    <div key={i} className="col-2">
                      <div
                        style={{ minHeight: 100}}
                        className="card"
                        >
                        <div className="col-1">
                          <span className="bold">Espace animation</span><br/>
                          {anim.dimensionL * anim.dimensionP} m<sup>2</sup><br/>
                          {anim.description}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={i} className="col-2">
                      <div
                        style={{ minHeight: 100}}
                        className="card"
                        >
                        <div className="col-1">
                          <span className="bold">Animation sur stand</span><br/>
                          {anim.description}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}

            </div>
          }
          
          <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
            <button
              className={`btn-save ${updatedShowroom ? "" : "disabled"}`}
              disabled={!updatedShowroom}
              onClick={() => subitShowroom()}
              >
                <Translate id="btn.save"/>
            </button>
          </div>

        </main>
      </>
    )
  }

  return (
    <div className="page-container">
      {showroom ?
        renderStand()
        :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  )
}