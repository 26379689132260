import React from "react";
import { Link } from "react-router-dom";

import shopsIcon  from "../../../../assets/images/icons/shops_icon.svg";
import contact  from "../../../../assets/images/icons/contact_primary.svg";
import contactGrey  from "../../../../assets/images/icons/contact_grey.svg";
import flag  from "../../../../assets/images/icons/flag.svg";
import redFlag  from "../../../../assets/images/icons/redFlag.svg";
import userSplash from "../../../../assets/images/icons/users-slash.svg";

export default function ShopRow(props) {
  const { shop } = props;

  function renderUpdatedLabel() {
    var updatedDate = new Date(shop.updatedAt);
    var today = new Date()
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  }

  let adherent = shop?.users?.find((u) => u?.profile?.function === 'ADHERENT');

  return (
    <Link to={"/shop-view/" + shop._id}>
      <div className="list-row" >
        {renderUpdatedLabel()}
        <div className="name">
          <div className="icon">
            <img src={shopsIcon} alt="icon-provider"/>
          </div>
          <p>
            {shop.name}
          </p>
        </div>
        <div className={`info shop ${props.dashboard ? "dashboard" : ""}`}>
          <div className="contacts">
            {shop.users && shop.users.length > 0 ?
              <>
                <div className="picto">
                  <p>{shop.users.filter( u => u.presence.length > 0).length}</p> <img src={contact} alt="icon-contact"/>
                </div>
                <div className="picto">
                  <p style={{ marginLeft: 10 }}>{shop.users.filter( u => u.presence.length === 0).length}</p> <img src={contactGrey} alt="icon-contact"/>
                </div>
              </>
            :
              <div className="picto not-active">
                <p>{0}</p> <img src={contactGrey} alt="icon-contact"/>
              </div>
            }
          </div>
          <p className="centrale">
            {shop.centrale}
          </p>
          {!props.dashboard && shop.location && shop.location.city &&
            <p className="city">
              {shop.location.city}
            </p>
          } 

          {shop?.users.length > 0 ?
            <>
              {adherent?.presence?.length > 0 ?
                <div>
                  <div className="picto">
                    <img src={flag} alt="icon-flag"/>
                    <p style={{ width: 30 , marginLeft: 10 }}>oui</p>
                  </div>
                </div>
                :
                <div>
                  <div className="picto not-active">
                    <img src={flag} alt="icon-flag"/>
                    <p style={{ width: 30 , marginLeft: 10 }}>non</p>
                  </div>
                </div>
              }
              </>
            :
              <div>
                <div className="picto">
                  {shop.presence && <img src={userSplash} className="user-slash" alt="icon-flag"/>}
                </div>
              </div>
            }

            {shop?.presence === true ?
              <>
                <div className="picto">
                  <img src={flag} alt="icon-flag"/>
                  <p style={{ width: 30 , marginLeft: 10 }}>oui</p>
                </div>
              </>
              :
              <>
                {shop?.presence === false ?
                  <>
                    <div className="picto">
                      <img src={redFlag} alt="icon-flag"/>
                      <p style={{ width: 30 , marginLeft: 10  }}>non</p>
                    </div>
                  </>
                  :
                  <>
                  <div className="picto not-active" style={{ opacity: 0 }}>
                    
                  </div>
                  </>
                }
              </>
            }
          
        </div>
      </div>
    </Link>
  )
}
