/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState , useCallback , useEffect } from 'react';
import * as _ from 'lodash';
// import { Translate } from "react-localize-redux";
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { getAssemblyListAction , exportAssembliesAction } from '../../../actions/assemblyActions';
import { useGlobalContext } from '../../context/GlobalContext';

import AssemblyRow from './Assembly_Partials/AssemblyRow';
import ExportExcel from '../../Partials/ExportExcel';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import '../../../styles/List.scss';
import { EXPORT_ASSEMBLY, SET_ASSEMBLY_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function AssemblyList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.assemblies;

  const [ filtredList , setFiltredList ] = useState();

  const [ search , setSearch ] = useState("");
  
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByStartTime , setSortByStartTime ] = useState(false);
  const [ sortByDuration , setSortByDuration ] = useState(false);
  const [ sortByEndTime , setSortByEndTime ] = useState(false);
  const [ sortByVolume , setSortByVolume ] = useState(false);
  const [ sortByIsHere , setSortByIsHere ] = useState(false);
  const [ exportIsLoading , setExportIsLoading] = useState(null);


  const getAssemblyList = useCallback(() => {
    setSearch(filters.search);
    setActivePage("assemblies")
    getAssemblyListAction(dispatch);

    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

    dispatch({
      type : 'EXPORT_ASSEMBLY',
      payload : null
    });

  }, [dispatch]);

  useEffect(() => {
    getAssemblyList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.assemblies.scrollAssembly)
  });

  useEffect(() => {
    if (context.assemblies.assemblyList.length > 0 ) {
      let list = context.assemblies.assemblyList;
      list.forEach(element => {
        var duration = [ "00:30", "01:00" , "01:30" ,  "02:00" , "02:30" ,  "03:00" , "03:30" ,  "04:00" , "04:30" ,  "05:00" , "05:30" ,  "06:00" , "06:30" ,  "07:00" , "07:30" ,  "08:00" , "08:30" ,  "09:00" , "09:30" , "10:00" , "10:30" ];
        var startHours = [ "07:00" , "07:30" , "08:00" , "08:30" , "09:00" ,"09:30" , "10:00" ,"10:30" , "11:00" , "11:30" , "12:00" , "12:30" , "13:00", "13:30"  , "14:00"  , "14:30" , "15:00"  , "15:30" , "16:00" , "16:30"  ,"17:00", "17:30" , "18:00" , "18:30"]; 
        if (duration.indexOf(element.duration) % 2 === 0 ) {
          element.endTime = startHours[startHours.indexOf(element.time) + duration.indexOf(element.duration) + 2 ] ;
        } else {
          element.endTime = startHours[startHours.indexOf(element.time) + duration.indexOf(element.duration) + 1 ];
        };
      });


      dispatch({
        type: SET_ASSEMBLY_FILTERS,
        payload: { search }
      })

      list.sort(function(a, b) {
        var dateA = new Date(a.updatedAt);
        var dateB = new Date(b.updatedAt);
        return dateA.getTime() - dateB.getTime();
      }).reverse();

      list = context.assemblies.assemblyList.filter(assembly => assembly.company.name.toLowerCase().includes(search.toLowerCase()));

      setFiltredList(list);
    }
    // eslint-disable-next-line 
  }, [context.assemblies.assemblyList, search])

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_ASSEMBLY',
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      if (sortByStartTime) {
        setFiltredList(filtredList.sort((a ,b ) => a.time > b.time ? 1 :-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => a.time > b.time ? 1 :-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByStartTime]);

  useEffect(() => {
    if (filtredList) {
      if (sortByDuration) {
        setFiltredList(filtredList.sort((a ,b ) => a.duration > b.duration ? 1 :-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => a.duration > b.duration ? 1 :-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByDuration]);

  useEffect(() => {
    if (filtredList) {
      if (sortByEndTime) {
        setFiltredList(filtredList.sort((a ,b ) => a.endTime > b.endTime ? 1 :-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => a.endTime > b.endTime ? 1 :-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByEndTime]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByIsHere) {
        setFiltredList(sortArrayByBooleen(newList , "isHere"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "isHere").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByIsHere]);

  useEffect(() => {
    if (filtredList) {
      if (sortByVolume) {
        setFiltredList(filtredList.sort((a ,b ) => a.volume > b.volume ? 1 :-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => a.volume > b.volume ? 1 :-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByVolume]);
  
  function renderExportBtn() {
    if (context.assemblies.exportList && context.assemblies.exportList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.assemblies.exportList.fileName}
        dataToExport={context.assemblies.exportList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_ASSEMBLY,
            payload : null
          });
          setExportIsLoading();
        }} 
        />
    } else {
      return <IconBtn
      label={"Exporter"}
      icon="export"
      isLoading={exportIsLoading === "assemblies"}
      handleClick={() => {
        exportAssembliesAction(dispatch);
        setExportIsLoading("assemblies");
      }}
      />
    }
  }

  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item && item[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };
  
  
  function renderAssemblyList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map((standAssembly , i) => <AssemblyRow key={i} standAssembly={standAssembly}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  }

  return (
    <div className="page-container">

      <header className="page-header">
        <h1>Liste des demandes de montage</h1>
      </header>

      <main className="page-content">

        <div className="row search-container space-between">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>

          {renderExportBtn()}
        </div>

        <div className="filters">

          <div
            className="filter"
            style={{ minWidth: '18%'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Nom du fournisseurs</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByStartTime(!sortByStartTime)}>
            <span>Heure d’arrivée</span>
            <img src={filterIcon} className={sortByStartTime? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByDuration(!sortByDuration)}>
            <span>Durée</span>
            <img src={filterIcon} className={sortByDuration? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            onClick={() => setSortByEndTime(!sortByEndTime)}>
            <span>Horaire de départ</span>
            <img src={filterIcon} className={sortByEndTime? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ minWidth: '9%'}}
            onClick={() => setSortByVolume(!sortByVolume)}>
            <span>Volume</span>
            <img src={filterIcon} className={sortByVolume? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ minWidth: '6%'}}
            onClick={() => setSortByIsHere(!sortByIsHere)}>
            <span>Pointage</span>
            <img src={filterIcon} className={sortByIsHere? "rotate180": ""} alt="filter"/>
          </div>

        </div>

        {renderAssemblyList()}

      </main>

    </div>
  );
};
