import { getData ,  putData , postData } from './index';

import {
  GET_ANIMATIONS_LIST,
  ERROR_ANIMATIONS,
  GET_ANIMATION,
  POST_ANIMATION,
  MESSAGE_ANIMATIONS,
  REMOVE_FILE
} from "./types"

export async function getAnimationsListAction(dispatch){
  let url = "/animation"
  let animationsList;
  await getData( ERROR_ANIMATIONS, url, dispatch , true).then((response)=>{
    animationsList = response.animations
  })
  dispatch({
    type : GET_ANIMATIONS_LIST,
    payload : animationsList
  })
}

export async function postAnimationAction(dispatch , obj){
  let url = "/animation"
  var animationCreated;

  await postData(POST_ANIMATION , ERROR_ANIMATIONS , url, dispatch , obj, true ).then((response)=>{
    animationCreated = response
  });
  
  dispatch({
    type : MESSAGE_ANIMATIONS,
    payload : animationCreated.message
  });
};

export async function getAnimationAction(dispatch , id){
  let url = "/animation/" + id 
  let animation;
  await getData( ERROR_ANIMATIONS, url, dispatch , true).then((response)=>{
    animation = response.animation
  })
  dispatch({
    type : GET_ANIMATION,
    payload : animation
  })
}

export async function updateAnimationAction(dispatch , id , data ){
  let url = "/animation/" + id 
  let animation;
  await putData( GET_ANIMATION , ERROR_ANIMATIONS , url, dispatch, data , true ).then((response)=>{
    animation = response
  })
  // dispatch({
  //   type : GET_ANIMATION,
  //   payload : animation.animation
  // })
  dispatch({
    type : MESSAGE_ANIMATIONS,
    payload : animation.message
  })
}

export async function removeFileAction(dispatch , id  , data ) {
  let url = "/animation/removefile/" + id 
  let animationRemove;

  await postData(POST_ANIMATION , ERROR_ANIMATIONS , url, dispatch , data , true ).then((response)=>{
    animationRemove = response
  });
  
  dispatch({
    type : REMOVE_FILE,
    payload : {
      message: animationRemove.message,
      fileName : data.fileName
    }
  });
}



