import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useHistory } from 'react-router-dom';
import Select from 'react-select';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProvidersListAction } from '../../../actions/providersActions';

import { postAnimationAction} from '../../../actions/animationsActions';
import selectStyle from '../../Partials/selectStyle';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

export default function AnimationView(props) {
  props.setActivePage("animations");
  var history = useHistory();

  const [ context , dispatch ] = useGlobalContext();
  const [ providersOptions , setProvidersOptions ] = useState();
  const [ selectedProvider , setSelectedProvider ] = useState();
  const [ animation , setAnimation ] = useState({ push: false });
  const [ poleExperience , setPoleExperience ] = useState({ push: false});



  const getProvidersList = useCallback(() => {
    getProvidersListAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
      getProvidersList();
  }, [getProvidersList]);

  useEffect(() => {
    if (context.providers.providersList) {
      var options = [];
      context.providers.providersList.forEach(item => options.push({ label: item.name , value: item._id }));
      setProvidersOptions(options);
    }
  }, [context.providers.providersList]);

  useEffect(() => {
    if (selectedProvider && selectedProvider.value) {
      setAnimation({...animation, company: selectedProvider.value});
      setPoleExperience({...poleExperience, company: selectedProvider.value})
    }
    // eslint-disable-next-line
  }, [selectedProvider]);

  useEffect(() => {
    if (context.animations.message === "animation saved") {
      successCreated();
      history.push('/animations')
    }
    // eslint-disable-next-line
  }, [context.animations.message])

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function deleteFile(obj , callBack ) {
    var anim = {...obj};
    delete anim.files
    callBack(anim)
  }

  function successCreated() {
    store.addNotification({
      message: "L'animation a été créé avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_ANIMATIONS',
      payload : {}
    });
  }

  function submit(data ) {
    var formData = new FormData()
    formData.append('user', context.auth.user._id);
    formData.append('company', data.company );
    formData.append('push', data.push );
    formData.append('description', data.description);
    if (data.dimensionL && data.dimensionP) {
      formData.append('dimensionL', data.dimensionL);
      formData.append('dimensionP', data.dimensionP);
    }

    if(data.files){
      Array.from(data.files).forEach((d)=>{
        formData.append('file', d);
      })
    }

    return postAnimationAction(dispatch , formData );

  }
  
  function renderStandAnimation() {
    return (
      <div className="col-2">
        <h1>Animation sur stand</h1>
        <div className="card">

          <p>
            Activer les notifications sur l'application du salon ?
          </p>
          <div className="list-container">
            <button
              onClick={() => setAnimation({...animation , push: true })}
              className={`btn list-item ${animation.push ? "selected" : ""}`}>
              oui
            </button>
            <button
              onClick={() => setAnimation({...animation , push: false })}
              className={`btn list-item ${!animation.push ? "selected" : ""}`}>
              non
            </button>
          </div>
          <p>
            Description des visuels et/ou argumentaires à relayer en bannière sur l'application
          </p>
          <textarea
            onChange={(e) => setAnimation({...animation , description: e.target.value }) }
            />
            
          <label htmlFor="upload"  className="btn-edit large">
            Joindre des fichiers
          </label>
          {animation && animation.files && animation.files.length &&
            <button
              style={{ marginLeft: 20 }}
              onClick={() => deleteFile(animation , setAnimation )}
              className="btn-edit large">
              supprimer les fichiers
            </button>
          }

          <input className="hide-file-input" onChange={(e) => setAnimation({...animation , files: animation.files ? [...animation.files ,...e.target.files] : [...e.target.files] })} type="file" name="upload" id="upload" multiple="multiple"/>

          <div className="files-list">
            {animation && animation.files && animation.files.length && <>{animation.files.map(file => (<div key={file.name} className="card bold" >{file.name}</div>))}</>}
          </div>

          <button
            onClick={() => submit(animation)}
            className="btn-edit large submit">
            Crée l'animation sur stand
          </button>
        </div>
      </div>
    )
  }

  function renderPoleAnimation() {
    return (
      <div className="col-2">
        <h1>Pôle d'expérence</h1>
        <div className="card">
          <p>
            Activer les notifications sur l'application du salon ?
          </p>
          <div className="list-container">
            <button
              onClick={() => setPoleExperience({...poleExperience, push: true })}
              className={`btn list-item ${poleExperience.push ? "selected" : ""}`}>
              oui
            </button>
            <button
              onClick={() => setPoleExperience({...poleExperience, push: false })}
              className={`btn list-item ${!poleExperience.push ? "selected" : ""}`}>
              non
            </button>
          </div>
          <p>
            Description du pôle d'experience:
          </p>
          <textarea
            onChange={(e) => setPoleExperience({...poleExperience, description: e.target.value }) }
            />
          <p>
            Dimensions souhaitées (LxP)<br/>
          </p>
          <div className="input-dimension">
            <span>L</span>
            <input
              placeholder={""}
              type="number"
              style={{ maxWidth: 50 }}
              onChange={(e) => setPoleExperience({...poleExperience, dimensionL: parseFloat(e.target.value) })}
            />
            <span>
            x P
            </span>
            <input
              placeholder={""}
              type="number"
              style={{ maxWidth: 50 }}
              onChange={(e) => setPoleExperience({...poleExperience, dimensionP: parseFloat(e.target.value) })}
            />
          </div>

          <label htmlFor="upload-pole"  className="btn-edit large">
            Joindre des fichiers
          </label>
          {poleExperience && poleExperience.files && poleExperience.files.length &&
            <button
              style={{ marginLeft: 20 }}
              onClick={() => deleteFile(poleExperience , setPoleExperience )}
              className="btn-edit large">
              supprimer les fichiers
            </button>
          }

          <input className="hide-file-input" onChange={(e) => setPoleExperience({...poleExperience , files: poleExperience.files ? [...poleExperience.files ,...e.target.files] : [...e.target.files] })} type="file" name="upload-pole" id="upload-pole" multiple="multiple"/>

          <div className="files-list">
            {poleExperience && poleExperience.files && poleExperience.files.length && <>{poleExperience.files.map(file => (<div key={file.name} className="card bold" >{file.name}</div>))}</>}
          </div>

          <button
            onClick={() => submit(poleExperience)}
            className="btn-edit large submit">
            Crée le pôle d'experience
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="page-container">
      <header className="page-header">

        <div className="icon">
          <img src={providerIcon} alt="icon-provider"/>
        </div>

        <h1>
          Création d'une animation {selectedProvider &&  `pour ${selectedProvider.label}`}
        </h1>

        </header>

        <main className="page-content">

          <Link to={"/animations"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <div className="row">
            <div className="col-3">
            <Select
              options={providersOptions}
              onChange={(val) => setSelectedProvider(val)}
              isMulti={false}
              styles={selectStyle}
              placeholder="Sélectionner un fournisseur"
              />
            </div>
          </div>

          {selectedProvider && selectedProvider.value &&
            <div className="row col-2 edit-animation">
              {renderStandAnimation()}
              {renderPoleAnimation()}
            </div>
          }

        </main>
    </div>
  );
  
};