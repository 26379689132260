import { deleteData, getData, postData, putData } from './index';

import {
  GET_OFFERS_LIST,
  ERROR_OFFERS,
  GET_OFFER,
  MESSAGE_OFFERS,
  PUT_OFFER,
  DELETE_OFFER,
  EXPORT_OFFERS,
} from "./types"

export async function getOffersListAction(dispatch){
  let url = "/product"
  let offersList;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offersList = response.products
  })
  dispatch({
    type : GET_OFFERS_LIST,
    payload : offersList
  })
}

export async function getOfferAction(dispatch , id){
  let url = "/product/" + id 
  let offer;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offer = response.product
  })
  dispatch({
    type : GET_OFFER,
    payload : offer
  })
}

export async function postProductAction(dispatch , data){
  let url = "/product/" 
  let productCreated;
  await postData(GET_OFFER , ERROR_OFFERS , url, dispatch , data, true , true ).then((response)=>{
   productCreated = response?.product;
  });
  if (productCreated) {
    dispatch({
      type : GET_OFFER,
      payload : productCreated
    })
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Le produit a été crée"
    });
  }
}

export async function putProductAction(dispatch , id, data){
  let url = "/product/" + id 
  let productUpdated;
  await putData( GET_OFFER , ERROR_OFFERS, url, dispatch, data , true , true).then((response)=>{
    productUpdated = response?.product;
  })
  if (productUpdated) {
    dispatch({
      type : PUT_OFFER,
      payload : productUpdated
    })
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Le produit a été modifié"
    });
  }
}

export async function deleteOfferAction(dispatch , id ){
  let url = "/product/" + id 
  let order;
  await deleteData(ERROR_OFFERS , url, dispatch, true ).then((response)=>{
    order = response
  });
  if (order) {
    dispatch({
      type : DELETE_OFFER,
      payload : id,
    });
  }
}


export async function exportOffersAction(dispatch , obj = { company: null } ){
  let url = "/export/product"
  let exportList;
  await postData(EXPORT_OFFERS , ERROR_OFFERS, url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_OFFERS,
    payload : exportList
  })
}
