import React from 'react';
import '../../../../styles/showroomShape.scss';

import IconWarn from '../../../../assets/images/icons/warn_icon.svg'
import shapeU from '../../../../assets/images/shape-U.svg'
import shapeL from '../../../../assets/images/shape-L.svg'
import shapeBack from '../../../../assets/images/shape-back.svg'
import shapeOpen from '../../../../assets/images/shape-open.svg'


export default function ShowroomShape(props){
  const { setShape , shape , shapeError } = props;


  return(
    <>
    <div className="shape">
      <section>
        <ul>
          <li onClick={() => setShape("U")} className={shape === "U" ? "selected" : "" }>
            <img src={shapeU} alt="shape U"/>
            <p>En u</p>
          </li>
          <li onClick={() => setShape("L")} className={shape === "L" ? "selected" : "" }>
            <img src={shapeL} alt="shape L"/>
            <p>En L</p>
          </li>
          <li onClick={() => setShape("back")} className={shape === "back" ? "selected" : "" }>
            <img src={shapeBack} alt="shape back"/>
            <p>Cloison de fond</p>
          </li>
          <li onClick={() => setShape("open")} className={shape === "open" ? "selected" : "" }>
            <img src={shapeOpen} alt="shape open"/>
            <p>En îlot</p>
          </li>
        </ul>
        { shape === "open" ?
          <p className="warn-shape"><img src={IconWarn} alt="warning"/>Le stand n'aura pas de "voisins" et le fournisseur ne pourra pas mettre de cloisons personnelle en bordure de stand.</p> : null
        }
      </section>
    </div>
    <p style={{ marginBottom: 20 }} className="text-error">{shapeError}</p>
    </>
  )
}