import React, { useState, useEffect } from 'react'
import { Skeleton } from '@material-ui/lab';

const ItemsLoading = ({width = "100px"}) => (
  <li>
    <Skeleton variant="rect" height={37} width={width}
      style={{
       marginBottom: 10,
       marginRight: 10,
       borderRadius : 12
      }}
    />
  </li>
)

export default function SelectItems(props){
  const [ selected , setSelected ] = useState([])

  useEffect(()=>{
    if(props.selectedItems){
      setSelected(props.selectedItems)
      if(props.handleChange){
        let error = fnError(props.selectedItems)
        props.handleChange(props.selectedItems,error)
      }
    } 
    // eslint-disable-next-line
  },[props.selectedItems])

  function fnError(arr){
    if(!props.required || arr.length > 0) return false
    if(arr.length === 0) return true
  }

  function toggleItems(id,isSelected){

    let arr;
    if(props.uniq){
      if(!isSelected){
        arr = [id]
      }else{
        arr = []
      }
    }else{
      arr = [...selected]
      if(!isSelected){
        arr.push(id)
      }else{
        arr = arr.filter((j)=>{
          return j !== id
        })
      }
    }


    if(props.handleChange){
      let error = fnError(arr)
      if(props.uniq){
        props.handleChange(arr[0],error)
      }else{
        props.handleChange(arr,error)
      }
    }

  }

  function isSelected(id){
    return selected.includes(id)
  }

  return (
    <div className="select-items">
      { !props.loading ?
        <ul>
          {props.items ?
            <>
            {props.items.map((item, i)=>(
              <li key={item+"-"+i} className={isSelected(item) ? "selected" : ""} onClick={() => toggleItems(item,isSelected(item))} id={item}>{item}</li>
            ))}
            </> : null
          }
        </ul> :
        <ul className="loading">
          <ItemsLoading width="90px" />
          <ItemsLoading width="110px" />
          <ItemsLoading width="60px" />
          <ItemsLoading width="90px" />
          <ItemsLoading width="60px" />
          <ItemsLoading width="50px" />
        </ul>
      }
    </div>
  )
}
