import React from "react";
import ReactExport from "react-export-excel";
import IconBtn from "./IconBtn/IconBtn";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel(props) {
  console.log(props)
  const renderExcelToExport = () => {
    if (props.dataToExport) {
      return props.dataToExport.map((sheet ,i) => {
        return (
          <ExcelSheet
            key={i}
            data={sheet.sheetData}
            name={sheet.sheetName}>
            {sheet.sheetHeader.map(( colValue ,i ) => (<ExcelColumn key={i} label={colValue} value={colValue} /> ))}
          </ExcelSheet>
        );
      });
    };
  };
  return (
    <ExcelFile
      element={
          <IconBtn
            label="Télécharger"
            icon="download"
            handleClick={() => 
              props.callBack()
            }
          />
      }
      filename={props.fileName}
      >
      {renderExcelToExport()}
    </ExcelFile>
  );
}
