import React , { useState } from 'react';
import more  from "../../../../assets/images/icons/more.svg";
import less  from "../../../../assets/images/icons/less.svg";

export default function ProductRow({ product , handleChangeProducts, defaultValue = 0 }) {
  
  const [ quantity , setQuantity ] = useState(defaultValue);

  function renderUpdatedLabel() {
    var updatedDate = new Date(product.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  function handleChangeQuantity(value) {
    if (value >= 0) {
      setQuantity(value);
      handleChangeProducts(product._id , value )
    }
  }

  return (

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          {/* <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div> */}
          <p>
            {product.company.name}
          </p>
        </div>

        <div className="info product">

          <p>
          {product.segment}
          </p>

          <p>
            {product.ref}
          </p>

          <p>
            {product.gencod}
          </p>

          <p>
            {product.packing}
          </p>

          <p className="bold text-primary">
            {product.price && product.price.toFixed(2)} €
          </p>

          <div className="counter input">

            <button
              onClick={() => handleChangeQuantity( quantity  - 1 )}
              >
              <img src={less} alt="more"/>
            </button>
 
            <input
              type="number"
              onChange={(e) => handleChangeQuantity( e.target.value )}
              min="0"
              value={quantity}
              />
     
            <button
              onClick={() => handleChangeQuantity(quantity + 1)}
              >
              <img src={more} alt="more"/>
            </button>
            
          </div>

        </div>

      </div>
  );
};
