import React , { useState , useEffect } from 'react';
import QrReader from 'react-qr-reader';
import { useGlobalContext } from '../../context/GlobalContext';
import { setUserPresenceAction , resetUserAction } from '../../../actions/usersActions';

import pouce from "../../../assets/images/icons/pouce.svg"
// import qr from "../../../assets/images/icons/qr-icon.png";

export default function QRcodeReader(props) {

  const { setIsBadgeValid } = props;

  const [ context, dispatch ] = useGlobalContext();
  const [ isScanned , setIsScanned ] = useState(false);
  const [ user , setUser ] = useState();

  useEffect(() => {
    if (context.users.user && context.users.user.isHere && context.users.user.isHere.find(item => item === props.event)) {
      setUser(context.users.user);
      setIsScanned(true);
      setIsBadgeValid(true)
    }

  }, [context.users.user , props.event , setIsBadgeValid ]);

  function handleScan(id) {
    if (id) {
      console.log(id);
      const data = {
        _id : id,
        day: props.event
      }
      setUserPresenceAction(dispatch , data);
    }
  }

  function handleError(err) {
    console.error(err)
  }

  function displayScan() {
    resetUserAction(dispatch);
    setIsScanned(false)
  }

  return (
    <>
      {!isScanned ?
        <div className="qr-container">
          <div className="qr">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        :
        <div className="scan-result-container">
          <div className="scan-result">
            <img src={pouce} alt= "is-valid"/>
            <h2>Badge validé</h2>
            <h1 className="bold">{user.profile.firstName} {user.profile.lastName}</h1>
            <button
              onClick={() => displayScan()}
              className="btn-edit">
              Scanner un autre badge
            </button>
          </div>
        </div>
      }
    </>
  )
}
