import * as moment from 'moment';
import 'moment/locale/fr'  

export const validateEmail = (email) => {
  // eslint-disable-next-line
  var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;
  return re.test(String(email).toLowerCase());
}

export function setEventTitle(event) {
  if (event === "day1") return "Mercredi 14 septembre";
  if (event === "day2") return "Jeudi 15 septembre";
  if (event === "conference2") return " conférence 2";
  if (event === "conference1") return " conférence 1";
  if (event === "cocktail") return "Cocktail" ;
}

export function translateDate(date , lang ) {
  var newDate = moment(date)
  moment.locale(lang)
  return  newDate.format('LLLL'); // 'Freitag, 24. Juni 2016 01:42'
}

export function triAlpha(a,b)  {
  return (a.name > b.name)? 1:-1;
};

export function triCloison(a,b)  {
  return (a.showroomOption.shape > b.showroomOption.shape )? 1:-1;
};

export function triCentrale(a,b)  {
  return (a.centrale > b.centrale )? 1:-1;
};

export const initPrices = {
  storage: 50,
  power: 275,
  water: 370,
  internet: 300,
  m6: 5700,
  mPlus: 1100,
}


export function calculPrice(data){
  let obj = {}
  let total = 0;

  if(data.option && data.dimension && data.option.direction ) {
    let dimension = data.dimension
    total = (dimension/6) * initPrices.m6
  }

  if(data.option &&
    data.option.customDimension &&
    data.option.customDimension.l &&
    data.option.customDimension.L
  ){
    let dimension = data.option.customDimension.l*data.option.customDimension.L
    total =  ((Math.floor(dimension/6))*initPrices.m6) + (dimension%6) * initPrices.mPlus;
    if (dimension < 6) {
      total = dimension * 900;
    }
  }

  if(data.option &&
    data.option.storageSize){
    obj.storagePrice = data.option.storageSize * initPrices.storage
    total = total + (data.option.storageSize * initPrices.storage)
  }

  if(data.option &&
    data.option.power){
    total = total + initPrices.power
    obj.powerPrice = initPrices.power
  }

  if(data?.option?.internet){
    total = total + initPrices.internet
  }

  if(data.option &&
    data.option.water){
    total = total + initPrices.water
    obj.waterPrice = initPrices.water
  }
  obj.total = total
  return obj

}

export function calcOrderTotal(productArray) {
  let total = 0;
  productArray.forEach(product => {
    let pack = parseFloat(product.packing) ? parseFloat(product.packing) : 1 ;
    if (product.price) {
      total += product.price * pack
    }
  });
  return total.toFixed(2)
} 

export function calcOfferPrice(offer) {
  let total = 0;
  let pack = parseFloat(offer.packing) ? parseFloat(offer.packing) : 1 ;
  if (offer.price) {
    total += offer.price * pack
  }
  if (offer.quantity) {
    total = total * offer.quantity;
  }
  return total.toFixed(2)
}

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});